import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Checkbox,
  IconButton,
  Switch,
  Tabs,
  Tab,
  Paper,
  Popover,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Add, Delete, Done, Remove } from "@material-ui/icons";
import { IoReload, IoPersonCircleOutline } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import CustomAvatar from "../CustomAvatar";
import Loading from "../Loading";
import { useParams } from "react-router-dom";
// import { getUserById } from "../../Utility/basic";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { a11yProps, TabPanel } from "../TabPanel";
import { authVerify } from "../../Utility/auth";
import {
  findApplicationById,
  findRoleById,
  findUserById,
} from "../../Utility/basic";
import { setUsers } from "../../Redux/Reducers/basicReducer";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
// import { allSystems, allUsers, userDetails } from "../../Data/data";

const useStyle = makeStyles((theme) => ({
  systemContainer: {
    display: "flex",
    flexDirection: "column",
    border: `0.5px solid ${theme.palette.text.hint}`,
    borderRadius: 8,
    padding: 10,
    paddingRight: 6,
    paddingBottom: 0,
    height: 160,
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  systemContainerExpanded: {
    height: "100%",
  },
  systemImg: {
    width: 40,
    height: 40,
    borderRadius: 4,
  },
  systemContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 4,
  },
  systemName: {
    fontSize: 14,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  systemSubSystemContainer: {
    height: 70,
    overflow: "hidden",
  },
  systemSubSystemContainerExpanded: {
    height: "auto",
  },
  systemSubSystemContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 4,
  },
  systemSubSystemName: {
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  systemExceedContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 4,
  },
  systemSelectedText: {
    fontSize: 9,
    fontWeight: 300,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  systemViewMoreLessButton: {
    fontSize: 9,
    textTransform: "lowercase",
  },

  userInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: 300,
  },
  userInfoItemContainer: {
    margin: 4,
    alignItems: "center",
  },
  userInfoContainerLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  userInfoContainerValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  copyIcon: {
    fontSize: 16,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  userInfoContainerText: {
    fontSize: 12,
  },

  userAssignedRolesTableContainer: {
    height: "53vh",
    // overflow: "overlay",
    width: "100%",
    paddingTop: 4,
  },
  userAssignedRolesTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: "#F1F5FE",
  },
  userAssignedRolesTableHeadCell: {
    // fontWeight: 700,
    whiteSpace: "nowrap",
    // color: theme.palette.background.paper,
    // fontSize: 14,
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  userAssignedRolesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAssignedRolesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userAssignedRolesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 10,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  userAssignedRolesBottomAddButton: {
    margin: "4px 10px",
    borderRadius: 50,
  },

  userAssignedLicensesTableContainer: {
    height: "53vh",
    // overflow: "overlay",
    width: "100%",
    paddingTop: 4,
  },
  userAssignedLicensesTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
  },
  userAssignedLicensesTableHeadCell: {
    // fontWeight: 700,
    whiteSpace: "nowrap",
    // color: theme.palette.background.paper,
    // fontSize: 14,
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  userAssignedLicensesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAssignedLicensesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userAssignedLicensesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 10,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  userAssignedLicensesBottomAddButton: {
    margin: "4px 10px",
    borderRadius: 50,
  },

  userAssociatedGroupsTableContainer: {
    height: "53vh",
    // overflow: "overlay",
    width: "100%",
    paddingTop: 4,
  },
  userAssociatedGroupsTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
  },
  userAssociatedGroupsTableHeadCell: {
    // fontWeight: 700,
    whiteSpace: "nowrap",
    // color: theme.palette.background.paper,
    // fontSize: 14,
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  userAssociatedGroupsTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAssociatedGroupsTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userAssociatedGroupsTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 10,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  userAssociatedGroupsBottomAddButton: {
    margin: "4px 10px",
    borderRadius: 50,
  },

  userDetailContainer: {
    flexDirection: "column",
    height: "100%",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    overflow: "overlay",
    position: "relative",
  },
  userDetailContent: {
    display: "flex",
    alignItems: "center",
    padding: 16,
  },
  userDetailEmpInfo: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    width: "100%",
  },
  userDetailEmpInfoTopContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userDetailEmpName: {
    fontSize: 18,
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  userDetailEmpInfoBottomContainer: {
    display: "flex",
    alignItems: "center",
  },
  userDetailEmpId: {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userDetailEmpInfoDivider: {
    border: `0.25px solid ${theme.palette.text.hint}`,
    margin: "0px 10px",
    height: 16,
  },
  userDetailTab: {
    fontSize: 14,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userDetailSAContainer: {
    height: "61vh",
    padding: 8,
  },
  userDetailSAContent: {
    padding: 4,
    marginBottom: 8,
  },
  userDetailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0px -4px 9px #D8D8D8",
    padding: "8px 16px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
  },
  userDetailFooterButton: {
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const System = ({ system, userDetail }) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [checked, setChecked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (params?.userId) {
      setChecked(
        findUserById(
          Number(params?.userId),
          basicReducerState?.users
        )?.applicationName?.includes(system?.name)
          ? true
          : false
      );
    }
  }, [params?.userId]);

  return (
    <Paper
      className={`${classes.systemContainer} ${
        expanded && classes.systemContainerExpanded
      }`}
      elevation={0}
    >
      {system?.imageType && system?.applicationImage && (
        <div>
          <img
            className={classes.systemImage}
            alt={system?.systemName}
            src={
              system?.imageType && system?.applicationImage
                ? `data:${system?.imageType};base64,${system?.applicationImage}`
                : ``
            }
            // width={30}
            height={30}
            style={{ height: 30, maxWidth: "100%", objectFit: "contain" }}
          />
        </div>
      )}

      <div className={classes.systemContent}>
        <Typography className={classes.systemName}>
          {system?.name || "-"}
        </Typography>

        <Checkbox
          checked={checked}
          color="primary"
          // onChange={(e) => setChecked(e.target.checked)}
          style={{ padding: 0 }}
        />
      </div>

      <div
        className={`${classes.systemSubSystemContainer} ${
          expanded && classes.systemSubSystemContainerExpanded
        }`}
      >
        {system?.subSystems?.map((item, index) => (
          <div
            key={`${item}-${index}`}
            className={classes.systemSubSystemContent}
          >
            <Typography className={classes.systemSubSystemName}>
              {`PROD-${index}`}
            </Typography>

            <Checkbox
              color="primary"
              checkedIcon={<Done />}
              style={{ padding: 0 }}
            />
          </div>
        ))}
      </div>

      {system?.subSystems?.length > 3 && (
        <div className={classes.systemExceedContainer}>
          <Typography className={classes.systemSelectedText}>
            {"5 selected"}
          </Typography>

          <Button
            variant="text"
            color="primary"
            startIcon={null}
            onClick={() => setExpanded(!expanded)}
            className={classes.systemViewMoreLessButton}
          >
            {expanded ? "less" : "more"}
          </Button>
        </div>
      )}
    </Paper>
  );
};

const UserInfo = ({ userDetail, id, anchorEl, onClose }) => {
  const classes = useStyle();

  return (
    <Popover
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Typography
        style={{
          fontSize: 12,
          color: "#3026B9",
          backgroundColor: "#F1F5FE",
          padding: "8px 10px",
        }}
      >
        Personal Information
      </Typography>

      <div className={classes.userInfoContainer}>
        <Grid container className={classes.userInfoItemContainer}>
          <Grid item xs={4}>
            <Typography className={classes.userInfoContainerLabel}>
              Employee Id
            </Typography>
          </Grid>

          <Grid item xs={8} className={classes.userInfoContainerValue}>
            <Typography className={classes.userInfoContainerText}>
              {userDetail?.userId || "6001422"}
            </Typography>

            <IconButton
              size="small"
              onClick={() => {
                navigator?.clipboard.writeText(userDetail?.empId || "6001422");
              }}
            >
              <MdContentCopy className={classes.copyIcon} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container className={classes.userInfoItemContainer}>
          <Grid item xs={4}>
            <Typography className={classes.userInfoContainerLabel}>
              User Email
            </Typography>
          </Grid>

          <Grid item xs={8} className={classes.userInfoContainerValue}>
            <Typography className={classes.userInfoContainerText}>
              {userDetail?.emailId || "aaron.baxter@incture.com"}
            </Typography>

            <IconButton
              size="small"
              onClick={() => {
                navigator?.clipboard.writeText(
                  userDetail?.userEmail || "aaron.baxter@incture.com"
                );
              }}
            >
              <MdContentCopy className={classes.copyIcon} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container className={classes.userInfoItemContainer}>
          <Grid item xs={4}>
            <Typography className={classes.userInfoContainerLabel}>
              Status
            </Typography>
          </Grid>

          <Grid item xs={8} className={classes.userInfoContainerValue}>
            <Typography className={classes.userInfoContainerText}>
              {userDetail?.status || "Active"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.userInfoItemContainer}>
          <Grid item xs={4}>
            <Typography className={classes.userInfoContainerLabel}>
              Type
            </Typography>
          </Grid>

          <Grid item xs={8} className={classes.userInfoContainerValue}>
            <Typography className={classes.userInfoContainerText}>
              {userDetail?.empType || "Employee"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.userInfoItemContainer}>
          <Grid item xs={4}>
            <Typography className={classes.userInfoContainerLabel}>
              Manager Id
            </Typography>
          </Grid>

          <Grid item xs={8} className={classes.userInfoContainerValue}>
            <Typography className={classes.userInfoContainerText}>
              {userDetail?.managerId || "1111111"}
            </Typography>

            <IconButton
              size="small"
              onClick={() => {
                navigator?.clipboard.writeText(
                  userDetail?.managerId || "1111111"
                );
              }}
            >
              <MdContentCopy className={classes.copyIcon} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container className={classes.userInfoItemContainer}>
          <Grid item xs={4}>
            <Typography className={classes.userInfoContainerLabel}>
              Manager Email
            </Typography>
          </Grid>

          <Grid item xs={8} className={classes.userInfoContainerValue}>
            <Typography className={classes.userInfoContainerText}>
              {userDetail?.managerEmail || "shruthi.rao@incture.com"}
            </Typography>

            <IconButton
              size="small"
              onClick={() => {
                navigator?.clipboard.writeText(
                  userDetail?.managerEmail || "shruthi.rao@incture.com"
                );
              }}
            >
              <MdContentCopy className={classes.copyIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};

const UserAssignedRoles = ({
  userDetail,
  setUserDetail,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [openAssignRoleDialog, setOpenAssignRoleDialog] = useState(false);
  // const params = useParams();
  const dispatch = useDispatch();

  const getApplicationameById = (applicationId) => {
    const application = findApplicationById(
      applicationId,
      basicReducerState.applications
    );
    return application?.name;
  };
  const deleteAssignedRole = (assignedRole) => {
    if (assignedRole?.status === "Draft") {
      setUserDetail({
        ...userDetail,
        assignedRoles: userDetail?.assignedRoles?.filter(
          (role) =>
            !(
              role?.applicationId === assignedRole?.applicationId &&
              role?.associationId === assignedRole?.associationId
            )
        ),
      });
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableUserRoleMappingUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMapping`;
        const disableUserRoleMappingPayload = {
          associationId: assignedRole?.associationId,
          associationType: assignedRole?.associationType,
          createdBy: assignedRole?.createdBy,
          createdOn: assignedRole?.createdOn,
          endDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          isActive: 0,
          isDeleted: 1,
          startDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          status: "Inactive",
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          userId: assignedRole?.userId,
        };
        const disableUserRoleMappingRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(disableUserRoleMappingPayload),
        };
        fetch(disableUserRoleMappingUrl, disableUserRoleMappingRequestParam)
          .then((res) => res.json())
          .then((users) => {
            setLoad(false);
            setUserDetail({
              ...userDetail,
              assignedRoles:
                userDetail?.assignedRoles?.filter(
                  (role) =>
                    !(
                      role?.applicationId === assignedRole?.applicationId &&
                      role?.associationId === assignedRole?.associationId
                    )
                ) || [],
            });

            var applicationNameMap = {};
            userDetail?.assignedRoles?.map((role) => {
              if (
                !(
                  role?.applicationId === assignedRole?.applicationId &&
                  role?.associationId === assignedRole?.associationId
                )
              ) {
                const application_name = getApplicationameById(
                  role.applicationId
                );
                applicationNameMap[application_name] = 1;
              }
              return null;
            });
            // dispatch(
            //   setUsers(
            //     basicReducerState?.users?.map((user) =>
            //       Number(user?.id) === Number(params?.userId)
            //         ? {
            //             ...user,
            //             applicationName: Object.keys(applicationNameMap),
            //           }
            //         : user
            //     ) || []
            //   )
            // );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };

  return (
    <>
      {/* <NewUserAssignedRole
        open={openAssignRoleDialog}
        onClose={() => setOpenAssignRoleDialog(false)}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        params={params}
      /> */}

      <TableContainer
        // component={Paper}
        className={`${classes.userAssignedRolesTableContainer} iagScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAssignedRolesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAssignedRolesTableHeadCell}
              >
                Application
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedRolesTableHeadCell}
              >
                Role
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedRolesTableHeadCell}
              >
                Status
              </TableCell>

              <TableCell
                align="center"
                className={classes.userAssignedRolesTableHeadCell}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAssignedRolesTableBody} iagScroll`}
          >
            {userDetail?.assignedRoles?.map((assignedRole, index) => {
              return (
                <TableRow
                  key={`${assignedRole?.id}-${index}`}
                  className={classes.userAssignedRolesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableBodyCell}
                  >
                    {assignedRole?.status === "Draft" ? (
                      <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedRole?.applicationId}
                        readOnly
                      >
                        {basicReducerState?.applications?.map(
                          (application, index) => (
                            <MenuItem
                              key={`${application?.id}-${index}`}
                              value={application?.id}
                              style={{ fontSize: 12 }}
                            >
                              {application?.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    ) : (
                      assignedRole?.applicationName
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableBodyCell}
                  >
                    {assignedRole?.status === "Draft" ? (
                      <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedRole?.associationId}
                        readOnly
                      >
                        {basicReducerState?.roles.map((role, index) => (
                          <MenuItem
                            key={`${role?.id}-${index}`}
                            value={role?.id}
                            style={{ fontSize: 12 }}
                          >
                            {role?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      assignedRole?.roleName
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableBodyCell}
                  >
                    {assignedRole?.status}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.userAssignedRolesTableBodyCell}
                  >
                    <Tooltip
                      title={
                        assignedRole?.status === "Draft" ? "Remove" : "Delete"
                      }
                    >
                      <IconButton
                        color="secondary"
                        onClick={() => deleteAssignedRole(assignedRole)}
                        disabled={load}
                      >
                        {assignedRole?.status === "Draft" ? (
                          <Remove style={{ fontSize: 16 }} />
                        ) : (
                          <Delete style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.userAssignedRolesBottomAddButton}
        onClick={() => setOpenAssignRoleDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button> */}
    </>
  );
};

const UserAssignedLicenses = ({
  userDetail,
  setUserDetail,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const [openAssignedLicenseDialog, setOpenAssignedLicenseDialog] =
    useState(false);

  return (
    <>
      {/* <NewUserAssignedLicense
        open={openAssignedLicenseDialog}
        onClose={() => {
          setOpenAssignedLicenseDialog(false);
        }}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        params={params}
      /> */}

      <TableContainer
        // component={Paper}
        className={`${classes.userAssignedLicensesTableContainer} iagScroll`}
      >
        <Table
        // size="small"
        //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAssignedLicensesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Application
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                License
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Assigned at
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Assigned till
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAssignedLicensesTableBody} iagScroll`}
          >
            {userDetail?.assignedLicenses?.map((assignedLicense, index) => {
              return (
                <TableRow
                  key={`${assignedLicense?.id}-${index}`}
                  className={classes.userAssignedLicensesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.applicationName}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.licenseNumber}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.startDate}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.endDate}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.status}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.userAssignedLicensesBottomAddButton}
        onClick={() => setOpenAssignedLicenseDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button> */}
    </>
  );
};

const UserAssociatedGroups = ({
  userDetail,
  setUserDetail,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [openAssociatedGroupDialog, setOpenAssociatedGroupDialog] =
    useState(false);
  // const params = useParams();

  const getApplicationameById = (applicationId) => {
    const application = findApplicationById(
      applicationId,
      basicReducerState.applications
    );
    return application?.name;
  };
  const deleteAssociatedGroup = (associatedGroup) => {
    if (associatedGroup?.status === "Draft") {
      setUserDetail({
        ...userDetail,
        associatedGroups: userDetail?.associatedGroups?.filter(
          (group) => group?.id !== associatedGroup?.id
        ),
      });
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableUserAssociatedGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/deleteUserFromGroup/deactivate`;
        const disableUserAssociatedGroupPayload = {
          // updatedBy: userReducerState?.user?.email,
          // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          groupId: [Number(associatedGroup?.id)],
          userId: Number(params?.userId),
        };
        const disableUserAssociatedGroupRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(disableUserAssociatedGroupPayload),
        };
        fetch(
          disableUserAssociatedGroupUrl,
          disableUserAssociatedGroupRequestParam
        )
          .then((res) => res.json())
          .then((groups) => {
            setLoad(false);
            setUserDetail({
              ...userDetail,
              associatedGroups:
                userDetail?.associatedGroups?.filter(
                  (group) => group?.id !== associatedGroup?.id
                ) || [],
            });
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };

  return (
    <>
      {/* <NewUserAssociatedGroup
        open={openAssociatedGroupDialog}
        onClose={() => setOpenAssociatedGroupDialog(false)}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        params={params}
      /> */}

      <TableContainer
        // component={Paper}
        className={`${classes.userAssociatedGroupsTableContainer} waScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAssociatedGroupsTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAssociatedGroupsTableHeadCell}
              >
                Application
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssociatedGroupsTableHeadCell}
              >
                Group
              </TableCell>

              <TableCell
                align="center"
                className={classes.userAssociatedGroupsTableHeadCell}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAssignedRolesTableBody} waScroll`}
          >
            {userDetail?.associatedGroups?.map((associatedGroup, index) => {
              return (
                <TableRow
                  key={`${associatedGroup?.id}-${index}`}
                  className={classes.userAssociatedGroupsTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.userAssociatedGroupsTableBodyCell}
                  >
                    {getApplicationameById(
                      Number(associatedGroup?.applicationId)
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssociatedGroupsTableBodyCell}
                  >
                    {associatedGroup?.name}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.userAssociatedGroupsTableBodyCell}
                  >
                    <Tooltip
                      title={
                        associatedGroup?.status === "Draft"
                          ? "Remove"
                          : "Delete"
                      }
                    >
                      <IconButton
                        color="secondary"
                        onClick={() => deleteAssociatedGroup(associatedGroup)}
                        disabled={load}
                      >
                        {associatedGroup?.status === "Draft" ? (
                          <Remove style={{ fontSize: 16 }} />
                        ) : (
                          <Delete style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Button
        size="small"
        variant="contained"
        color="primary"
        className={classes.userAssociatedGroupsBottomAddButton}
        onClick={() => setOpenAssociatedGroupDialog(true)}
        startIcon={<Add />}
        disabled={load}
      >
        Add
      </Button> */}
    </>
  );
};

function UserDetail({ selectedSubItem }) {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [load, setLoad] = useState(false);
  const [selectedUserDetailContentValue, setSelectedUserDetailContentValue] =
    useState(0);
  const [userDetail, setUserDetail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const params = useParams();
  const personalInfoId = Boolean(anchorEl)
    ? "personal-information-popover"
    : undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.userId) {
      // getUserDetail();
      getUserInfoById();
    }
  }, [params?.userId]);

  const removeRepeatedValue = (valueList) => {
    var valueMap = {};
    valueList?.map((value) => {
      valueMap[value] = 1;
      return null;
    });
    // console.log("valueMap", valueMap);
    return Object.keys(valueMap);
  };
  const getApplicationameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name;
  };
  const getUserInfoById = () => {
    const getUserByIdUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userEmail?userEmail=${params?.userId}`;
    const getUserByIdRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userReducerState.token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    // const getUserAssociatedGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/getUserGroup?userEmail=${params?.userId}`;
    // const getUserAssociatedGroupRequestParams = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${userReducerState.token}`,
    //     applicationId: process.env.REACT_APP_IWAId,
    //   },
    // };
    // const getUserRoleMappingUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMapping/byUserEmail?userEmail=${params?.userId}`;
    // const getUserRoleMappingRequestParam = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${userReducerState.token}`,
    //   },
    // };
    // const userId = basicReducerState.users.find(
    //   (user) => user.id === Number(params?.userId)
    // )?.userId;
    // const getMappedLicenseByUsernameUrl = `${process.env.REACT_APP_IWAServices}/api/v1/license/mappedUserDetails?userEmail=${params?.userId}`;
    // const getMappedLicenseByUsernameRequestParam = {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${userReducerState.token}`,
    //     "Content-Type": "application/json",
    //     applicationId: process.env.REACT_APP_IWAId,
    //   },
    // };
    if (authVerify(userReducerState.token) && params?.userId) {
      setLoad(true);
      setUserDetail({});
      Promise.all([
        fetch(getUserByIdUrl, getUserByIdRequestParams).then((res) =>
          res.json()
        ),
        // fetch(
        //   getUserAssociatedGroupUrl,
        //   getUserAssociatedGroupRequestParams
        // ).then((res) => res.json()),
        // fetch(getUserRoleMappingUrl, getUserRoleMappingRequestParam).then(
        //   (res) => res.json()
        // ),
        // fetch(
        //   getMappedLicenseByUsernameUrl,
        //   getMappedLicenseByUsernameRequestParam
        // ).then((res) => res.json()),
      ])
        .then(
          ([
            userDetail,
            // userAssociatedGroupDetail,
            // userAssignedRolesDetail,
            // userAssignedLicensesDetail,
          ]) => {
            //   const postMappedAdditionFeatureUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/assignedAndUnassigneFeatures`;
            //   var roleIdList = [];
            //   userAssociatedGroupDetail?.data?.map((group) => {
            //     const roleIds = group?.roleIdList?.split(",");
            //     roleIds?.map((id) => {
            //       roleIdList.push(id);
            //       return null;
            //     });
            //     return null;
            //   });
            //   userAssignedRolesDetail?.data?.map((role) => {
            //     const roleDet = findRoleById(
            //       role?.roleId,
            //       basicReducerState?.roles
            //     );
            //     if (roleDet?.isComposite === 1) {
            //       const associateRolesId = role?.associateRoles?.split(",");
            //       associateRolesId.map((id) => {
            //         roleIdList.push(id);
            //         return null;
            //       });
            //     } else {
            //       roleIdList.push(role?.roleId);
            //     }
            //     return null;
            //   });
            //   const postMappedAdditionFeaturePayload = {
            //     userId: Number(params?.userId),
            //     applicationId: removeRepeatedValue(
            //       userAssignedRolesDetail?.data?.map(
            //         (role) => role?.applicationId
            //       ) || []
            //     ),
            //     roleId: removeRepeatedValue(roleIdList),
            //   };
            //   const postMappedAdditionFeatureRequestParam = {
            //     method: "POST",
            //     headers: {
            //       Authorization: `Bearer ${userReducerState.token}`,
            //       "Content-Type": "application/json",
            //       applicationId: process.env.REACT_APP_IWAId,
            //     },
            //     body: JSON.stringify(postMappedAdditionFeaturePayload),
            //   };
            //   // console.log(postMappedAdditionFeaturePayload);
            //   fetch(
            //     postMappedAdditionFeatureUrl,
            //     postMappedAdditionFeatureRequestParam
            //   )
            //     .then((res) => res.json())
            //     .then((userAdditionalFeatures) => {
            //       setLoad(false);
            //       setUserDetail({
            //         ...userDetail.data,
            //         assignedRoles: userAssignedRolesDetail?.data || [],
            //         assignedLicenses: userAssignedLicensesDetail?.data || [],
            //         assignedActivities: userAdditionalFeatures?.data?.[0] || [],
            //         unassignedActivities: userAdditionalFeatures?.data?.[1] || [],
            //         exceptionalActivities:
            //           userAdditionalFeatures?.data?.[2] || [],
            //         associatedGroups: userAssociatedGroupDetail?.data || [],
            //       });
            //       var applicationNameMap = {};
            //       userAssignedRolesDetail?.data?.map((role) => {
            //         const application_name = getApplicationameById(
            //           role.applicationId
            //         );
            //         applicationNameMap[application_name] = 1;
            //         return null;
            //       });
            //       dispatch(
            //         setUsers(
            //           basicReducerState?.users?.map((user) =>
            //             Number(user?.id) === Number(params?.userId)
            //               ? {
            //                   ...user,
            //                   displayName: userDetail?.data?.displayName,
            //                   applicationName: Object.keys(applicationNameMap),
            //                 }
            //               : user
            //           ) || []
            //         )
            //       );
            //     })
            //     .catch((err) => {
            //       setLoad(false);
            //     });

            setLoad(false);
            setUserDetail({
              ...userDetail.data,
              // assignedRoles: userAssignedRolesDetail?.data || [],
              // assignedLicenses: userAssignedLicensesDetail?.data || [],
              // assignedActivities: userAdditionalFeatures?.data?.[0] || [],
              // unassignedActivities: userAdditionalFeatures?.data?.[1] || [],
              // exceptionalActivities:
              //   userAdditionalFeatures?.data?.[2] || [],
              // associatedGroups: userAssociatedGroupDetail?.data || [],
            });
          }
        )
        .catch((err) => {
          setLoad(false);
          setUserDetail({});
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <div className={classes.userDetailContainer}>
      {/* {load ? (
        <Loading load={load} />
      ) : ( */}
      <>
        {load ? (
          <div className={classes.userDetailContent}>
            <Skeleton
              variant="rect"
              animation="wave"
              width={"100%"}
              height={47}
            />
          </div>
        ) : (
          <div className={classes.userDetailContent}>
            <CustomAvatar
              name={userDetail?.userName}
              style={{ marginRight: 16 }}
            />

            <div className={classes.userDetailEmpInfo}>
              <div className={classes.userDetailEmpInfoTopContainer}>
                <Typography className={classes.userDetailEmpName}>
                  {userDetail?.userName}
                </Typography>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    aria-describedby={personalInfoId}
                    style={{ padding: 0, marginRight: 8 }}
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    <IoPersonCircleOutline style={{ fontSize: 20 }} />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      // getUserDetail();
                      getUserInfoById();
                    }}
                    style={{ padding: 0 }}
                  >
                    <IoReload style={{ fontSize: 20 }} />
                  </IconButton>
                </div>
              </div>

              <div className={classes.userDetailEmpInfoBottomContainer}>
                <Typography className={classes.userDetailEmpId}>
                  Personal ID - {userDetail?.userId}
                </Typography>

                {/* <div className={classes.userDetailEmpInfoDivider} /> */}

                {/* <Switch color="primary" /> */}
              </div>
            </div>
          </div>
        )}

        {selectedSubItem === "Application Mapping" && (
          <>
            <Tabs
              value={selectedUserDetailContentValue}
              onChange={(e, newValue) =>
                setSelectedUserDetailContentValue(newValue)
              }
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label="Production System Access"
                className={classes.userDetailTab}
                {...a11yProps(0)}
              />

              <Tab
                label="Non Production System Access"
                className={classes.userDetailTab}
                {...a11yProps(1)}
              />

              <Tab
                label="CherryWork Apps Access"
                className={classes.userDetailTab}
                {...a11yProps(2)}
              />
            </Tabs>

            <TabPanel value={selectedUserDetailContentValue} index={0}>
              <div className={`${classes.userDetailSAContainer} iagScroll`}>
                {load ? (
                  <Grid
                    container
                    spacing={2}
                    className={classes.userDetailSAContent}
                  >
                    {[1, 2, 3, 4, 5, 6]?.map((data) => (
                      <Grid item key={data} xs={4}>
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          width={"100%"}
                          height={160}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    className={classes.userDetailSAContent}
                  >
                    {basicReducerState?.applications
                      ?.filter((system) => system?.applicationType === "PROD")
                      ?.map((system, index) => (
                        <Grid
                          item
                          key={`${system?.id}-${index}`}
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                        >
                          <System
                            key={`${system?.id}-${index}`}
                            system={system}
                            userDetail={userDetail}
                          />
                        </Grid>
                      ))}
                  </Grid>
                )}
              </div>
            </TabPanel>

            <TabPanel value={selectedUserDetailContentValue} index={1}>
              <div className={`${classes.userDetailSAContainer} iagScroll`}>
                {load ? (
                  <Grid
                    container
                    spacing={2}
                    className={classes.userDetailSAContent}
                  >
                    {[1, 2, 3, 4, 5, 6]?.map((data) => (
                      <Grid item key={data} xs={4}>
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          width={"100%"}
                          height={160}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    className={classes.userDetailSAContent}
                  >
                    {basicReducerState?.applications
                      ?.filter((system) => system?.applicationType === "DEV")
                      ?.map((system, index) => (
                        <Grid
                          item
                          key={`${system?.id}-${index}`}
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                        >
                          <System
                            key={`${system?.id}-${index}`}
                            system={system}
                            userDetail={userDetail}
                          />
                        </Grid>
                      ))}
                  </Grid>
                )}
              </div>
            </TabPanel>

            <TabPanel value={selectedUserDetailContentValue} index={2}>
              <div className={`${classes.userDetailSAContainer} iagScroll`}>
                {load ? (
                  <Grid
                    container
                    spacing={2}
                    className={classes.userDetailSAContent}
                  >
                    {[1, 2, 3, 4, 5, 6]?.map((data) => (
                      <Grid item key={data} xs={4}>
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          width={"100%"}
                          height={160}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    className={classes.userDetailSAContent}
                  >
                    {basicReducerState?.applications
                      ?.filter((system) => system?.applicationType === "CW")
                      ?.map((system, index) => (
                        <Grid
                          item
                          key={`${system?.id}-${index}`}
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                        >
                          <System
                            key={`${system?.id}-${index}`}
                            system={system}
                            userDetail={userDetail}
                          />
                        </Grid>
                      ))}
                  </Grid>
                )}
              </div>
            </TabPanel>
          </>
        )}

        {/* {selectedSubItem === "License Management" && (
              <UserAssignedLicenses
                userDetail={userDetail}
                setUserDetail={setUserDetail}
                loca={load}
                setLoad={setLoad}
                params={params}
              />
            )}

            {selectedSubItem === "Roles Mapping" && (
              <UserAssignedRoles
                userDetail={userDetail}
                setUserDetail={setUserDetail}
                load={load}
                setLoad={setLoad}
                params={params}
              />
            )}

            {selectedSubItem === "Group Mapping" && (
              <UserAssociatedGroups
                userDetail={userDetail}
                setUserDetail={setUserDetail}
                load={load}
                setLoad={setLoad}
                params={params}
              />
            )} */}

        {/* <div className={classes.userDetailFooter}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.userDetailFooterButton}
              //    onClick={updateUserInfo}
            >
              Submit
            </Button>
          </div> */}

        <UserInfo
          userDetail={userDetail}
          id={personalInfoId}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      </>
      {/* )} */}
    </div>
  );
}

export default UserDetail;
