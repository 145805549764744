export const userFileHeading = "User Id,First Name,Last Name,Email Id";
export const roleFileHeading = "Name,Label,Description";
export const applicationFileHeading = ["Name", "Label", "Description", "Unique Id", "Type"];
export const entityFileHeading = "Name,Label,Description";
export const apiFileHeading = "Name,Type,Description";
export const activityFileHeading = "Name,Label,Description,Module Name";
export const payloadFileHeading = "Name,Type,Description";
export const groupFileHeading = "Name";
export const requestsFileHeading = [
  "User",
  "Email",
  "Employee Id",
  "Parent Request Id",
  "Sub Request Id",
  "Created By",
  "Emergency",
  // "ITM Process Id",
  // "ITM Task Id",
  "Request Type",
  "Status",
  "System Name",
  "System Type",
  "Updated By",
  "Created On",
  "Updated On",
];
export const systemDetailsHeading = [
  "Display Name",
  "User Id",
  "Email",
  "Roles",
  "Requst Created On",
  "Request Created By",
  "Request Approved On",
  "Request Approved By",
];

export const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
