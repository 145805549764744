import React from "react";
import { IconButton, Paper, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete, Edit } from "@material-ui/icons";
import { features, roles } from "../../Data/data";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  customCardContainer: {
    padding: 6,
    height: 120,
    margin: "4px 0",
    boxShadow:
      "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    // height: "100%",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  customCardContainerSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  customCardName: {
    fontSize: 14,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 215,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  customCardLabel: {
    fontSize: 14,
    fontWeight: "normal",
    maxWidth: 215,
    // color: theme.palette.text.secondary,
  },
  customCardDescription: {
    fontSize: 12,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    maxWidth: 230,
  },
  customCardActionContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: 15,
    right: 20,
  },
}));

function CustomCard({
  item,
  application,
  onDelete,
  onClick,
  selected,
  load,
  onUpdate,
}) {
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const classes = useStyle();

  return (
    <Paper
      className={`${classes.customCardContainer} ${
        selected && classes.customCardContainerSelected
      }`}
      elevation={0}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <div
          style={{
            width: "5%",
            minWidth: 32,
            minHeight: 32,
            backgroundColor: "grey",
            borderRadius: 8,
            marginRight: 8,
          }}
        /> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography className={classes.customCardName} onClick={onClick}>
            {item?.name}
          </Typography>

          <Typography className={classes.customCardLabel}>
            {item?.label || item?.type}
          </Typography>
        </div>
      </div>

      <Divider style={{ margin: "8px 0px" }} />

      <Typography className={classes.customCardDescription}>
        {item?.description}
      </Typography>

      <div className={classes.customCardActionContainer}>
        {(userReducerState?.entitiesAndActivities?.roles?.includes(
          roles?.SUPER_ADMIN
        ) ||
          (userFeatures?.includes(features?.ADD_SYSTEM_DETAILS) &&
            application?.applicationOwnerEmails?.includes(
              userReducerState?.user?.email
            ))) && (
          <IconButton
            size="small"
            color="primary"
            onClick={onUpdate}
            disabled={load}
          >
            <Edit style={{ fontSize: 18 }} />
          </IconButton>
        )}

        {(userReducerState?.entitiesAndActivities?.roles?.includes(
          roles?.SUPER_ADMIN
        ) ||
          (userFeatures?.includes(features?.ADD_SYSTEM_DETAILS) &&
            application?.applicationOwnerEmails?.includes(
              userReducerState?.user?.email
            ))) && (
          <IconButton
            size="small"
            color="secondary"
            onClick={onDelete}
            disabled={load}
          >
            <Delete style={{ fontSize: 18 }} />
          </IconButton>
        )}
      </div>
    </Paper>
  );
}

export default CustomCard;
