export const getUserById = (userId, users) => {
  return users?.find((user) => user.empId === userId);
};

export const getCurrentLoggedInUser = (userEmail, users) => {
  return users?.find((user) => user.emailId === userEmail);
};

export const findUserById = (emailId, users) => {
  return users?.find((user) => user?.emailId === emailId) || null;
};

export const findIdpUserByEmailId = (emailId, idpUsers) => {
  return idpUsers?.find((idpUser) => idpUser?.emailId === emailId) || null;
};

export const findApplicationById = (id, applications) => {
  return applications?.find((application) => application?.id === id) || null;
};

export const findRoleById = (id, roles) => {
  return roles?.find((role) => role?.id === id) || null;
};

export const findEntityById = (id, entities) => {
  return entities?.find((entitie) => entitie?.id === id) || null;
};

export const findActivityById = (id, activities) => {
  return activities?.find((activity) => activity?.id === id) || null;
};

export const findLicenseById = (id, licenses) => {
  return licenses?.find((license) => license?.id === id) || null;
};

export const showFeatureNumber = (license) => {
  if (license?.licenseType === "Limited Feature") {
    if (license?.featuresId) {
      const licenseFeaturesList = license?.featuresId?.split(",");
      if (licenseFeaturesList?.length > 1) {
        return "(" + licenseFeaturesList?.length + " features)";
      }
      return "(" + licenseFeaturesList?.length + " feature)";
    }
    return "(0 feature)";
  } else {
    return "";
  }
};

export const findApiByid = (id, apis) => {
  return apis?.find((api) => Number(api?.id) === Number(id)) || null;
};

export function sorting(key, op) {
  return (obj1, obj2) => {
    let comp = 0;
    if (obj1?.[key]?.toLowerCase() < obj2?.[key]?.toLowerCase()) {
      comp = -1;
    } else {
      comp = 1;
    }
    return comp * op;
  };
}
