import React, { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  Paper,
  Tabs,
  Tab,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack, Refresh } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { findApplicationById } from "../../Utility/basic";
import { HiDownload } from "react-icons/hi";
import { authVerify } from "../../Utility/auth";
import { CSVLink } from "react-csv";
import { systemDetailsHeading } from "../../Utility/file";
import SystemUserTable from "./SystemUserTable";
import { a11yProps, TabPanel } from "../TabPanel";
import { appType } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  applicationPageHeaderHeight,
  cwAppDetailPageHeaderHeight,
  cwAppDetailPageTabHeight,
  ncwAppDetailPageHeaderHeight,
} from "../../Data/cssConstant";

const useStyles = makeStyles((theme) => ({
  systemInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    height: "64vh",
  },
  systemInfoItemContainer: {
    margin: 4,
    alignItems: "center",
  },
  systemInfoContainerLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  systemInfoContainerText: {
    fontSize: 12,
  },

  applicationContainer: {
    width: "100%",
  },
  applicationHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 10px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
  },
  applicationHeadeTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  applicationHeadeAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  applicationContentContainer: {
    height: "66vh",
    padding: "0 4px",
  },
  applicationDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    marginTop: 8,
  },
  applicationDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    maxWidth: 80,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0px 8px",
  },
  applicationDetailHeaderItemSelected: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },

  userDetailTab: {
    fontSize: 14,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userDetailTabSelected: {
    backgroundColor: "rgb(241, 245, 254)",
  },
}));

export const SystemBasicInfo = ({ selectedApplication, height }) => {
  const classes = useStyles();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [application, setApplication] = useState(null);

  useEffect(() => {
    const app = basicReducerState?.applications?.find(
      (app) => app?.id === Number(selectedApplication)
    );
    setApplication(app);
  }, [selectedApplication]);

  return (
    <div className={classes.systemInfoContainer} style={{ height: height }}>
      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.name}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Label
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.label}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Description
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.description}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Application Type
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {appType[application?.applicationType]}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Owners
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.applicationOwnerEmails?.join(", ")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Unique Ids
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.uniqueIds?.join(", ")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Status
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.status}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Created On
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.createdOn}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Created By
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.createdBy}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Updated On
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.updatedOn}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.systemInfoItemContainer}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Typography className={classes.systemInfoContainerLabel}>
            Updated By
          </Typography>
        </Grid>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <Typography className={classes.systemInfoContainerText}>
            {application?.updatedBy}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

function SystemDetails({ selectedApplication, setSelectedApplication }) {
  const classes = useStyles();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();
  const [
    selectedApplicationDetailContentType,
    setSelectedApplicationDetailContentType,
  ] = useState(0);
  const [load, setLoad] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [empId, setEmpId] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const id = Boolean(anchorEl)
    ? `workTip-${empId ? selectedEmp : ""}`
    : undefined;
  const [params, setParams] = useState({ applicationId: selectedApplication });

  const getApplicationById = (applicationId) => {
    const application = findApplicationById(
      applicationId,
      basicReducerState?.applications
    );
    return application;
  };
  const getSystemDetail = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const getSystemDetailUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/read?id=${selectedApplication}`;
      // const getSystemDetailUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/read?id=${selectedApplication}`;
      const getSystemDetailRequestParam = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
      };
      fetch(getSystemDetailUrl, getSystemDetailRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          setUsers(data?.data);
          setFilteredUsers(data?.data);
          downloadData(data?.data);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const downloadData = (data) => {
    var result = [systemDetailsHeading];
    data?.map((user) => {
      result.push([
        user?.displayName,
        user?.userId,
        user?.emailId,
        user?.roleName?.join(", "),
        user?.reqCreatedOn,
        user?.createdBy,
        user?.reqUpdatedOn,
        user?.approvedBy,
      ]);
      return null;
    });
    // console.log(result);
    setCsvData(result);
  };

  useEffect(() => {
    getSystemDetail();
  }, [selectedApplication]);

  return (
    <div style={{ width: "100%", padding: 16, height: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            style={{
              border: "1px solid #3f51b5",
              borderRadius: "50%",
            }}
            onClick={() => {
              setSelectedApplication(null);
              setParams(null);
            }}
          >
            <ArrowBack style={{ fontSize: 20 }} color="primary" />
          </IconButton>

          <Typography style={{ marginLeft: 8, fontSize: 14 }}>
            {getApplicationById(params?.applicationId)?.name || ""}
          </Typography>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <CSVLink data={csvData}>
            <IconButton size="small" style={{ marginLeft: 6 }}>
              <HiDownload style={{ fontSize: 20 }} />
            </IconButton>
          </CSVLink>

          <IconButton size="small" onClick={() => {}}>
            <Refresh style={{ fontSize: 20 }} />
          </IconButton>
        </div>
      </div>

      <Paper
        style={{
          marginTop: 8,
          height: `calc(100vh - ${appHeaderHeight} - ${applicationPageHeaderHeight})`,
        }}
      >
        <Tabs
          value={selectedApplicationDetailContentType}
          onChange={(e, newValue) =>
            setSelectedApplicationDetailContentType(newValue)
          }
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ minHeight: ncwAppDetailPageHeaderHeight }}
        >
          <Tab
            label="Basic Info"
            className={`${classes.userDetailTab} ${
              selectedApplicationDetailContentType === 0 &&
              classes.userDetailTabSelected
            }`}
            {...a11yProps(0)}
          />

          <Tab
            label="Users"
            className={`${classes.userDetailTab} ${
              selectedApplicationDetailContentType === 1 &&
              classes.userDetailTabSelected
            }`}
            {...a11yProps(1)}
          />
        </Tabs>

        <TabPanel value={selectedApplicationDetailContentType} index={0}>
          <SystemBasicInfo
            selectedApplication={selectedApplication}
            height={`calc(100vh - ${appHeaderHeight} - ${applicationPageHeaderHeight} - ${ncwAppDetailPageHeaderHeight} - 4px)`}
          />
        </TabPanel>

        <TabPanel value={selectedApplicationDetailContentType} index={1}>
          <SystemUserTable
            filteredUsers={filteredUsers}
            load={load}
            height={`calc(100vh - ${appHeaderHeight} - ${applicationPageHeaderHeight} - ${ncwAppDetailPageHeaderHeight} - 4px`}
          />
        </TabPanel>
      </Paper>
    </div>
  );
}

export default SystemDetails;
