import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PieChart, Pie, Cell } from "recharts";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  chartRequestContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F2F7FF",
    borderRadius: 8,
    padding: 16,
    marginRight: 10,
  },
  chartRequestContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginLeft: 8,
  },
  chartRequestHeading: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  chartRequestType: {
    display: "flex",
    alignItems: "center",
  },
  chartRequestTypeDot: {
    width: 8,
    height: 8,
    borderRadius: 50,
    marginRight: 4,
  },
  chartRequestTypeLabel: {
    fontSize: 12,
    whiteSpace: "nowrap",
  },
  chartRequestTypeValue: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 10,
  },
}));

function ChartRequest({ title, data, load }) {
  const classes = useStyle();

  return (
    <Paper className={classes.chartRequestContainer}>
      {load ? (
        <Skeleton animation="wave" variant="circle" width={80} height={80} />
      ) : (
        <PieChart width={90} height={90}>
          <Pie
            data={data}
            dataKey="value"
            cx={35}
            cy={40}
            innerRadius={20}
            outerRadius={40}
            fill="#8884d8"
            paddingAngle={2}
          >
            {data?.map((entry, index) => (
              <Cell key={index} fill={entry?.color} />
            ))}
          </Pie>
        </PieChart>
      )}

      <div className={classes.chartRequestContent}>
        <Typography className={classes.chartRequestHeading}>{title}</Typography>

        <Grid container>
          {data?.map((entry, index) => (
            <Grid key={index} item xs={6}>
              <div className={classes.chartRequestType}>
                <div
                  className={classes.chartRequestTypeDot}
                  style={{
                    backgroundColor: entry?.color,
                  }}
                />

                <Typography className={classes.chartRequestTypeLabel}>
                  {entry?.name}
                </Typography>
              </div>

              <Typography className={classes.chartRequestTypeValue}>
                {entry?.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </Paper>
  );
}

export default ChartRequest;
