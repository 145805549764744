import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { authVerify } from "../../Utility/auth";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getAllPayloads } from "../../Action/action";
import {
  setPayloads,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";

const useStyle = makeStyles((theme) => ({
  newItemDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newItemDialogActionsContainer: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

function NewPayload({
  open,
  onClose,
  selectApi,
  update,
  title,
  updatingPayload,
}) {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const initialPayload = {
    name: "",
    type: "",
    // param: "",
    description: "",
  };
  const [newPayload, setNewPayload] = useState(initialPayload);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const handleCreatePayload = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const insertPayloadUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload`;
      // const insertPayloadUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload`;
      const insertPayloadPayload = {
        createdBy: userReducerState?.user?.email,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: newPayload?.description,
        apiId: selectApi,
        isActive: 1,
        isDeleted: 0,
        name: newPayload?.name,
        type: newPayload?.type,
        // param: newPayload?.param,
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const insertPayloadRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(insertPayloadPayload),
      };
      fetch(insertPayloadUrl, insertPayloadRequestParam)
        .then((res) => res.json())
        .then((payload_data) => {
          setLoad(true);
          setNewPayload(initialPayload);
          onClose();
          getAllPayloads(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setPayloads(data?.data || []));
              setLoad(false);

              dispatch(
                setResponseMessage({
                  open: true,
                  status: payload_data?.status ? "success" : "error",
                  message: payload_data?.status
                    ? "Payload created successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setLoad(false);
            }
          );
        })
        .catch((err) => {
          setLoad(true);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleUpdatePayload = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updatePayloadUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload/modify`;
      // const updatePayloadUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload/modify`;
      const updatePayloadPayload = {
        id: newPayload?.id,
        createdBy: newPayload?.createdBy,
        createdOn: moment(new Date(newPayload?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: newPayload?.description,
        apiId: selectApi,
        isActive: 1,
        isDeleted: 0,
        name: newPayload?.name,
        type: newPayload?.type,
        // param: newPayload?.param,
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updatePayloadRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updatePayloadPayload),
      };
      fetch(updatePayloadUrl, updatePayloadRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(true);
          setNewPayload(initialPayload);
          onClose();
          dispatch(
            setPayloads(
              basicReducerState?.payloads?.map((payload) =>
                Number(payload?.id) === Number(newPayload?.id)
                  ? {
                      ...payload,
                      name: newPayload?.name,
                      type: newPayload?.type,
                      description: newPayload?.description,
                    }
                  : { ...payload }
              ) || []
            )
          );

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Payload updated successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setLoad(true);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  useEffect(() => {
    if (updatingPayload) {
      setNewPayload(updatingPayload);
    } else {
      setNewPayload(initialPayload);
    }
  }, [updatingPayload]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newItemDialogTitle}>{title}</DialogTitle>

      <DialogContent>
        {load && <Loading load={load} />}

        <TextField
          variant="standard"
          fullWidth
          required
          label="Name"
          value={newPayload?.name}
          onChange={(e) =>
            setNewPayload({ ...newPayload, name: e.target.value })
          }
        />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Type"
          value={newPayload?.type}
          onChange={(e) =>
            setNewPayload({ ...newPayload, type: e.target.value })
          }
        />

        {/* <TextField
          variant="standard"
          fullWidth
          required
          label="Param"
          value={newPayload?.param}
          onChange={(e) =>
            setNewPayload({ ...newPayload, param: e.target.value })
          }
        /> */}

        <TextField
          variant="standard"
          fullWidth
          required
          label="Description"
          value={newPayload?.description}
          onChange={(e) =>
            setNewPayload({
              ...newPayload,
              description: e.target.value,
            })
          }
        />
      </DialogContent>

      <DialogActions className={classes.newItemDialogActionsContainer}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onClose();
            setNewPayload(initialPayload);
          }}
          style={{ textTransform: "capitalize" }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            if (update) {
              handleUpdatePayload();
            } else {
              handleCreatePayload();
            }
          }}
          disabled={
            newPayload?.name?.length === 0 ||
            newPayload?.type?.length === 0 ||
            // newPayload?.param?.length === 0 ||
            newPayload?.description?.length === 0 ||
            load
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewPayload;
