import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  dialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  dialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

function DeletionMessageBox({ open, onClose, onDelete, load }) {
  const classes = useStyle();
  const [deleteText, setDeleteText] = useState("");

  useEffect(() => {
    setDeleteText("");
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>Confirm Delete</DialogTitle>

      <DialogContent>
        <TextField
          id="standard-basic"
          label="Type 'delete' if you wanted to delete it"
          fullWidth
          value={deleteText}
          onChange={(e) => setDeleteText(e.target.value)}
        />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setDeleteText("");
          }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            onDelete();
            onClose();
            setDeleteText("");
          }}
          style={{ textTransform: "capitalize" }}
          disabled={load || deleteText?.toLowerCase() !== "delete"}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeletionMessageBox;
