import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iag from "./Iag";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "./Components/Loading";
import {
  getAllUsers,
  getIdpUsers,
  getAllRequests,
  getAllRoles,
  getAllApplications,
  getAllEntities,
  getAllApis,
  getAllActivities,
  getAllPayloads,
  getAllGroups,
  getAllRoleTemplates,
  getAllLicenses,
} from "./Action/action";
import {
  setUsers,
  setIdpUsers,
  setRequests,
  setRoles,
  setApplications,
  setEntities,
  setApis,
  setActivities,
  setPayloads,
  setGroups,
  setRoleTemplates,
  setLienses,
} from "./Redux/Reducers/basicReducer";
import {
  setEntitiesAndActivities,
  setIsSessionExpired,
  setKeycloak,
  setToken,
  setUser,
} from "./Redux/Reducers/userReducer";
import { authVerify } from "./Utility/auth";
import LoadingApp from "./Components/LoadingApp";

function App({ keycloak }) {
  const userReducerState = useSelector((state) => state.userReducer);
  const [load, setLoad] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    saveBasicDetail();
  }, []);

  useEffect(() => {
    if (userReducerState?.user && isAccess) {
      // getAllRequests(
      //   keycloak?.token,
      //   () => {
      //     // setLoad(true);
      //   },
      //   (data) => {
      //     dispatch(setRequests(data?.data || []));
      //     // setLoad(false);
      //   },
      //   (err) => {
      //     // setLoad(false);
      //   }
      // );
      getAllUsers(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setUsers(data.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getIdpUsers(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setIdpUsers(data.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllRoles(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setRoles(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllRoleTemplates(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setRoleTemplates(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllApplications(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setApplications(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllEntities(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setEntities(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllApis(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setApis(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllActivities(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setActivities(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllPayloads(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setPayloads(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
      getAllGroups(
        keycloak?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setGroups(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
      getAllLicenses(
        userReducerState.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setLienses(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    }
  }, [userReducerState?.user, isAccess]);

  useEffect(() => {
    // if (authVerify(keycloak?.token)) {
    setLoad(true);
    fetch(
      `${process.env.REACT_APP_IWAServices}/api/v1/applications/entitiesAndActivities?applicationId=${process.env.REACT_APP_IWAId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "applicatin/json",
          applicaionId: process.env.REACT_APP_IWAId,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch(setEntitiesAndActivities(data?.data));
        setLoad(false);
        if (data?.data?.roles?.length > 0) {
          setIsAccess(true);
        } else {
          setIsAccess(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
    // } else {
    //   dispatch(setIsSessionExpired(true));
    // }
  }, []);

  const saveBasicDetail = () => {
    dispatch(setToken(keycloak.token));
    dispatch(setUser(keycloak.tokenParsed));
    dispatch(setIsSessionExpired(false));
  };

  // return load ? <Loading load={load} /> : <Iag keycloak={keycloak} />;
  return (
    <Router>
      {load ? (
        <LoadingApp message={"Please wait for sometime"} />
      ) : isAccess ? (
        <Iag keycloak={keycloak} />
      ) : (
        <LoadingApp message={"You don't have access to this application"} />
      )}
    </Router>
  );
}

export default App;
