import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { roles, triggersName } from "../../Data/data";
import { setPowerBi } from "../../Redux/Reducers/basicReducer";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import { authVerify } from "../../Utility/auth";
import Loading from "../Loading";
import NotFound from "../NotFound";
import Dashboard from "./Dashboard";

function Index() {
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();
  const userRoles = userReducerState?.entitiesAndActivities?.roles || [];
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (userRoles?.length === 0) {
      return;
    }
    getPowerBiResult();
  }, [userRoles]);

  const getPowerBiResult = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      let getPowerBiGraphUrl = ``;
      if (userRoles?.includes(roles?.SUPER_ADMIN)) {
        getPowerBiGraphUrl += `${process.env.REACT_APP_PowerBiServices}/api/${triggersName?.SUPER_ADMIN}?code=vZxAUq-JYvA7XsOFun6g2u-5BN3224d4zTHV98dEwKi8AzFuflSmkw==`;
      } else if (userRoles?.includes(roles?.PEOPLE_MANAGER)) {
        getPowerBiGraphUrl += `${process.env.REACT_APP_PowerBiServices}/api/${triggersName?.PEOPLE_MANAGER}?code=vZxAUq-JYvA7XsOFun6g2u-5BN3224d4zTHV98dEwKi8AzFuflSmkw==`;
      } else if (userRoles?.includes(roles?.SYSTEM_OWNER)) {
        getPowerBiGraphUrl += `${process.env.REACT_APP_PowerBiServices}/api/${triggersName?.SYSTEM_OWNER}?code=vZxAUq-JYvA7XsOFun6g2u-5BN3224d4zTHV98dEwKi8AzFuflSmkw==`;
      } else if (userRoles?.includes(roles?.BUSINESS_USER)) {
        getPowerBiGraphUrl += `${process.env.REACT_APP_PowerBiServices}/api/${triggersName?.BUSINESS_USER}?code=vZxAUq-JYvA7XsOFun6g2u-5BN3224d4zTHV98dEwKi8AzFuflSmkw==`;
      } else if (userRoles?.includes(roles?.HR_MANAGER)) {
        getPowerBiGraphUrl += `${process.env.REACT_APP_PowerBiServices}/api/${triggersName?.HR_MANAGER}?code=vZxAUq-JYvA7XsOFun6g2u-5BN3224d4zTHV98dEwKi8AzFuflSmkw==`;
      }
      const getPowerBiGraphRequestParams = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userReducerState?.token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };
      fetch(getPowerBiGraphUrl, getPowerBiGraphRequestParams)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setLoad(false);
          dispatch(setPowerBi(data));
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
          dispatch(setPowerBi(null));
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <>
      {userRoles?.length === 0 ? (
        <NotFound />
      ) : load ? (
        <Loading load={load} />
      ) : (
        <Dashboard />
      )}
    </>
  );
}

export default Index;
