import React from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../Loading";
import {
  appHeaderHeight,
  cwAppDetailPageHeaderHeight,
  cwAppDetailPageTabHeight,
} from "../../Data/cssConstant";

const useStyles = makeStyles((theme) => ({
  applicationContainer: {
    width: "100%",
  },
  applicationHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 10px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
  },
  applicationHeadeTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  applicationHeadeAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  applicationContentContainer: {
    height: "66vh",
    padding: "0 4px",
  },
  applicationDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    marginTop: 8,
  },
  applicationDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    maxWidth: 80,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0px 8px",
  },
  applicationDetailHeaderItemSelected: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },

  usersTableContainer: {
    width: "100%",
  },
  usersTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  usersTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  usersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  usersTableBodyRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  usersTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  usersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  usersTableBodyTextHide: {
    overflow: "hidden",
    maxWidth: 180,
    textOverflow: "ellipsis",
  },
}));

function SystemUserTable({ filteredUsers, load, height }) {
  const classes = useStyles();

  return (
    <>
      <Loading load={load} />

      <TableContainer
        // component={Paper}
        className={`${classes.usersTableContainer} iagScroll`}
        style={{ height: height }}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.usersTableHead}>
            <TableRow>
              <TableCell
                //    align="center"
                className={classes.usersTableHeadCell}
              >
                Name
              </TableCell>

              <TableCell
                // align="center"
                className={classes.usersTableHeadCell}
              >
                User Id
              </TableCell>

              <TableCell
                // align="center"
                className={classes.usersTableHeadCell}
              >
                Email ID
              </TableCell>

              <TableCell
                // align="center"
                className={classes.usersTableHeadCell}
              >
                Roles
              </TableCell>

              <TableCell
                align="center"
                className={classes.usersTableHeadCell}
                colSpan={2}
              >
                Request Detail
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={`${classes.usersTableBody} iagScroll`}>
            {filteredUsers?.map((user, index) => {
              return (
                <TableRow
                  key={`${user?.id}-${index}`}
                  className={`${classes.usersTableBodyRow}`}
                >
                  <TableCell
                    // align="center"
                    className={classes.usersTableBodyCell}
                  >
                    {user?.displayName || "-"}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.usersTableBodyCell}
                  >
                    {user?.userId || "-"}
                  </TableCell>

                  <Tooltip title={user?.emailId || "-"}>
                    <TableCell
                      // align="center"
                      className={`${classes.usersTableBodyCell} ${classes.usersTableBodyTextHide}`}
                    >
                      {user?.emailId || "-"}
                    </TableCell>
                  </Tooltip>

                  <Tooltip title={user?.roleName?.join(", ") || "-"}>
                    <TableCell
                      // align="center"
                      className={`${classes.usersTableBodyCell} ${classes.usersTableBodyTextHide}`}
                    >
                      {user?.roleName?.join(", ") || "-"}
                    </TableCell>
                  </Tooltip>

                  <TableCell
                    // align="center"
                    className={`${classes.usersTableBodyCell}`}
                  >
                    <Typography style={{ fontSize: 12 }}>
                      {`Created On: ${user?.reqCreatedOn || "-"}`}
                    </Typography>

                    <Typography style={{ fontSize: 12 }}>
                      {`Updated On: ${user?.reqUpdatedOn || "-"}`}
                    </Typography>
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={`${classes.usersTableBodyCell}`}
                  >
                    <Typography style={{ fontSize: 12 }}>
                      {`Raised By: ${user?.createdBy || "-"}`}
                    </Typography>

                    <Typography style={{ fontSize: 12 }}>
                      {`Approved By: ${user?.approvedBy || "-"}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default SystemUserTable;
