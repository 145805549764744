import { combineReducers } from "redux";
import basicReducer from "./basicReducer";
import userReducer from "./userReducer";

const reducers = combineReducers({
  basicReducer: basicReducer,
  userReducer: userReducer,
});

export default reducers;
