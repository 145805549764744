import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Loading from "./Loading";

const useStyle = makeStyles((theme) => ({
  idpUsersDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  idpUsersDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

const UploadFile = ({
  open,
  onClose,
  onUpload,
  file,
  setFile,
  disableCondition,
  children,
  load,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle className={classes.idpUsersDialogTitle}>
        Upload File
      </DialogTitle>

      <DialogContent>
        <Loading load={load} />

        <input
          type="file"
          name="file"
          accept=".csv"
          onChange={(event) => {
            if (event.target.files.length > 0) {
              if (event.target.files[0].type === "text/csv") {
                setFile(event.target.files[0]);
              } else {
                setFile(null);
              }
            } else {
              setFile(null);
            }
          }}
          required
          className={userReducerState?.darkMode && "inputDarkMode"}
        />

        {children}
      </DialogContent>

      <DialogActions className={classes.idpUsersDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
          }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={onUpload}
          style={{ textTransform: "capitalize" }}
          disabled={disableCondition || load}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFile;
