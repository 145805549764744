import React from "react";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    width: 32,
    height: 32,
  },
}));

export const nameShorten = (name) => {
  var splittedName = [""];
  if (name) {
    splittedName = name.trim().split(",")[0].split(" ");
    // while (splittedName[splittedName.length - 1][0] === "(") {
    //   splittedName = splittedName?.filter(
    //     (name, ind) => ind !== splittedName?.length - 1
    //   );
    // }
  }
  return splittedName?.length > 1
    ? `${splittedName[0][0]}${splittedName[splittedName.length - 1][0]}`
    : splittedName?.[0]?.length > 0
    ? `${splittedName[0][0]}`
    : "";
};

function CustomAvatar(props) {
  const classes = useStyles();

  return (
    <Avatar
      {...props}
      src={props.src}
      className={`${classes.avatar} ${props?.className}`}
      sx={props.sx && props.sx}
    >
      {nameShorten(props.name)}
    </Avatar>
  );
}

export default CustomAvatar;
