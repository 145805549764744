import { createSlice } from "@reduxjs/toolkit";

export const userReducer = createSlice({
  name: "userReducer",
  initialState: {
    darkMode: false,
    // keycloak: null,
    token: null,
    user: null,
    entitiesAndActivities: null,
    isSessionExpired: false,
  },
  reducers: {
    setDarkMode: (state) => {
      state.darkMode = state.darkMode ? false : true;
    },
    // setKeycloak: (state, action) => {
    //   state.keycloak = action.payload;
    // },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setEntitiesAndActivities: (state, action) => {
      state.entitiesAndActivities = action.payload;
    },
    setIsSessionExpired: (state, action) => {
      state.isSessionExpired = action.payload;
    },
  },
});

export const {
  setDarkMode,
  // setKeycloak,
  setToken,
  setUser,
  setEntitiesAndActivities,
  setIsSessionExpired,
} = userReducer.actions;

export default userReducer.reducer;
