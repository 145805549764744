import React, { useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { MdClose } from "react-icons/md";

const useStyle = makeStyles((theme) => ({}));

function CustomAutoComplete({
  selectedList,
  dataList,
  onSelect,
  placeholder,
  renderOption,
  selectedOption,
}) {
  const classes = useStyle();
  const [search, setSearch] = useState("");

  return (
    <div style={{ marginTop: 8 }}>
      <Typography color="primary" style={{ fontSize: 10 }}>
        {placeholder}
      </Typography>

      {selectedList?.length > 0 && (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
          {selectedList?.map((ownerEmail) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #bbb6b6",
                borderRadius: 20,
                padding: "4px 10px",
                background: "#eddada",
              }}
            >
              {selectedOption(ownerEmail)}

              <MdClose style={{ marginLeft: 8, cursor: "pointer" }} />
            </div>
          ))}
        </div>
      )}

      <TextField
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      {search?.length > 0 && (
        <div
          style={{
            position: "absolute",
            maxHeight: 150,
            background: "#fff",
            width: "92%",
            zIndex: 99,
            overflow: "overlay",
          }}
        >
          {dataList
            ?.filter(
              (data) =>
                !selectedList?.includes(data) &&
                data?.toLowerCase()?.includes(search?.toLowerCase())
            )
            ?.map((data) => (
              <div
                style={{ cursor: "pointer", padding: "4px 10px" }}
                onClick={() => {
                  onSelect(data);
                  setSearch("");
                }}
              >
                {renderOption(data)}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default CustomAutoComplete;
