import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { authVerify } from "../../Utility/auth";
import { getAllApis } from "../../Action/action";
import { setApis, setResponseMessage } from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";

const useStyle = makeStyles((theme) => ({
  newApiDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newApiDialogActionsContainer: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

const NewApi = ({ open, onClose, title, update, updatingApi, applicationId }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const initialState = {
    name: "",
    type: "",
    description: "",
  };
  const [newApi, setNewApi] = useState(initialState);
  const [onLoad, setOnLoad] = useState(false);
  const dispatch = useDispatch();
  const apiType = [
    "GET",
    "POST",
    "PUT",
    "PATCH",
    "DELETE",
    "COPY",
    "HEAD",
    "OPTIONS",
    "LINK",
    "UNLINK",
    "PURGE",
    "LOCK",
    "UNLOCK",
    "PROPFIND",
    "VIEW",
  ];

  const handleCreateApi = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const insertApiUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload`;
      // const insertApiUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload`;
      const insertApiPayload = {
        applicationId: applicationId,
        createdBy: userReducerState?.user?.email,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: newApi?.description,
        isActive: 1,
        isDeleted: 0,
        type: newApi?.type,
        name: newApi?.name,
        // status: "Active",
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      console.log(insertApiPayload);
      const insertApiRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(insertApiPayload),
      };
      fetch(insertApiUrl, insertApiRequestParam)
        .then((res) => res.json())
        .then((api_data) => {
          setOnLoad(false);
          setNewApi(initialState);
          getAllApis(
            userReducerState.token,
            () => {
              setOnLoad(true);
            },
            (data) => {
              dispatch(setApis(data?.data || []));
              setOnLoad(false);
              onClose();

              dispatch(
                setResponseMessage({
                  open: true,
                  status: api_data?.status ? "success" : "error",
                  message: api_data?.status
                    ? "Api created successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setOnLoad(false);
            }
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleUpdateApi = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const updateApiUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/modify`;
      // const updateApiUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/modify`;
      const updateApiPayload = {
        id: newApi?.id,
        applicationId: applicationId,
        createdBy: newApi?.createdBy,
        createdOn: moment(new Date(newApi?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: newApi?.description,
        isActive: 1,
        isDeleted: 0,
        type: newApi?.type,
        name: newApi?.name,
        // status: "Active",
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updateApiRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateApiPayload),
      };
      fetch(updateApiUrl, updateApiRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          setNewApi(initialState);
          dispatch(
            setApis(
              basicReducerState?.apis?.map((api) =>
                Number(api?.id) === Number(newApi?.id)
                  ? {
                      ...api,
                      name: newApi?.name,
                      type: newApi?.type,
                      description: newApi?.description,
                    }
                  : { ...api }
              ) || []
            )
          );
          onClose();

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Api updated successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  useEffect(() => {
    if (updatingApi) {
      setNewApi(updatingApi);
    } else {
      setNewApi(initialState);
    }
  }, [updatingApi]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newApiDialogTitle}>{title}</DialogTitle>

      <DialogContent>
        <Loading load={onLoad} />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Name"
          value={newApi?.name}
          onChange={(e) => setNewApi({ ...newApi, name: e.target.value })}
        />

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="licenseType">Type</InputLabel>
          <Select
            labelId="licenseType"
            size="small"
            label="Type"
            style={{ fontSize: 12 }}
            value={newApi?.type}
            onChange={(e) => setNewApi({ ...newApi, type: e.target.value })}
          >
            {apiType?.map((type, index) => (
              <MenuItem value={type} key={index} style={{ fontSize: 12 }}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <TextField
          variant="standard"
          fullWidth
          required
          label="Type"
          value={newApi?.type}
          onChange={(e) => setNewApi({ ...newApi, type: e.target.value })}
        /> */}

        <TextField
          variant="standard"
          fullWidth
          required
          label="Description"
          value={newApi?.description}
          onChange={(e) =>
            setNewApi({
              ...newApi,
              description: e.target.value,
            })
          }
        />
      </DialogContent>

      <DialogActions className={classes.newApiDialogActionsContainer}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onClose();
            setNewApi(initialState);
          }}
          style={{ textTransform: "capitalize" }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            if (update) {
              handleUpdateApi();
            } else {
              handleCreateApi();
            }
          }}
          disabled={
            newApi?.name?.length === 0 ||
            newApi?.type?.length === 0 ||
            newApi?.description?.length === 0 ||
            onLoad
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewApi;
