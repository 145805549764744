import React from "react";
import {
  SIDEBAR_SUB_ITEM_GROUPS,
  SIDEBAR_SUB_ITEM_LICENSES,
  SIDEBAR_SUB_ITEM_ROLES,
  SIDEBAR_SUB_ITEM_SYSTEMS,
  SIDEBAR_SUB_ITEM_USERS,
} from "../../Data/data";
import Applications from "./Applications";
import Groups from "./Groups";
import Licenses from "./Licenses";
import Roles from "./Roles";
import Users from "./Users";

const Index = ({ selectedSubItem }) => {
  return (
    <>
      {selectedSubItem === SIDEBAR_SUB_ITEM_SYSTEMS && <Applications />}
      {selectedSubItem === SIDEBAR_SUB_ITEM_GROUPS && <Groups />}
      {selectedSubItem === SIDEBAR_SUB_ITEM_ROLES && <Roles />}
      {selectedSubItem === SIDEBAR_SUB_ITEM_USERS && <Users />}
      {selectedSubItem === SIDEBAR_SUB_ITEM_LICENSES && <Licenses />}
    </>
  );
};

export default Index;
