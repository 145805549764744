import React, { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBack,
  CheckCircle,
  Error,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import { CgSandClock } from "react-icons/cg";
import { Cell, Pie, PieChart } from "recharts";
import {
  requestDetailHeaderHeight,
  appHeaderHeight,
} from "../../Data/cssConstant";

const useStyle = makeStyles((theme) => ({
  requestDetailContainer: {
    width: "93%",
  },
  requestDetail: {
    width: "100%",
    padding: "24px 16px 0px 16px",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
  },
  requestDetailHeaderContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: 4,
    height: requestDetailHeaderHeight,
  },
  requestDetailHeaderBackIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },
  requestDetailHeaderText: {
    fontSize: 14,
    marginLeft: 8,
  },
  requestDetailLeftContent: {
    padding: "16px 8px",
  },
  requestDetailLeftContentContainer: {
    height: "100%",
    borderRadius: 16,
    backgroundColor: "#F1F5FE",
  },
  requestDetailLeftContentTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },
  requestDetailEmpDetail: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 16,
    overflow: "auto",
  },
  requestDetailEmpName: {
    fontSize: 24,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  requestDetailEmpId: {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  requestDetailEmpStatus: {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    padding: 0,
    textTransform: "uppercase",
  },
  requestDetailEmpSuccessStatus: {
    backgroundColor: "#E8FEEA",
    color: `${theme.palette.success.main} !important`,
  },
  requestDetailEmpErrorStatus: {
    backgroundColor: "#FFEDED",
    color: `${theme.palette.success.main} !important`,
  },
  requestDetailEmpProgressStatus: {
    backgroundColor: "#fff5e2",
    color: `${theme.palette.warning.main} !important`,
  },
  requestDetailLeftContentBottom: {
    padding: 16,
  },
  requestDetailEmpDetailLabel: {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  requestDetailEmpDetailValue: {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  requestDetailRightContainer: {
    height: "100%",
    padding: "16px 0px 0px 0px",
    boxShadow: "7px 0px 24px rgba(225, 225, 225, 0.25)",
  },
  requestDetailRightContent: {
    height: "100%",
    marginRight: 6,
    borderRadius: 16,
    border: `1px solid ${theme.palette.divider}`,
  },
  requestDetailRightContentHeading: {
    padding: 16,
    fontSize: 16,
    fontWeight: "bold",
  },
  requestDetailStepLabelText: {
    fontSize: 14,
  },
  requestDetailStepContainer: {
    display: "flex",
    flexDirection: "column",
  },
  requestDetailStepLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  requestDetailStepContentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    borderRadius: 8,
  },
  requestDetailStepContentProgressContainer: {
    border: `2px solid ${theme.palette.warning.main}`,
  },
  requestDetailStepContentErrorContainer: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  requestDetailStepContentSuccessContainer: {
    border: `2px solid ${theme.palette.success.main}`,
  },
  requestDetailStepContentText: {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  requestDetailStepContentStatus: {
    color: theme.palette.text.secondary,
  },
  requestDetailStepContentSubContainer: {
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.text.hint}`,
    padding: 10,
    borderRadius: 8,
  },
}));

const ProgressEmp = ({ selectedRequest }) => {
  const data = [
    {
      name: "success",
      value: selectedRequest?.completedRequest || 0,
      color: "#6BC975",
    },
    {
      name: "error",
      value: selectedRequest?.errorRequest || 0,
      color: "#DDDDDD",
    },
    {
      name: "in progress",
      value: selectedRequest?.inProgressRequest || 0,
      color: "#ff9800",
    },
  ];

  return (
    <PieChart width={110} height={150}>
      <Pie
        data={data}
        dataKey="value"
        cx={55}
        cy={75}
        innerRadius={40}
        outerRadius={50}
        fill="#8884d8"
      >
        {data?.map((entry, index) => (
          <Cell key={index} fill={entry?.color} />
        ))}
      </Pie>
    </PieChart>
  );
};

const ColorlibStepIcon = ({ success, error, progress }) => {
  return (
    <>
      {success && <CheckCircle style={{ color: "#6BC975" }} />}
      {error && <Error style={{ color: "#EF7474" }} />}
      {progress && <CgSandClock style={{ color: "#ff9800" }} />}
    </>
  );
};

function RequestDetail({
  selectedSubItem,
  selectedRequest,
  setSelectedRequest,
}) {
  const classes = useStyle();
  const [activeStep, setActiveStep] = useState(-1);
  // const [subSystemExpand, setSubSystemExpand] = useState(-1);

  return (
    <div className={classes.requestDetailContainer}>
      <div className={classes.requestDetail}>
        <div className={classes.requestDetailHeaderContainer}>
          <IconButton
            size="small"
            className={classes.requestDetailHeaderBackIcon}
            onClick={() => {
              setSelectedRequest(null);
            }}
          >
            <ArrowBack style={{ fontSize: 20 }} color="primary" />
          </IconButton>

          <Typography className={classes.requestDetailHeaderText}>
            {selectedSubItem} for {selectedRequest?.firstName}{" "}
            {selectedRequest?.lastName} (Personal ID -{" "}
            {selectedRequest?.employeeId})
          </Typography>
        </div>
      </div>

      <Grid
        container
        spacing={0}
        style={{
          height: `calc(100vh - ${appHeaderHeight} - ${requestDetailHeaderHeight} - 13px)`,
        }}
      >
        <Grid
          item
          xs={4}
          className={classes.requestDetailLeftContent}
          style={{ height: "100%" }}
        >
          <Paper className={`${classes.requestDetailLeftContentContainer} iagScroll`}>
            <div className={classes.requestDetailLeftContentTop}>
              <ProgressEmp selectedRequest={selectedRequest} />

              <div className={classes.requestDetailEmpDetail}>
                <Typography className={classes.requestDetailEmpName}>
                  {selectedRequest?.firstName} {selectedRequest?.lastName}
                </Typography>

                <Typography className={classes.requestDetailEmpId}>
                  {selectedRequest?.employeeId}
                </Typography>

                {(selectedRequest?.inProgressRequest !== 0 ||
                  selectedRequest?.errorRequest !== 0) && (
                  <Button
                    className={`${classes.requestDetailEmpStatus} ${classes.requestDetailEmpProgressStatus}`}
                    variant="text"
                    disabled
                  >
                    {"in progress"}
                  </Button>
                )}

                {selectedRequest?.inProgressRequest === 0 &&
                  selectedRequest?.errorRequest === 0 &&
                  selectedRequest?.completedRequest !== 0 && (
                    <Button
                      className={`${classes.requestDetailEmpStatus} ${classes.requestDetailEmpSuccessStatus}`}
                      variant="text"
                      disabled
                    >
                      {"complete"}
                    </Button>
                  )}

                {selectedRequest?.inProgressRequest === 0 &&
                  selectedRequest?.errorRequest === 0 &&
                  selectedRequest?.completedRequest === 0 && (
                    <Button
                      className={`${classes.requestDetailEmpStatus}`}
                      variant="text"
                      disabled
                    >
                      {"upcomming"}
                    </Button>
                  )}
              </div>
            </div>

            <div className={classes.requestDetailLeftContentBottom}>
              {selectedRequest?.requestsDetails?.parentId && (
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Typography className={classes.requestDetailEmpDetailLabel}>
                      Request Id
                    </Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography className={classes.requestDetailEmpDetailValue}>
                      {selectedRequest?.requestsDetails?.parentId}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {selectedRequest?.emailId && (
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Typography className={classes.requestDetailEmpDetailLabel}>
                      Email Id
                    </Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography className={classes.requestDetailEmpDetailValue}>
                      {selectedRequest?.emailId}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography className={classes.requestDetailEmpDetailLabel}>
                    Location
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography className={classes.requestDetailEmpDetailValue}>
                    Delhi India
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography className={classes.requestDetailEmpDetailLabel}>
                    Joining Date
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography className={classes.requestDetailEmpDetailValue}>
                    May 09, 2022
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography className={classes.requestDetailEmpDetailLabel}>
                    Request Creation Date
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography className={classes.requestDetailEmpDetailValue}>
                    Jul 23, 2021
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography className={classes.requestDetailEmpDetailLabel}>
                    ServiceNow RITM
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography className={classes.requestDetailEmpDetailValue}>
                    RITM0058252
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography className={classes.requestDetailEmpDetailLabel}>
                    SLA
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography className={classes.requestDetailEmpDetailValue}>
                    YES
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={8} className={classes.requestDetailRightContainer}>
          <Paper className={`${classes.requestDetailRightContent} iagScroll`}>
            <Typography className={classes.requestDetailRightContentHeading}>
              Workflow
            </Typography>

            <Stepper orientation="vertical" activeStep={activeStep}>
              {selectedRequest?.requestsDetails?.details?.map(
                (detail, index) => (
                  <Step key={index}>
                    <StepLabel
                      StepIconComponent={() => (
                        <ColorlibStepIcon
                          success={
                            detail?.status?.toLowerCase() === "complete" ||
                            detail?.status?.toLowerCase() === "completed"
                          }
                          error={detail?.status?.toLowerCase() === "error"}
                          progress={
                            detail?.status?.toLowerCase() === "in progress"
                          }
                        />
                      )}
                    >
                      <div className={classes.requestDetailStepLabelContainer}>
                        <Typography
                          className={classes.requestDetailStepLabelText}
                        >
                          {detail?.systemName}
                        </Typography>

                        {activeStep === index ? (
                          <ExpandLess
                            onClick={() => {
                              setActiveStep(-1);
                              // setSubSystemExpand(-1);
                            }}
                          />
                        ) : (
                          <ExpandMore
                            onClick={() => {
                              setActiveStep(index);
                              // setSubSystemExpand(-1);
                            }}
                          />
                        )}
                      </div>
                    </StepLabel>

                    <StepContent>
                      <div
                        className={`${
                          classes.requestDetailStepContentContainer
                        } ${
                          detail?.status?.toLowerCase() === "in progress"
                            ? classes.requestDetailStepContentProgressContainer
                            : detail?.status?.toLowerCase() === "complete" ||
                              detail?.status?.toLowerCase() === "completed"
                            ? classes.requestDetailStepContentSuccessContainer
                            : detail?.status?.toLowerCase() === "eror"
                            ? classes.requestDetailStepContentErrorContainer
                            : classes.requestDetailStepContentErrorContainer
                        }`}
                      >
                        <Typography
                          className={`${classes.requestDetailStepContentText} ${classes.requestDetailStepContentStatus}`}
                        >
                          Status - {detail?.status}
                        </Typography>

                        <Typography
                          className={classes.requestDetailStepContentText}
                        >
                          Emergency -{" "}
                          {detail?.isEmergency === "Y" ? "Yes" : "No"}
                        </Typography>

                        <Typography
                          className={classes.requestDetailStepContentText}
                        >
                          System Type -{" "}
                          {detail?.systemType === "CW"
                            ? "Cherrywork"
                            : detail?.systemType === "PROD"
                            ? "Production"
                            : "Non-Production"}
                        </Typography>

                        <div
                          className={
                            classes.requestDetailStepContentSubContainer
                          }
                        >
                          <Typography
                            className={classes.requestDetailStepContentText}
                          >
                            {detail?.systemName} - {detail?.status}
                          </Typography>

                          <Typography
                            className={classes.requestDetailStepContentText}
                          >
                            Processing Date - {detail?.processingDate}
                          </Typography>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                )
              )}
            </Stepper>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default RequestDetail;

/* <Stepper orientation="vertical" activeStep={activeStep}>
  <Step>
    <StepLabel StepIconComponent={() => <ColorlibStepIcon success={true} />}>
      <div className={classes.requestDetailStepLabelContainer}>
        <Typography className={classes.requestDetailStepLabelText}>
          WorkCollab
        </Typography>

        {activeStep === 0 ? (
          <ExpandLess
            onClick={() => {
              setActiveStep(-1);
              setSubSystemExpand(-1);
            }}
          />
        ) : (
          <ExpandMore
            onClick={() => {
              setActiveStep(0);
              setSubSystemExpand(-1);
            }}
          />
        )}
      </div>
    </StepLabel>

    <StepContent>
      // <Typography className={classes.requestDetailStepContentText}>
      //   For each ad campaign that you create, you can control how much you're
      //   willing to spend on clicks and conversions, which networks and
      //   geographical locations you want your ads to show on, and more
      // </Typography>
       
      <div
        className={`${classes.requestDetailStepContentContainer} ${classes.requestDetailStepContentSuccessContainer}`}
      >
        <Typography
          className={`${classes.requestDetailStepContentText} ${classes.requestDetailStepContentStatus}`}
        >
          Status - In Progress
        </Typography>

        <Typography className={classes.requestDetailStepContentText}>
          Onboarding Duration -
        </Typography>

        <Typography className={classes.requestDetailStepContentText}>
          End Time -
        </Typography>

        <div className={classes.requestDetailStepContentSubContainer}>
          <Typography className={classes.requestDetailStepContentText}>
            WorkCollab - In progress
          </Typography>
          <Typography className={classes.requestDetailStepContentText}>
            Able to make a connection
          </Typography>
          <Typography className={classes.requestDetailStepContentText}>
            P001703 - 7/23/2021 - No Connectivity issue
          </Typography>
          <Typography className={classes.requestDetailStepContentText}>
            Onboarding Duration -
          </Typography>
          // <Typography className={classes.requestDetailStepContentText}>
          //   End Time - Jul 23, 2021 5:30:00 AM
          // </Typography>
          
        </div>
      </div>
    </StepContent>
  </Step>

  <Step>
    <StepLabel StepIconComponent={() => <ColorlibStepIcon success={true} />}>
      <div className={classes.requestDetailStepLabelContainer}>
        <Typography className={classes.requestDetailStepLabelText}>
          WorkCTest
        </Typography>

        {activeStep === 1 ? (
          <ExpandLess
            onClick={() => {
              setActiveStep(-1);
              setSubSystemExpand(-1);
            }}
          />
        ) : (
          <ExpandMore
            onClick={() => {
              setActiveStep(1);
              setSubSystemExpand(-1);
            }}
          />
        )}
      </div>
    </StepLabel>

    <StepContent>
      <div className={classes.requestDetailStepContainer}>
        <div className={classes.requestDetailStepLabelContainer}>
          <Typography className={classes.requestDetailStepLabelText}>
            WorkCTest Demo
          </Typography>

          {subSystemExpand === 0 ? (
            <ExpandLess
              onClick={() => {
                setSubSystemExpand(-1);
              }}
            />
          ) : (
            <ExpandMore
              onClick={() => {
                setSubSystemExpand(0);
              }}
            />
          )}
        </div>

        {subSystemExpand === 0 && (
          <Typography className={classes.requestDetailStepContentText}>
            For each ad campaign that you create, you can control how much
            you're willing to spend on clicks and conversions, which networks
            and geographical locations you want your ads to show on, and more
          </Typography>
        )}
      </div>
    </StepContent>
  </Step>

  <Step>
    <StepLabel StepIconComponent={() => <ColorlibStepIcon error={true} />}>
      <div className={classes.requestDetailStepLabelContainer}>
        <Typography className={classes.requestDetailStepLabelText}>
          WorkNotes
        </Typography>

        {activeStep === 2 ? (
          <ExpandLess
            onClick={() => {
              setActiveStep(-1);
              setSubSystemExpand(-1);
            }}
          />
        ) : (
          <ExpandMore
            onClick={() => {
              setActiveStep(2);
              setSubSystemExpand(-1);
            }}
          />
        )}
      </div>
    </StepLabel>

    <StepContent>
      <div
        className={`${classes.requestDetailStepContentContainer} ${classes.requestDetailStepContentErrorContainer}`}
      >
        <Typography
          className={`${classes.requestDetailStepContentText} ${classes.requestDetailStepContentStatus}`}
        >
          Status - ERROR
        </Typography>
        <Typography className={classes.requestDetailStepContentText}>
          Onboarding Duration -
        </Typography>
        // <Typography className={classes.requestDetailStepContentText}>
        //   End Time -
        // </Typography>
        
        <div className={classes.requestDetailStepContentSubContainer}>
          <Typography className={classes.requestDetailStepContentText}>
            WorkNotes Buyer - ERROR
          </Typography>
          <Typography className={classes.requestDetailStepContentText}>
            Unable to make a connection
          </Typography>
          <Typography className={classes.requestDetailStepContentText}>
            P001703 - 7/23/2021 - Connectivity issue happened, retrigger
            disabled Ariba
          </Typography>
          <Typography className={classes.requestDetailStepContentText}>
            Onboarding Duration -
          </Typography>
          // <Typography className={classes.requestDetailStepContentText}>
          //   End Time - Jul 23, 2021 5:30:00 AM
          // </Typography>
          
        </div>
      </div>
    </StepContent>
  </Step>
</Stepper>; */
