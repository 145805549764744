export const appHeaderHeight = "48px";
export const sidebarWidth = "82px";
export const buttonHeight = "31px";
export const crossIconContainerHeight = "26px";

export const applicationPageHeaderHeight = "50px";
export const applicationFooterButton = "53px";
export const cwAppDetailPageHeaderHeight = "28px";
export const cwAppDetailPageTabHeight = "39px";
export const ncwAppDetailPageHeaderHeight = "47px";
export const rolePageHeaderHeight = "50px";
export const roleDetailPageCss = {
  footerHeight: "53px",
  tabsContainerHeight: "42px",
};
export const userPageHeaderHeight = "50px";
export const userDetailPageCss = {
  footerHeight: "53px",
  tabsContainerHeight: "42px",
};
export const groupPageHeaderHeight = "50px";
export const groupDetailPageCss = {
  footerHeight: "53px",
  tabsContainerHeight: "42px",
};
export const licensePageHeaderHeight = "40px";

export const requestTabHeight = "42px";
export const requestChartContainerHeight = "130px";
export const requestListHeaderHeight = "38px";
export const requestDetailHeaderHeight = "42px";

export const onBoardingPageHeaderHeight = "25px";
export const onBoardingPageStepperHeight = "154px";
export const onBoardingPageFooterHeight = "48px";

export const profileHeaderMaxHeight = "230px";
export const profileHeaderSmMaxHeight = "126px";
export const profileHeaderLgMaxHeight = "70px";
export const profileTabHeight = "48px";
