import React from "react";
import {
  SIDEBAR_SUB_ITEM_DEBOARDING,
  SIDEBAR_SUB_ITEM_ONBOARDING,
} from "../../Data/data";
import Deboarding from "./Deboarding";
import Onboarding from "./Onboarding";

function Boarding({ selectedSubItem }) {
  return (
    <>
      {selectedSubItem === SIDEBAR_SUB_ITEM_ONBOARDING && <Onboarding />}
      {selectedSubItem === SIDEBAR_SUB_ITEM_DEBOARDING && <Deboarding />}
    </>
  );
}

export default Boarding;
