import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExitToApp } from "@material-ui/icons";
import { useSelector } from "react-redux";
import CustomAvatar from "./CustomAvatar";
import {Routes, Route, useNavigate,Link} from 'react-router-dom';

const useStyle = makeStyles((theme) => ({
  signOutContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 99999,
    width: "100%",
    height: "100%",
    maxWidth: 192,
    maxHeight: 161,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 1px 24px rgba(92, 92, 92, 0.25)",
    borderRadius: 4,
  },
  signOutContainerUserName: {
    fontSize: 10,
    marginTop: 6,
    color: theme.palette.text.primary,
  },
  signOutContainerUserEmail: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginTop: 6,
  },
  signOutContainerManageAccountButton: {
    fontSize: 10,
    textTransform: "capitalize",
    color: theme.palette.secondary.main,
    marginTop: 8,
    padding: 6,
  },
  signOutContainerSignOutButton: {
    fontSize: 10,
    textTransform: "capitalize",
    padding: 6,
  },
}));

function SignOut({ keycloak }) {
  const userReducerState = useSelector((state) => state.userReducer);
  const classes = useStyle();

  return (
    <div className={classes.signOutContainer}>
      <CustomAvatar name={userReducerState?.user?.name || ""} />

      <Typography className={classes.signOutContainerUserName}>
        {userReducerState?.user?.name || ""}
      </Typography>

      <Typography className={classes.signOutContainerUserEmail}>
        {userReducerState?.user?.email || ""}
      </Typography>

      <Button
        variant="text"
        className={classes.signOutContainerManageAccountButton}
        component={Link}
        to='/profile'
      >
        Profile
      </Button>

      <Button
        variant="text"
        className={classes.signOutContainerSignOutButton}
        startIcon={<ExitToApp style={{ fontSize: 14 }} />}
        onClick={() => {
          if (keycloak) {
            keycloak.logout();
          }
        }}
      >
        Sign out
      </Button>
    </div>
  );
}

export default SignOut;
