import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Input,
  FormControlLabel,
  Checkbox,
  Paper,
  Divider,
  Button,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MdDelete, MdDragIndicator } from "react-icons/md";
import { GoPerson, GoOrganization } from "react-icons/go";
import { ImAddressBook, ImAttachment } from "react-icons/im";
import { AiFillBank } from "react-icons/ai";
import {
  FaComments,
  FaUserGraduate,
  FaChalkboardTeacher,
  FaCalculator,
} from "react-icons/fa";
import { HiDocument } from "react-icons/hi";
import clsx from "clsx";
import {
  martialStatus,
  religions,
  nationalities,
  genders,
  employeeTypes,
  communicationTypes,
  attachments,
  features,
} from "../../Data/data";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { authVerify } from "../../Utility/auth";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  onBoardingPageFooterHeight,
  onBoardingPageHeaderHeight,
  onBoardingPageStepperHeight,
  sidebarWidth,
} from "../../Data/cssConstant";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <GoPerson />,
    2: <ImAddressBook />,
    3: <AiFillBank />,
    4: <FaComments />,
    5: <GoPerson />,
    6: <FaUserGraduate />,
    7: <FaChalkboardTeacher />,
    8: <HiDocument />,
    9: <ImAttachment />,
    10: <GoOrganization />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function Onboarding() {
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();

  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });

  const [load, setLoad] = useState(false);

  // const initialState=[];
  const allSteps = [
    "Personal Informations",
    "Addresses",
    "Bank Details",
    "Communication Details",
    "Previous Experience",
    "Education Qualifications",
    "Trannings",
    "Documents",
    "Attachments",
    "Organizational Informations",
  ];
  const limitedSteps = [
    "Personal Informations",
    "Addresses",
    "Bank Details",
    "Communication Details",
    "Previous Experience",
    "Education Qualifications",
    "Trannings",
  ];
  const [steps, setSteps] = useState(limitedSteps);
  // const [steps, setSteps] = useState(
  //   userReducerState?.entitiesAndActivities?.designation?.toLowerCase() === "hr"
  //     ? allSteps
  //     : limitedSteps
  // );
  const [activeStep, setActiveStep] = useState(0);

  const [wrResult, setWrResult] = useState("");
  const [currUser, setCurrUser] = useState("");

  const initialOnboardingState = {
    personalInfo: {
      // employeeId: currUser,
      employeeId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: moment(new Date()).format("yyyy-MM-dd").toString(),
      fatherName: "",
      martialStatus: "",
      religion: "",
      nationality: "",
      gender: "",
      bloodGroup: "",
    },
    address: {
      currentAddressLine1: "",
      permanentAddressLine1: "",
      currentAddressLine2: "",
      permanentAddressLine2: "",
      currentAddressCity: "",
      permanentAddressCity: "",
      currentAddressState: "",
      permanentAddressState: "",
      currentAddressPinCode: "",
      permanentAddressPinCode: "",
    },
    document: {
      aadharCardNumber: "",
      aadharCardName: "",
      passportNumber: "",
      passportExpiryDate: "",
      passportIssueDate: "",
      panNumber: "",
      uanNumber: "",
      pfNumber: "",
    },
    bankDetails: {
      name: "",
      accountNumber: "",
      ifscCode: "",
      accountType: "",
    },
    communicationDetails: [],
    attachments: [],
    experiences: [],
    educations: [],
    trainings: [],
    organizationalInfo: {
      employeeType: "",
      sourceOfHire: "",
      dateOfJoining: "",
      onboardingLocation: "",
      tentativeJoiningDate: "",
      primarySkill: "",
      secondarySkill: "",
      batchType: "",
      batch: "",
      peopleManager: "",
      projectManager: "",
      joiningBonus: "",
      candidateJoiningStatus: "",
      designation: "",
      location: "",
      country: "",
      state: "",
      pdcCompetency: "",
      eagle: "",
      grade: "",
      subGrade: "",
      competencyOwner: "",
      relocationBonus: "",
      region: "",
      buVertical: "",
      diversityHires: "",
      recruiterName: "",
      specialRecoverableBenifits: "",
      deliveryManager: "",
      allWorkSectionDetailsCompleted: "",
    },
  };
  const [onboardingUserDetail, setOnboardingUserDetail] = useState(
    initialOnboardingState
  );
  const [samePermanentAddress, setSamePermanentAddress] = useState(false);

  // const getEmployeeIdByEmailId = () => {
  //   const user = basicReducerState?.users?.find(
  //     (user) => user?.emailId === userReducerState?.user?.email
  //   );
  //   return user?.employeeId && user?.employeeId != "null"
  //     ? user?.employeeId
  //     : "";
  // };

  // useEffect(() => {
  // if(authVerify(userReducerState?.token)){
  //   setLoad(true);
  //   const invokePayloadUrl = `${process.env.REACT_APP_WREngineServices}/rest/v1/invokePayload?decisionTableId=DT_000588`;
  //   const invokePayloadRequestParam = {
  //     method: "GET",
  //     headers: {
  //       // Authorization: `Bearer ${userReducerState?.token}`,
  //       "Content-Type": "application/json",
  //       applicationId: process.env.REACT_APP_IWAId,
  //     },
  //   };
  //   fetch(invokePayloadUrl, invokePayloadRequestParam)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       const invokeRuleUrl = `${process.env.REACT_APP_WREngineServices}/rest/v1/invoke-rules`;
  //       const invokeRuleRequestParam = {
  //         method: "POST",
  //         headers: {
  //           // Authorization: `Bearer ${userReducerState?.token}`,
  //           "Content-Type": "application/json",
  //           applicationId: process.env.REACT_APP_IWAId,
  //         },
  //         body: JSON.stringify({
  //           ...data,
  //           conditions: [
  //             {
  //               "Data_Access_Conditions.WA_ROLE":
  //                 userReducerState?.entitiesAndActivities?.roles?.[0],
  //             },
  //           ],
  //         }),
  //       };
  //       fetch(invokeRuleUrl, invokeRuleRequestParam)
  //         .then((res) => res.json())
  //         .then((data) => {
  //           console.log(
  //             data?.data?.result?.[0]?.["Data_Access_Actions"]?.[0]?.[
  //               "WA_APPLICATION"
  //             ]
  //           );
  //           setWrResult(
  //             data?.data?.result?.[0]?.["Data_Access_Actions"]?.[0]?.[
  //               "WA_APPLICATION"
  //             ] || ""
  //           );
  //           setLoad(false);
  //         })
  //         .catch((err) => {
  //           setLoad(false);
  //         });
  //     })
  //     .catch((err) => {
  //       setLoad(false);
  //     });
  //   const empId = getEmployeeIdByEmailId();
  //   setCurrUser(empId);
  // }else {
  // dispatch(setIsSessionExpired(true));
  // }
  // }, []);

  // useEffect(() => {
  //   if (currUser !== "" && currUser) {
  //   }
  // }, [currUser]);

  useEffect(() => {
    setSteps(
      userFeatures?.includes(features?.OTHERS_ONBOARDING)
        ? allSteps
        : limitedSteps
    );
  }, []);

  return (
    <div
      style={{
        width: `calc(100vw - ${sidebarWidth})`,
        height: `calc(100vh - ${appHeaderHeight})`,
        position: "relative",
      }}
    >
      <Loading load={load} />

      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "4px 10px 0px 10px",
            height: onBoardingPageHeaderHeight,
          }}
        >
          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
            Onboarding
          </Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            {wrResult === "HR" && (
              <FormControl
                variant="standard"
                size="small"
                required
                style={{ width: 150 }}
              >
                <InputLabel>Users</InputLabel>
                <Select
                  size="small"
                  style={{ fontSize: 12 }}
                  value={currUser}
                  onChange={(e) => {
                    setCurrUser(e.target.value);
                  }}
                >
                  {basicReducerState?.users
                    ?.filter(
                      (user) => user?.employeeId && user?.employeeId !== "null"
                    )
                    ?.map((user, index) => (
                      <MenuItem
                        key={`${user?.id}-${index}`}
                        value={user?.employeeId}
                        style={{ fontSize: 12 }}
                      >
                        {`${user?.firstName} ${user?.lastName}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </div>
        </div>

        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          className={"iagScroll"}
          style={{ height: onBoardingPageStepperHeight }}
        >
          {steps?.map((step, index) => (
            <Step key={index} style={{ width: "200px" }}>
              <StepLabel
                // classes={{ root: { minWidth: 200 } }}
                StepIconComponent={ColorlibStepIcon}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
              height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
              overflowX: "hidden",
            }}
            className="iagScroll"
          >
            <Typography
              style={{
                backgroundColor: "#f1f5fe",
                fontSize: 14,
                fontWeight: 700,
                padding: 10,
              }}
            >
              Personal Informations
            </Typography>

            <Grid
              container
              spacing={2}
              style={{ padding: "0px 10px 10px 10px" }}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Employee Id"
                  value={onboardingUserDetail?.personalInfo?.employeeId}
                  // need to comment out this
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        employeeId: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  required
                  label="First Name"
                  value={onboardingUserDetail?.personalInfo?.firstName}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        firstName: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Middle Name"
                  value={onboardingUserDetail?.personalInfo?.middleName}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        middleName: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  required
                  label="Last Name"
                  value={onboardingUserDetail?.personalInfo?.lastName}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        lastName: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                required
                label="Date of Birth"
                type="date"
                defaultValue="2022-05-20"
                // placeholder=""
                // formate="dd-MM-yyyy"
                value={onboardingUserDetail?.personalInfo?.dateOfBirth}
                onChange={(e) => {
                  setOnboardingUserDetail({
                    ...onboardingUserDetail,
                    personalInfo: {
                      ...onboardingUserDetail?.personalInfo,
                      dateOfBirth: e.target.value,
                    },
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                variant="standard"
                fullWidth
                label="Father's Name"
                value={onboardingUserDetail?.personalInfo?.fatherName}
                onChange={(e) => {
                  setOnboardingUserDetail({
                    ...onboardingUserDetail,
                    personalInfo: {
                      ...onboardingUserDetail?.personalInfo,
                      fatherName: e.target.value,
                    },
                  });
                }}
              />
            </Grid> */}

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth required>
                <InputLabel id="martial-status">Martial Status</InputLabel>
                <Select
                  labelId="martial-status"
                  value={onboardingUserDetail?.personalInfo?.martialStatus}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        martialStatus: e.target.value,
                      },
                    });
                  }}
                >
                  {martialStatus?.map((m_status) => (
                    <MenuItem value={m_status}>{m_status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth required>
                <InputLabel id="religion">Religion</InputLabel>
                <Select
                  labelId="religion"
                  value={onboardingUserDetail?.personalInfo?.religion}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        religion: e.target.value,
                      },
                    });
                  }}
                >
                  {religions?.map((religion) => (
                    <MenuItem value={religion}>{religion}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth required>
                <InputLabel id="nationality">Nationality</InputLabel>
                <Select
                  labelId="nationality"
                  value={onboardingUserDetail?.personalInfo?.nationality}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        nationality: e.target.value,
                      },
                    });
                  }}
                >
                  {nationalities?.map((nationality) => (
                    <MenuItem value={nationality}>{nationality}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth required>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                  labelId="gender"
                  value={onboardingUserDetail?.personalInfo?.gender}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        gender: e.target.value,
                      },
                    });
                  }}
                >
                  {genders?.map((gender) => (
                    <MenuItem value={gender}>{gender}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                variant="standard"
                fullWidth
                label="Blood Group"
                value={onboardingUserDetail?.personalInfo?.bloodGroup}
                onChange={(e) => {
                  setOnboardingUserDetail({
                    ...onboardingUserDetail,
                    personalInfo: {
                      ...onboardingUserDetail?.personalInfo,
                      bloodGroup: e.target.value,
                    },
                  });
                }}
              />
            </Grid> */}

              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl
                fullWidth
                required
                style={{ alignItems: "flex-end" }}
              >
                <InputLabel htmlFor="profile-picture">
                  Profile Picture
                </InputLabel>
                <Input
                  style={{ width: "50%" }}
                  required
                  fullWidth
                  type="file"
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      personalInfo: {
                        ...onboardingUserDetail?.personalInfo,
                        profilePic: e.target?.files?.[0],
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid> */}
            </Grid>
          </Paper>
        )}

        {activeStep === 1 && (
          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
              height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
              overflowX: "hidden",
            }}
            className="iagScroll"
          >
            <Typography
              style={{
                backgroundColor: "#f1f5fe",
                fontSize: 14,
                fontWeight: 700,
                padding: 10,
              }}
            >
              Addresses
            </Typography>

            <div
              style={{
                borderRadius: 8,
                border: "1px dotted",
                margin: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 10px",
                }}
              >
                <MdDragIndicator />

                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    padding: "4px",
                  }}
                >
                  Current Address
                </Typography>
              </div>

              <Grid
                container
                spacing={2}
                style={{ padding: "0px 10px 10px 10px" }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Address 1"
                    value={onboardingUserDetail?.address?.currentAddressLine1}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        address: {
                          ...onboardingUserDetail?.address,
                          currentAddressLine1: e.target.value,
                          permanentAddressLine1: samePermanentAddress
                            ? e.target.value
                            : onboardingUserDetail?.address
                                ?.permanentAddressLine1,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Address 2"
                    value={onboardingUserDetail?.address?.currentAddressLine2}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        address: {
                          ...onboardingUserDetail?.address,
                          currentAddressLine2: e.target.value,
                          permanentAddressLine2: samePermanentAddress
                            ? e.target.value
                            : onboardingUserDetail?.address
                                ?.permanentAddressLine2,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="City"
                    value={onboardingUserDetail?.address?.currentAddressCity}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        address: {
                          ...onboardingUserDetail?.address,
                          currentAddressCity: e.target.value,
                          permanentAddressCity: samePermanentAddress
                            ? e.target.value
                            : onboardingUserDetail?.address
                                ?.permanentAddressCity,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="State"
                    value={onboardingUserDetail?.address?.currentAddressState}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        address: {
                          ...onboardingUserDetail?.address,
                          currentAddressState: e.target.value,
                          permanentAddressState: samePermanentAddress
                            ? e.target.value
                            : onboardingUserDetail?.address
                                ?.permanentAddressState,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Pin code"
                    value={onboardingUserDetail?.address?.currentAddressPinCode}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        address: {
                          ...onboardingUserDetail?.address,
                          currentAddressPinCode: e.target.value,
                          permanentAddressPinCode: samePermanentAddress
                            ? e.target.value
                            : onboardingUserDetail?.address
                                ?.permanentAddressPinCode,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            {/* <Divider style={{ margin: "4px 10px" }} /> */}

            <div
              style={{
                borderRadius: 8,
                border: "1px dotted",
                margin: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 10px",
                  }}
                >
                  <MdDragIndicator />

                  <Typography
                    style={{
                      fontSize: 14,
                      // marginRight: 120,
                      fontWeight: "bold",
                      padding: "4px",
                    }}
                  >
                    Permanent Address
                  </Typography>
                </div>

                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={samePermanentAddress}
                      onChange={(e) => {
                        setSamePermanentAddress(e.target.checked);
                        if (e.target.checked) {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            address: {
                              ...onboardingUserDetail?.address,
                              permanentAddressLine1:
                                onboardingUserDetail?.address
                                  ?.currentAddressLine1,
                              permanentAddressLine2:
                                onboardingUserDetail?.address
                                  ?.currentAddressLine2,
                              permanentAddressCity:
                                onboardingUserDetail?.address
                                  ?.currentAddressCity,
                              permanentAddressState:
                                onboardingUserDetail?.address
                                  ?.currentAddressState,
                              permanentAddressPinCode:
                                onboardingUserDetail?.address
                                  ?.currentAddressPinCode,
                            },
                          });
                        } else {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            address: {
                              ...onboardingUserDetail?.address,
                              permanentAddressLine1: "",
                              permanentAddressLine2: "",
                              permanentAddressCity: "",
                              permanentAddressState: "",
                              permanentAddressPinCode: "",
                            },
                          });
                        }
                      }}
                    />
                  }
                  label="Same as current address"
                />
              </div>

              <Grid
                container
                spacing={2}
                style={{ padding: "0px 10px 10px 10px" }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Address 1"
                    value={onboardingUserDetail?.address?.permanentAddressLine1}
                    onChange={(e) => {
                      if (!samePermanentAddress) {
                        setOnboardingUserDetail({
                          ...onboardingUserDetail,
                          address: {
                            ...onboardingUserDetail?.address,
                            permanentAddressLine1: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Address 2"
                    value={onboardingUserDetail?.address?.permanentAddressLine2}
                    onChange={(e) => {
                      if (!samePermanentAddress) {
                        setOnboardingUserDetail({
                          ...onboardingUserDetail,
                          address: {
                            ...onboardingUserDetail?.address,
                            permanentAddressLine2: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="City"
                    value={onboardingUserDetail?.address?.permanentAddressCity}
                    onChange={(e) => {
                      if (!samePermanentAddress) {
                        setOnboardingUserDetail({
                          ...onboardingUserDetail,
                          address: {
                            ...onboardingUserDetail?.address,
                            permanentAddressCity: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="State"
                    value={onboardingUserDetail?.address?.permanentAddressState}
                    onChange={(e) => {
                      if (!samePermanentAddress) {
                        setOnboardingUserDetail({
                          ...onboardingUserDetail,
                          address: {
                            ...onboardingUserDetail?.address,
                            permanentAddressState: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Pin code"
                    value={
                      onboardingUserDetail?.address?.permanentAddressPinCode
                    }
                    onChange={(e) => {
                      if (!samePermanentAddress) {
                        setOnboardingUserDetail({
                          ...onboardingUserDetail,
                          address: {
                            ...onboardingUserDetail?.address,
                            permanentAddressPinCode: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        )}

        {activeStep === 2 && (
          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
              height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
              overflowX: "hidden",
            }}
            className="iagScroll"
          >
            <Typography
              style={{
                backgroundColor: "#f1f5fe",
                fontSize: 14,
                fontWeight: 700,
                padding: 10,
              }}
            >
              Bank details
            </Typography>

            <Grid
              container
              spacing={2}
              style={{ padding: "0px 10px 10px 10px" }}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Bank name"
                  value={onboardingUserDetail?.bankDetails?.name}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      bankDetails: {
                        ...onboardingUserDetail?.bankDetails,
                        name: e.currentTarget.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Account number"
                  value={onboardingUserDetail?.bankDetails?.accountNumber}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      bankDetails: {
                        ...onboardingUserDetail?.bankDetails,
                        accountNumber: e.currentTarget.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="IFSC Code"
                  value={onboardingUserDetail?.bankDetails?.ifscCode}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      bankDetails: {
                        ...onboardingUserDetail?.bankDetails,
                        ifscCode: e.currentTarget.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Account Type"
                  value={onboardingUserDetail?.bankDetails?.accountType}
                  onChange={(e) => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      bankDetails: {
                        ...onboardingUserDetail?.bankDetails,
                        accountType: e.currentTarget.value,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        )}

        {activeStep === 3 && (
          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
              height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
              overflowX: "hidden",
            }}
            className="iagScroll"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#f1f5fe",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  padding: 10,
                }}
              >
                Communication Details
              </Typography>

              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  setOnboardingUserDetail({
                    ...onboardingUserDetail,
                    communicationDetails: [
                      ...onboardingUserDetail?.communicationDetails,
                      { name: "", value: "", status: "Draft" },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </div>

            <div style={{ padding: "0px 10px 10px 10px" }}>
              {onboardingUserDetail?.communicationDetails?.map(
                (communicationDetail, index) => (
                  <Grid container spacing={2} style={{ alignItems: "end" }}>
                    <Grid item xs={5} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel id="communication-type">
                          Communication Type
                        </InputLabel>
                        <Select
                          labelId="communication-type"
                          value={communicationDetail?.name}
                          onChange={(e) => {
                            setOnboardingUserDetail({
                              ...onboardingUserDetail,
                              communicationDetails:
                                onboardingUserDetail?.communicationDetails?.map(
                                  (det, ind) =>
                                    index === ind
                                      ? { ...det, name: e.target.value }
                                      : { ...det }
                                ),
                            });
                          }}
                        >
                          {communicationTypes?.map((communicationType) => (
                            <MenuItem value={communicationType}>
                              {communicationType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <TextField
                        variant="standard"
                        fullWidth
                        value={communicationDetail?.value}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            communicationDetails:
                              onboardingUserDetail?.communicationDetails?.map(
                                (det, ind) =>
                                  index === ind
                                    ? { ...det, value: e.target.value }
                                    : { ...det }
                              ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={1} align="center">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            communicationDetails:
                              onboardingUserDetail?.communicationDetails?.filter(
                                (det, ind) => index !== ind
                              ),
                          });
                        }}
                      >
                        <MdDelete />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              )}
            </div>
          </Paper>
        )}

        {activeStep === 4 && (
          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
              height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
              overflowX: "hidden",
            }}
            className="iagScroll"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#f1f5fe",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  padding: 10,
                }}
              >
                Previous Experience
              </Typography>

              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  setOnboardingUserDetail({
                    ...onboardingUserDetail,
                    experiences: [
                      ...onboardingUserDetail?.experiences,
                      {
                        companyName: "",
                        jobTitle: "",
                        ctc: "",
                        dateOfJoining: "",
                        jobDescription: "",
                        lastDate: "",
                      },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </div>

            <div style={{ padding: "0px 10px 10px 10px" }}>
              {onboardingUserDetail?.experiences?.map((experience, index) => (
                <>
                  <Grid
                    container
                    spacing={2}
                    style={{ padding: "0px 10px 10px 10px" }}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Company Name"
                        value={experience?.companyName}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            experiences: onboardingUserDetail?.experiences?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, companyName: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Job title"
                        value={experience?.jobTitle}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            experiences: onboardingUserDetail?.experiences?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, jobTitle: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Last CTC"
                        value={experience?.ctc}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            experiences: onboardingUserDetail?.experiences?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, ctc: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Date of joining"
                        type="date"
                        defaultValue="2022-05-20"
                        value={experience?.dateOfJoining}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            experiences: onboardingUserDetail?.experiences?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, dateOfJoining: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Job description"
                        value={experience?.jobDescription}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            experiences: onboardingUserDetail?.experiences?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, jobDescription: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        type="date"
                        defaultValue="2022-05-20"
                        label="Last Date"
                        value={experience?.lastDate}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            experiences: onboardingUserDetail?.experiences?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, lastDate: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={8}
                      lg={1}
                      align="end"
                      style={{ marginTop: "auto" }}
                    >
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations:
                              onboardingUserDetail?.educations?.filter(
                                (det, ind) => ind !== index
                              ),
                          });
                        }}
                      >
                        <MdDelete />
                      </IconButton>
                    </Grid>
                  </Grid>

                  {index !== onboardingUserDetail?.experiences?.length - 1 && (
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  )}
                </>
              ))}
            </div>
          </Paper>
        )}

        {activeStep === 5 && (
          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
              height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
              overflowX: "hidden",
            }}
            className="iagScroll"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#f1f5fe",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  padding: 10,
                }}
              >
                Education Qualifications
              </Typography>

              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  setOnboardingUserDetail({
                    ...onboardingUserDetail,
                    educations: [
                      ...onboardingUserDetail?.educations,
                      {
                        name: "",
                        university: "",
                        specialization: "",
                        degree: "",
                        grade: "",
                        passingYear: "",
                        status: "Draft",
                      },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </div>

            <div style={{ padding: "0px 10px 10px 10px" }}>
              {onboardingUserDetail?.educations?.map((education, index) => (
                <>
                  <Grid container spacing={2} style={{ alignItems: "end" }}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="School/College Name"
                        value={education?.name}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations: onboardingUserDetail?.educations?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, name: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="University/Board Name"
                        value={education?.university}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations: onboardingUserDetail?.educations?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, university: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Specialization"
                        value={education?.specialization}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations: onboardingUserDetail?.educations?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, specialization: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Degree or Diploma"
                        value={education?.degree}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations: onboardingUserDetail?.educations?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, degree: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Grade"
                        value={education?.grade}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations: onboardingUserDetail?.educations?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, grade: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        type="date"
                        defaultValue="2022-05-20"
                        fullWidth
                        label="Year of passing"
                        value={education?.passingYear}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations: onboardingUserDetail?.educations?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, passingYear: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} align="end">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            educations:
                              onboardingUserDetail?.educations?.filter(
                                (det, ind) => ind !== index
                              ),
                          });
                        }}
                      >
                        <MdDelete />
                      </IconButton>
                    </Grid>
                  </Grid>

                  {index !== onboardingUserDetail?.educations?.length - 1 && (
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  )}
                </>
              ))}
            </div>
          </Paper>
        )}

        {activeStep === 6 && (
          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
              height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
              overflowX: "hidden",
            }}
            className="iagScroll"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#f1f5fe",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  padding: 10,
                }}
              >
                Trainings
              </Typography>

              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  setOnboardingUserDetail({
                    ...onboardingUserDetail,
                    trainings: [
                      ...onboardingUserDetail?.trainings,
                      {
                        name: "",
                        type: "",
                        providerName: "",
                        startDate: "",
                        endDate: "",
                        status: "Draft",
                      },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </div>

            <div style={{ padding: "0px 10px 10px 10px" }}>
              {onboardingUserDetail?.trainings?.map((training, index) => (
                <>
                  <Grid container spacing={2} style={{ alignItems: "end" }}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Training Name"
                        value={training?.name}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            trainings: onboardingUserDetail?.trainings?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, name: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Training Type"
                        value={training?.type}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            trainings: onboardingUserDetail?.trainings?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, type: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Provider Name"
                        value={training?.providerName}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            trainings: onboardingUserDetail?.trainings?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, providerName: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Start Date"
                        type="date"
                        defaultValue="2022-05-20"
                        value={training?.startDate}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            trainings: onboardingUserDetail?.trainings?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, startDate: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="End Date"
                        type="date"
                        defaultValue="2022-05-20"
                        value={training?.endDate}
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            trainings: onboardingUserDetail?.trainings?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, endDate: e.target.value }
                                  : { ...det }
                            ),
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={9} align="end">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            trainings: onboardingUserDetail?.trainings?.filter(
                              (det, ind) => ind !== index
                            ),
                          });
                        }}
                      >
                        <MdDelete />
                      </IconButton>
                    </Grid>
                  </Grid>

                  {index !== onboardingUserDetail?.trainings?.length - 1 && (
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  )}
                </>
              ))}
            </div>
          </Paper>
        )}

        {userFeatures?.includes(features?.OTHERS_ONBOARDING) &&
          activeStep === 7 && (
            <Paper
              elevation={0}
              style={{
                boxShadow:
                  "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
                borderRadius: 4,
                margin: 16,
                height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
                overflowX: "hidden",
              }}
              className="iagScroll"
            >
              <Typography
                style={{
                  backgroundColor: "#f1f5fe",
                  fontSize: 14,
                  fontWeight: 700,
                  padding: 10,
                }}
              >
                Documents
              </Typography>

              <Grid
                container
                spacing={2}
                style={{ padding: "0px 10px 10px 10px" }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Aadhar number"
                    value={onboardingUserDetail?.document?.aadharCardNumber}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          aadharCardNumber: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Name on Aadhar"
                    value={onboardingUserDetail?.document?.aadharCardName}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          aadharCardName: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="PAN number"
                    value={onboardingUserDetail?.document?.panNumber}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          panNumber: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Passport number"
                    value={onboardingUserDetail?.document?.passportNumber}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          passportNumber: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    type="date"
                    defaultValue="2022-05-20"
                    label="Passport expiry date"
                    value={onboardingUserDetail?.document?.passportExpiryDate}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          passportExpiryDate: e.currentTarget.value,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    type="date"
                    defaultValue="2022-05-20"
                    label="Passport issue date"
                    value={onboardingUserDetail?.document?.pasportIssueDate}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          pasportIssueDate: e.currentTarget.value,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="UAN number"
                    value={onboardingUserDetail?.document?.uanNumber}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          uanNumber: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="PF number"
                    value={onboardingUserDetail?.document?.pfNumber}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        document: {
                          ...onboardingUserDetail?.document,
                          pfNumber: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}

        {userFeatures?.includes(features?.OTHERS_ONBOARDING) &&
          activeStep === 8 && (
            <Paper
              elevation={0}
              style={{
                boxShadow:
                  "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
                borderRadius: 4,
                margin: 16,
                height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
                overflowX: "hidden",
              }}
              className="iagScroll"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#f1f5fe",
                }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    padding: 10,
                  }}
                >
                  Attachments
                </Typography>

                <Button
                  variant="text"
                  color="primary"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setOnboardingUserDetail({
                      ...onboardingUserDetail,
                      attachments: [
                        ...onboardingUserDetail?.attachments,
                        { name: "", value: null, status: "Draft" },
                      ],
                    });
                  }}
                >
                  Add
                </Button>
              </div>

              <div style={{ padding: "0px 10px 10px 10px" }}>
                {onboardingUserDetail?.attachments?.map((attachment, index) => (
                  <Grid container spacing={2} style={{ alignItems: "end" }}>
                    <Grid item xs={5} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel id="attachment-type">
                          Attachment Type
                        </InputLabel>
                        <Select
                          labelId="attachment-type"
                          value={attachment?.name}
                          onChange={(e) => {
                            setOnboardingUserDetail({
                              ...onboardingUserDetail,
                              attachments:
                                onboardingUserDetail?.attachments?.map(
                                  (det, ind) =>
                                    ind === index
                                      ? { ...det, name: e.target.value }
                                      : { ...det }
                                ),
                            });
                          }}
                        >
                          {attachments?.map((attachment) => (
                            <MenuItem value={attachment}>{attachment}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <Input
                        required
                        fullWidth
                        type="file"
                        onChange={(e) => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            attachments: onboardingUserDetail?.attachments?.map(
                              (det, ind) =>
                                ind === index
                                  ? { ...det, value: e.target?.files?.[0] }
                                  : { ...det }
                            ),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={1} align="center">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setOnboardingUserDetail({
                            ...onboardingUserDetail,
                            attachments:
                              onboardingUserDetail?.attachments?.filter(
                                (det, ind) => {
                                  console.log(ind);
                                  return index !== ind;
                                }
                              ),
                          });
                        }}
                      >
                        <MdDelete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Paper>
          )}

        {userFeatures?.includes(features?.OTHERS_ONBOARDING) &&
          activeStep === 9 && (
            <Paper
              elevation={0}
              style={{
                boxShadow:
                  "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
                borderRadius: 4,
                margin: 16,
                height: `calc(100vh - ${appHeaderHeight} - ${onBoardingPageHeaderHeight} - ${onBoardingPageStepperHeight} - ${onBoardingPageFooterHeight} - 10px)`,
                overflowX: "hidden",
                overflowX: "hidden",
              }}
              className="iagScroll"
            >
              <Typography
                style={{
                  backgroundColor: "#f1f5fe",
                  fontSize: 14,
                  fontWeight: 700,
                  padding: 10,
                }}
              >
                Organizational Informations
              </Typography>

              <Grid
                container
                spacing={2}
                style={{ padding: "0px 10px 10px 10px" }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth required>
                    <InputLabel id="employee-type">Employee Type</InputLabel>
                    <Select
                      labelId="employee-type"
                      value={
                        onboardingUserDetail?.organizationalInfo?.employeeType
                      }
                      onChange={(e) => {
                        setOnboardingUserDetail({
                          ...onboardingUserDetail,
                          organizationalInfo: {
                            ...onboardingUserDetail?.organizationalInfo,
                            employeeType: e.target.value,
                          },
                        });
                      }}
                    >
                      {employeeTypes?.map((employeeType) => (
                        <MenuItem value={employeeType}>{employeeType}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Source of hire"
                    value={
                      onboardingUserDetail?.organizationalInfo?.sourceOfHire
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          sourceOfHire: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Date of Joining"
                    type="date"
                    defaultValue="2022-05-20"
                    value={
                      onboardingUserDetail?.organizationalInfo?.dateOfJoining
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          dateOfJoining: e.target.value,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Onboarding Location"
                    value={
                      onboardingUserDetail?.organizationalInfo
                        ?.onboardingLocation
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          onboardingLocation: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Tentative Joining Date"
                    type="date"
                    defaultValue="2022-05-20"
                    value={
                      onboardingUserDetail?.organizationalInfo
                        ?.tentativeJoiningDate
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          tentativeJoiningDate: e.target.value,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Primary Skill"
                    value={
                      onboardingUserDetail?.organizationalInfo?.primarySkill
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          primarySkill: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Secondary Skill"
                    value={
                      onboardingUserDetail?.organizationalInfo?.secondarySkill
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          secondarySkill: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Batch Type"
                    value={onboardingUserDetail?.organizationalInfo?.batchType}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          batchType: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Batch"
                    value={onboardingUserDetail?.organizationalInfo?.batch}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          batch: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="People Manager"
                    value={
                      onboardingUserDetail?.organizationalInfo?.peopleManager
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          peopleManager: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Project Manager"
                    value={
                      onboardingUserDetail?.organizationalInfo?.projectManager
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          projectManager: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Joining Bonus"
                    value={
                      onboardingUserDetail?.organizationalInfo?.joiningBonus
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          joiningBonus: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Candidate Joining Status"
                    value={
                      onboardingUserDetail?.organizationalInfo
                        ?.candidateJoiningStatus
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          candidateJoiningStatus: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Designation"
                    value={
                      onboardingUserDetail?.organizationalInfo?.designation
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          designation: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Location"
                    value={onboardingUserDetail?.organizationalInfo?.location}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          location: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Country"
                    value={onboardingUserDetail?.organizationalInfo?.country}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          country: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="State"
                    value={onboardingUserDetail?.organizationalInfo?.state}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          state: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="PDC-Competency"
                    value={
                      onboardingUserDetail?.organizationalInfo?.pdcCompetency
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          pdcCompetency: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Eagle"
                    value={onboardingUserDetail?.organizationalInfo?.eagle}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          eagle: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Grade"
                    value={onboardingUserDetail?.organizationalInfo?.grade}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          grade: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Sub-grade"
                    value={onboardingUserDetail?.organizationalInfo?.subGrade}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          subGrade: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Competency Owner"
                    value={
                      onboardingUserDetail?.organizationalInfo?.competencyOwner
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          competencyOwner: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Relocation Bonus"
                    value={
                      onboardingUserDetail?.organizationalInfo?.relocationBonus
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          relocationBonus: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Region"
                    value={onboardingUserDetail?.organizationalInfo?.region}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          region: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="BU Vertical"
                    value={onboardingUserDetail?.organizationalInfo?.buVertical}
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          buVertical: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Diversity Hires"
                    value={
                      onboardingUserDetail?.organizationalInfo?.diversityHires
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          diversityHires: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Recruiter Name"
                    value={
                      onboardingUserDetail?.organizationalInfo?.recruiterName
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          recruiterName: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Special Recoverable Benifits"
                    value={
                      onboardingUserDetail?.organizationalInfo
                        ?.specialRecoverableBenifits
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          specialRecoverableBenifits: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Delivery Manager"
                    value={
                      onboardingUserDetail?.organizationalInfo?.deliveryManager
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          deliveryManager: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="All work section details completed"
                    value={
                      onboardingUserDetail?.organizationalInfo
                        ?.allWorkSectionDetailsCompleted
                    }
                    onChange={(e) => {
                      setOnboardingUserDetail({
                        ...onboardingUserDetail,
                        organizationalInfo: {
                          ...onboardingUserDetail?.organizationalInfo,
                          allWorkSectionDetailsCompleted: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "sticky",
            width: "100%",
            bottom: 0,
            right: 0,
            backgroundColor: "white",
            padding: 8,
            justifyContent: "flex-end",
            // boxShadow: "0px 0px 9px #D8D8D8",
            paddingRight: 16,
            height: onBoardingPageFooterHeight,
          }}
        >
          {activeStep !== 0 && (
            <Button
              color="primary"
              variant="text"
              style={{ textTransform: "capitalize", fontSize: 12 }}
              onClick={() => {
                setActiveStep(activeStep - 1);
              }}
            >
              Back
            </Button>
          )}

          {activeStep !== steps.length - 1 ? (
            <Button
              color="primary"
              variant="text"
              style={{ textTransform: "capitalize", fontSize: 12 }}
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              color="primary"
              variant="text"
              style={{ textTransform: "capitalize", fontSize: 12 }}
              onClick={() => {
                if (authVerify(userReducerState?.token)) {
                  // console.log(onboardingUserDetail);
                  setOnboardingUserDetail(initialOnboardingState);
                  setSamePermanentAddress(false);
                  fetch("/api", {
                    method: "POST",
                    body: JSON.stringify(onboardingUserDetail),
                  })
                    .then((res) => res.json())
                    .then((data) => {})
                    .catch((err) => {});
                } else {
                  dispatch(setIsSessionExpired(true));
                }
              }}
            >
              Confirm
            </Button>
          )}
        </div>
      </>
    </div>
  );
}

export default Onboarding;
