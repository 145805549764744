import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  Tabs,
  Tab,
  Divider,
  Select,
  MenuItem,
  // Popover,
  Popper,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  CheckCircle,
  Error,
  MoreHoriz,
  Close,
  Cancel,
} from "@material-ui/icons";
import { FiFilter } from "react-icons/fi";
import { HiDownload } from "react-icons/hi";
import {
  IoReload,
  //  IoPersonOutline
} from "react-icons/io5";
import {
  BsSearch,
  BsFillPersonFill,
  // BsInfoCircle
} from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import {
  AiOutlineClose,
  AiOutlineFileText,
  // AiOutlineMail,
} from "react-icons/ai";
import { CgSandClock } from "react-icons/cg";
import { GiCancel } from "react-icons/gi";
import { Autocomplete } from "@material-ui/lab";
import ChartRequest from "./ChartRequest";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { getAllRequests } from "../../Action/action";
import {
  setRequests,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import { a11yProps, TabPanel } from "../TabPanel";
import moment from "moment";
import {
  findApplicationById,
  getCurrentLoggedInUser,
} from "../../Utility/basic";
import { authVerify } from "../../Utility/auth";
import Notes from "@cw/cherrywork-worknotes-generator";
import {
  features,
  ristrictedApps,
  SIDEBAR_SUB_ITEM_DEPROVISIONING,
  SIDEBAR_SUB_ITEM_PROVISIONING,
  status,
} from "../../Data/data";
import { requestsFileHeading } from "../../Utility/file";
import { CSVLink } from "react-csv";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  requestTabHeight,
  requestChartContainerHeight,
  requestListHeaderHeight,
  appHeaderHeight,
} from "../../Data/cssConstant";
import { toastMessage } from "../../Utility/config";

const useStyle = makeStyles((theme) => ({
  newRequestDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    // margin: 0,
    padding: "0px 20px",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
  },
  newRequestDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  filterRequestDialogContentHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d9d9d9",
    padding: "0px 10px",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
  },
  filterRequestDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
  filterRequestTab: {
    fontSize: 14,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  requestEmpCardContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    borderRadiud: 4,
    width: 350,
  },
  requestEmpCard: {
    width: 4,
    height: "100%",
    position: "absolute",
    left: -16,
    top: 0,
    bottom: 0,
  },
  requestEmpCardRegular: {
    backgroundColor: theme.palette.primary.light,
  },
  requestEmpCardEmergency: {
    backgroundColor: theme.palette.warning.light,
  },
  requestEmpCardTopContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  requestEmpCardEmpName: {
    fontSize: 16,
    fontWeight: "bold",
    cursor: "pointer",
  },
  requestEmpCardStatusContainer: {
    display: "flex",
    alignItems: "center",
  },
  requestEmpCardStatus: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginRight: 6,
  },
  requestEmpCardStatusSuccess: {
    backgroundColor: theme.palette.success.light,
  },
  requestEmpCardStatusError: {
    backgroundColor: theme.palette.error.light,
  },
  requestEmpCardStatusText: {
    fontSize: 12,
  },
  requestEmpCardStatusTextSuccess: {
    color: theme.palette.success.light,
  },
  requestEmpCardStatusTextError: {
    color: theme.palette.error.light,
  },
  requestEmpCardEmpId: {
    fontSize: 14,
  },
  requestEmpCardBottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  requestEmpCardBottomLeftContainer: {
    display: "flex",
    flexDirection: "column",
  },
  requestEmpCardTerminationContainer: {
    display: "flex",
    alignItems: "center",
  },
  requestEmpCardTerminationDate: {
    fontSize: 14,
    marginLeft: 6,
  },
  requestEmpCardApprovedDate: {
    fontSize: 10,
    color: theme.palette.text.secondary,
  },
  requestEmpCardBottomRightContainer: {
    display: "flex",
    alignItems: "center",
  },
  requestEmpCardBottomRightNotesIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  requestEmpCardTerminationIcon: {
    fontSizze: 20,
    color: theme.palette.secondary.main,
  },

  discrepancyEmpCardContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    borderRadius: 4,
  },
  discrepancyEmpCardName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  discrepancyEmpCardId: {
    fontSize: 14,
  },
  discrepancyEmpCardUniqueId: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginTop: 4,
  },
  discrepancyEmpCardExtra: {
    fontSize: 12,
  },

  requestEmpSystemStatusContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  requestEmpSystemStatusWarning: {
    color: theme.palette.warning.main,
    fontSize: 24,
  },
  requestEmpSystemStatusError: {
    color: theme.palette.error.main,
  },
  requestEmpSystemStatusSuccess: {
    color: theme.palette.success.main,
  },
  requestEmpSystemStatusInfo: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 4,
    bottom: 4,
  },

  requestEmpNotesDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
    backgroundColor: "#FAFCFF",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
    "& h2": {
      fontSize: 18,
      width: "100%",
      marginRight: 8,
    },
  },
  requestEmpNotesTextArea: {
    border: "1px solid #D9D9D9",
    boxShadow: "7px 0px 24px rgba(225, 225, 225, 0.25)",
    borderRadius: 8,
  },
  requestEmpNotesDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
    boxShadow: "0px -4px 9px #D8D8D8",
    paddingRight: 20,
  },

  requestsContainer: {
    width: "94%",
    overflow: "hidden",
  },
  requestsTopContainer: {
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
    padding: "16px 8px",
  },
  requestsTabContainer: {
    display: "flex",
    overflow: "overlay",
    position: "sticky",
    marginBottom: 8,
  },
  requestsTab: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    fontSize: 14,
    marginRight: 40,
    paddingBottom: 4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  requestsTabSelected: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  requestsBottomContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px",
  },
  requestsBottomHeadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: requestListHeaderHeight,
  },
  requestsBottomHeadingRightContainerDropdown: {
    width: 180,
  },
  requestsBottomHeading: {
    fontSize: 16,
    fontWeight: "bold",
  },
  requestsBottomHeadingRightContainer: {
    display: "flex",
    alignItems: "center",
  },
  requestTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${requestTabHeight} - ${requestChartContainerHeight} - ${requestListHeaderHeight} - 35px)`,
  },
  requestTableHead: {
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: "#F1F5FE",
  },
  requestTableHeadSearchCell: {
    position: "sticky",
    left: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
    whiteSpace: "nowrap",
    minWidth: 100,
  },
  requestsTableHeaderInputContainer: {
    margin: 8,
    display: "flex",
    alignItems: "center",
    border: "1px solid #bdbdbd",
    borderRadius: 4,
    padding: 4,
    backgroundColor: "white",
    width: 350,
  },
  requestsTableHeaderInput: {
    border: "0px solid",
    outlined: "none",
    flex: 1,
  },
  requestTableHeadCell: {
    fontSize: 12,
    lineHeight: 1.5,
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
    whiteSpace: "nowrap",
    minWidth: 115,
  },
  requestTableBodyCardCell: {
    position: "sticky",
    left: 0,
    zIndex: 99,
    backgroundColor: "white",
    whiteSpace: "nowrap",
    borderTop: "1px solid #E5E5E5",
    borderBottom: "1px solid #E5E5E5",
    minWidth: 400,
  },
  requestTableBodyCell: {
    position: "relative",
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
    whiteSpace: "nowrap",
    minWidth: 100,
  },
  requestTableBodyCellProgress: {
    backgroundColor: "#fff5e2",
  },
  requestTableBodyCellSuccess: {
    backgroundColor: "#E8FEEA",
  },
  requestTableBodyCellError: {
    backgroundColor: "#FFEDED",
  },
}));

const ConfirmationForHRApp = ({ open, onClose, onContinue, hrApp, users }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const [msg, setMsg] = useState("");
  // const [load, setLoad] = useState(false);
  // console.log(users);

  useEffect(() => {
    setMsg("");
    if (hrApp?.includes("zoho-crm")) {
      setMsg(
        // msg +
        `For Zoho CRM, you will only be able to access ${userReducerState?.entitiesAndActivities?.country} region's data.`
      );
    }
    if (hrApp?.includes("ariba")) {
      if (
        userReducerState?.entitiesAndActivities?.roles?.includes("iwaAdmin")
      ) {
        setMsg(
          // msg +
          `${users?.join(", ")} ${users.length > 1 ? "are" : "is"
          } not authorized to access Ariba. Do you want to override ?`
        );
      }
    }
  }, [hrApp]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRequestDialogTitle}>
        Confirmation
      </DialogTitle>

      <DialogContent>
        <Typography style={{ fontSize: 12, textAlign: "center", padding: 12 }}>
          {msg}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.newRequestDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          style={{ borderRadius: 50 }}
          onClick={() => {
            onClose();
            setMsg("");
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            onClose();
            onContinue();
            setMsg("");
          }}
          style={{ borderRadius: 50 }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NewProvisioningRequest = ({
  open,
  onClose,
  selectedSubItem,
  getRequests,
  getSystems,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);

  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [loggedInUser, setLoggedInUser] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const initialRequestState = {
    for: "self",
    users: [loggedInUser],
    // allSystems: false,
    applications: [],
    roles: [],
    groups: [],
    type: "REGULAR",
  };
  const [newRequest, setNewProvisioningRequest] = useState(initialRequestState);
  const [load, setLoad] = useState(false);
  const [access, setAccess] = useState({ canRaise: true, msg: "" });
  const [openHRConfirmationBox, setOpenHRConfirmationBox] = useState(false);
  const [systems, setSystems] = useState([]);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      newRequest?.for === "self" ? systems : basicReducerState?.applications
    );
    // console.log(application);
    return application?.name || "-";
  };
  const selectApplication = (applicationId, applications) => {
    return findApplicationById(Number(applicationId), applications);
  };
  const selectiveSystemReq = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const insertNewProvisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      // const insertNewProvisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      var insertNewProvisioningRequestPayload = [];
      newRequest?.users?.map((user) => {
        newRequest?.applications?.map((application) => {
          insertNewProvisioningRequestPayload.push({
            requestType: `PROVISIONING`,
            // pid: user?.userId,
            // userName: user?.userName,
            createdFor: user?.emailId,
            createdBy: userReducerState?.user?.email,
            createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            updatedBy: userReducerState?.user?.email,
            updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            status: "IN_PROGRESS",
            sla: 0,
            isActive: 1,
            isDeleted: 0,
            systemId: application?.id,
            roleId:
              newRequest?.roles
                ?.filter((role) => role?.applicationId === application?.id)
                ?.map((role) => Number(role?.id)) || [],
            isEmergency: newRequest?.type === "REGULAR" ? 0 : 1,
            // systemName: application?.name,
            // systemOwnerEmail: application?.applicationOwnerEmails,
            // processingDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            // roleName:
            //   newRequest?.roles
            //     ?.filter((role) => role?.applicationId === application?.id)
            //     ?.map((role) => role?.name) || [],
          });
          return null;
        });
        return null;
      });
      // console.log(newRequest?.users);
      const insertNewProvisioningRequestRequestParams = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify({
          details: insertNewProvisioningRequestPayload,
          createdFor: newRequest?.users?.map((user) => user?.emailId),
        }),
      };
      // console.log(insertNewProvisioningRequestPayload, JSON.stringify(insertNewProvisioningRequestRequestParams.body));
      fetch(
        insertNewProvisioningRequestUrl,
        insertNewProvisioningRequestRequestParams
      )
        .then((res) => res.json())
        .then((req_data) => {
          setOpenHRConfirmationBox(false);
          getRequests();
          getSystems();
          setLoad(false);
          setOpenSnackbar(false);
          onClose();
          setAccess({ canRaise: true, msg: "" });
          setOpenHRConfirmationBox(false);
          setNewProvisioningRequest(initialRequestState);
          dispatch(
            setResponseMessage({
              open: true,
              status: req_data?.status ? "success" : "error",
              message: req_data?.status ? "Request created successfully" : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
          setOpenSnackbar(false);
          dispatch(
            setResponseMessage({
              open: true,
              status: "error",
              message: "Something went wrong",
            })
          );
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
    // addTaskToITM(insertNewProvisioningRequestPayload);
  };
  const groupSystemReq = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const insertNewProvisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      // const insertNewProvisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      var insertNewProvisioningRequestPayload = [];
      newRequest?.groups?.map((group) => {
        newRequest?.applications?.map((application) => {
          insertNewProvisioningRequestPayload.push({
            requestType: `PROVISIONING`,
            createdFor: group?.name,
            createdBy: userReducerState?.user?.email,
            createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            updatedBy: userReducerState?.user?.email,
            updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            status: "IN_PROGRESS",
            sla: 0,
            isActive: 1,
            isDeleted: 0,
            isGroup: 1,
            systemId: application?.id,
            roleId:
              newRequest?.roles
                ?.filter((role) => role?.applicationId === application?.id)
                ?.map((role) => Number(role?.id)) || [],
            isEmergency: newRequest?.type === "REGULAR" ? 0 : 1,
            // systemName: application?.name,
            // systemOwnerEmail: application?.applicationOwnerEmails,
            // processingDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            // roleName:
            //   newRequest?.roles
            //     ?.filter((role) => role?.applicationId === application?.id)
            //     ?.map((role) => role?.name) || [],
          });
          return null;
        });
        return null;
      });
      // console.log(newRequest?.users);
      const insertNewProvisioningRequestRequestParams = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify({
          details: insertNewProvisioningRequestPayload,
          createdFor: newRequest?.groups?.map((group) => group?.name),
        }),
      };
      // console.log(insertNewProvisioningRequestPayload, JSON.stringify(insertNewProvisioningRequestRequestParams.body));
      fetch(
        insertNewProvisioningRequestUrl,
        insertNewProvisioningRequestRequestParams
      )
        .then((res) => res.json())
        .then((req_data) => {
          setOpenHRConfirmationBox(false);
          getRequests();
          getSystems();
          setLoad(false);
          setOpenSnackbar(false);
          onClose();
          setAccess({ canRaise: true, msg: "" });
          setOpenHRConfirmationBox(false);
          setNewProvisioningRequest(initialRequestState);
        })
        .catch((err) => {
          setLoad(false);
          setOpenSnackbar(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const addRequests = () => {
    if (newRequest?.for === "self" || newRequest?.for === "other") {
      selectiveSystemReq();
    } else if (newRequest?.for === "group") {
      groupSystemReq();
    }
  };
  const aribaValidation = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const invokePayloadUrl = `${process.env.REACT_APP_WREngineServices}/rest/v1/invokePayload?decisionTableId=DT_000588`;
      const invokePayloadRequestParam = {
        method: "GET",
        headers: {
          // Authorization: `Bearer ${userReducerState?.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(invokePayloadUrl, invokePayloadRequestParam)
        .then((res) => res.json())
        .then((data) => {
          const invokeRuleUrl = `${process.env.REACT_APP_WREngineServices}/rest/v1/invoke-rules`;
          console.log(userReducerState?.entitiesAndActivities);
          const invokeRulePayload = {
            ...data,
            conditions: userReducerState?.entitiesAndActivities?.roles?.map(
              (role) => ({
                [Object?.keys(data?.conditions?.[0])]: role,
              })
            ),
          };
          const invokeRuleRequestParam = {
            method: "POST",
            headers: {
              // Authorization: `Bearer ${userReducerState?.token}`,
              "Content-Type": "application/json",
              applicationId: process.env.REACT_APP_IWAId,
            },
            body: JSON.stringify(invokeRulePayload),
          };
          fetch(invokeRuleUrl, invokeRuleRequestParam)
            .then((res) => res.json())
            .then((data) => {
              let wrRes = [];
              data?.data?.result
                ?.filter((d) => d?.["Data_Access_Actions"])
                ?.map((d) => {
                  d?.["Data_Access_Actions"]?.map((action) => {
                    wrRes.push(action?.["WA_APPLICATION"]?.toLowerCase());
                    return null;
                  });
                  return null;
                });
              console.log(wrRes);
              if (wrRes?.includes("ariba")) {
                if (newRequest?.for === "self") {
                  addRequests();
                } else {
                  setOpenHRConfirmationBox(true);
                }
              } else {
                setAccess({
                  canRaise: false,
                  msg: `You are not authorized to raise request for Ariba. Please contact administrator.`,
                });
              }
              setLoad(false);
            })
            .catch((err) => {
              setLoad(false);
            });
        })
        .catch((err) => {
          setLoad(false);
        });
      // const empId = getEmployeeIdByEmailId();
      // setCurrUser(empId);
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const isDefaultRoleSelected = () => {
    const allDefaultRoleName = basicReducerState?.roles
      ?.filter(
        (role) =>
          selectApplication(role?.applicationId, newRequest?.applications) &&
          role?.isDefault === 1
      )
      ?.map((role) => role?.name);
    const selectedDefaultRole = newRequest?.roles
      ?.filter(
        (role) =>
          selectApplication(role?.applicationId, newRequest?.applications) &&
          role?.isDefault === 1
      )
      ?.map((role) => role?.name);
    return allDefaultRoleName
      ?.map((roleName) =>
        selectedDefaultRole?.includes(roleName) ? true : false
      )
      ?.reduce((acc, curr) => acc && curr, true);
  };
  const selectEachSystemRole = () => {
    let applicationIds = newRequest?.roles?.map((role) => role?.applicationId);
    applicationIds = new Set(applicationIds);
    applicationIds = Array.from(applicationIds);
    return applicationIds?.length === newRequest?.applications?.length;
  };
  const handleCloseConfirmationDialog = () => {
    setOpenSnackbar(false);
  };
  const getAllSystems = () => {
    if (authVerify(userReducerState?.token)) {
      const getAllSystemUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications`;
      const getAllSystemsRequestParam = {
        mehtod: "GET",
        headers: {
          Authorization: `Bearer ${userReducerState?.token}`,
          "Content-Type": "application/json",
        },
      };
      fetch(getAllSystemUrl, getAllSystemsRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setSystems(data?.data || []);
        })
        .catch((err) => {
          setSystems([]);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getAllRoles = () => {
    if (authVerify(userReducerState?.token)) {
      const getAllRoleUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles`;
      const getAllRolesRequestParam = {
        mehtod: "GET",
        headers: {
          Authorization: `Bearer ${userReducerState?.token}`,
          "Content-Type": "application/json",
        },
      };
      fetch(getAllRoleUrl, getAllRolesRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setRoles(data?.data || []);
        })
        .catch((err) => {
          setRoles([]);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  useEffect(() => {
    setLoggedInUser(
      basicReducerState?.users?.find(
        (user) => user?.emailId === userReducerState?.user?.email
      )
    );
  }, [basicReducerState?.users]);

  useEffect(() => {
    setNewProvisioningRequest({ ...newRequest, users: [loggedInUser] });
  }, [loggedInUser]);

  useEffect(() => {
    getAllSystems();
    getAllRoles();
  }, []);

  return (
    <>
      <Loading load={load} />

      <ConfirmationForHRApp
        open={openHRConfirmationBox}
        onClose={() => {
          setOpenHRConfirmationBox(false);
        }}
        onContinue={() => {
          addRequests();
        }}
        hrApp={newRequest?.applications
          ?.filter((application) =>
            ristrictedApps?.includes(application?.name?.toLowerCase())
          )
          ?.map((app) => app?.name?.toLowerCase())}
        users={newRequest?.users?.map((user) => user?.displayName)}
      />

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => {
          onClose();
          setAccess({ canRaise: true, msg: "" });
          setOpenHRConfirmationBox(false);
          setNewProvisioningRequest(initialRequestState);
        }}
      >
        <DialogTitle className={classes.newRequestDialogTitle}>
          {selectedSubItem} Request
        </DialogTitle>

        <DialogContent style={{ position: "relative" }}>
          <Loading load={load} />

          {!access?.canRaise && (
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: "red",
                textAlign: "center",
              }}
            >
              {access?.msg}
            </Typography>
          )}

          <Typography
            style={{ fontSize: 14, fontWeight: "bold", marginTop: 8 }}
          >
            Request For
          </Typography>

          <FormControl
            component="fieldset"
            style={{ marginTop: 0, width: "100%" }}
          >
            <RadioGroup
              aria-label="requestFor"
              name="requestFor"
              style={{ flexDirection: "row" }}
              value={newRequest?.for}
              onChange={(e) => {
                setNewProvisioningRequest({
                  ...newRequest,
                  for: e.target.value,
                  users:
                    e.target.value === "self"
                      ? [
                        getCurrentLoggedInUser(
                          userReducerState?.user,
                          basicReducerState?.users
                        ),
                      ]
                      : [],
                  applications: [],
                  roles: [],
                  groups: [],
                });
              }}
            >
              {userFeatures?.includes(features?.SELF_REQUESTS) && (
                <FormControlLabel
                  value="self"
                  control={<Radio color="primary" />}
                  label="Self"
                  style={{ flex: 1 }}
                />
              )}

              {userFeatures?.includes(features?.OTHERS_REQUESTS) && (
                <FormControlLabel
                  value="other"
                  control={<Radio color="primary" />}
                  label="Others"
                  style={{ flex: 1 }}
                />
              )}

              {userFeatures?.includes(features?.OTHERS_REQUESTS) && (
                <FormControlLabel
                  value="group"
                  control={<Radio color="primary" />}
                  label="Group"
                  style={{ flex: 1 }}
                />
              )}
            </RadioGroup>
          </FormControl>

          {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={newRequest.allSystems}
                    onChange={(e) => {
                      setNewProvisioningRequest({
                        ...newRequest,
                        allSystems: e.target.checked,
                      });
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="All Systems"
              /> */}

          {newRequest?.for === "other" && (
            <Autocomplete
              multiple
              size="small"
              style={{ fontSize: 12 }}
              disableCloseOnSelect
              filterSelectedOptions
              value={newRequest?.users}
              onChange={(e, users) => {
                setNewProvisioningRequest({
                  ...newRequest,
                  users: users,
                  applications: [],
                  roles: [],
                  groups: [],
                });
              }}
              options={basicReducerState?.users}
              getOptionLabel={(option) => option?.userName}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox color="primary" fontSize="small" />}
                    checked={selected}
                  />
                  <Typography style={{ fontSize: 12 }}>
                    {option.userName}
                  </Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Users"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          )}

          {newRequest?.for === "group" && (
            <Autocomplete
              multiple
              size="small"
              style={{ fontSize: 12 }}
              disableCloseOnSelect
              filterSelectedOptions
              value={newRequest?.groups}
              onChange={(e, groups) => {
                setNewProvisioningRequest({
                  ...newRequest,
                  groups: groups,
                  applications: [],
                  roles: [],
                  users: [],
                });
              }}
              options={basicReducerState?.groups}
              getOptionLabel={(option) => option?.name}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox color="primary" fontSize="small" />}
                    checked={selected}
                  />
                  <Typography style={{ fontSize: 12 }}>
                    {option.name}
                  </Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Groups"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          )}

          <Autocomplete
            multiple
            size="small"
            style={{ fontSize: 12 }}
            disableCloseOnSelect
            filterSelectedOptions
            value={newRequest?.applications}
            onChange={(e, applications) => {
              if (newRequest?.applications?.length > applications?.length) {
                setNewProvisioningRequest({
                  ...newRequest,
                  applications: applications,
                  roles: newRequest?.roles?.filter((role) =>
                    applications
                      ?.map((app) => Number(app?.id))
                      ?.includes(role?.applicationId)
                  ),
                });
              } else {
                setNewProvisioningRequest({
                  ...newRequest,
                  applications: applications,
                });
              }
              setAccess({ canRaise: true, msg: "" });
            }}
            options={
              // newRequest?.for === "self"
              //   ? \
              systems
              //     ?.filter(
              //       (system) =>
              //         !newRequest?.users?.[0]?.applicationName?.includes(
              //           system?.name
              //         )
              //     )
              //     ?.filter((system) => system) || []
              // : basicReducerState?.applications
            }
            getOptionLabel={(option) => `${option?.name}`}
            // getOptionDisabled={(option) =>
            //   userReducerState?.entitiesAndActivities?.country?.toLowerCase() !==
            //   "india"
            //     ? option?.applicationType?.toLowerCase() !== "cw"
            //     : null
            // }
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />
                <Typography
                  style={{ fontSize: 12 }}
                >{`${option?.name}`}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Systems"
                style={{ fontSize: 12 }}
              />
            )}
          />

          {newRequest?.applications?.length > 0 && (
            <Autocomplete
              multiple
              size="small"
              style={{ fontSize: 12 }}
              disableCloseOnSelect
              filterSelectedOptions
              value={newRequest?.roles}
              onChange={(e, roles) => {
                setNewProvisioningRequest({ ...newRequest, roles: roles });
              }}
              options={
                newRequest?.for === "self"
                  ? roles?.filter((role) =>
                    selectApplication(
                      role?.applicationId,
                      newRequest?.applications
                    )
                  )
                  : basicReducerState?.roles?.filter((role) =>
                    selectApplication(
                      role?.applicationId,
                      newRequest?.applications
                    )
                  )
              }
              groupBy={(option) => getApplicationNameById(option.applicationId)}
              getOptionLabel={(option) =>
                `${option?.name} (${getApplicationNameById(
                  option?.applicationId
                )})`
              }
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox color="primary" fontSize="small" />}
                    checked={selected}
                  />
                  <Typography style={{ fontSize: 12 }}>{`${option.name} ${option?.isDefault === 1 ? "(Default)" : ""
                    }`}</Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Roles"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          )}

          <Typography
            style={{ fontSize: 14, fontWeight: "bold", marginTop: 12 }}
          >
            Request Type
          </Typography>

          <FormControl
            component="fieldset"
            style={{ marginTop: 0, width: "100%" }}
          >
            <RadioGroup
              aria-label="requestType"
              name="requestType"
              style={{ flexDirection: "row" }}
              value={newRequest?.type}
              onChange={(e) => {
                setNewProvisioningRequest({
                  ...newRequest,
                  type: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="REGULAR"
                control={<Radio color="primary" />}
                label="Regular"
                style={{ flex: 1 }}
              />
              <FormControlLabel
                value="EMERGENCY"
                control={<Radio color="primary" />}
                label="Emergency"
                style={{ flex: 1 }}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.newRequestDialogActions}>
          <Button
            key={"CANCEL"}
            color="secondary"
            variant="outlined"
            size="small"
            style={{ borderRadius: 50 }}
            onClick={() => {
              onClose();
              setAccess({ canRaise: true, msg: "" });
              setOpenHRConfirmationBox(false);
              setNewProvisioningRequest(initialRequestState);
            }}
            disabled={load}
          >
            Cancel
          </Button>

          <Button
            key={"ADD"}
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenSnackbar(true);
            }}
            style={{ borderRadius: 50 }}
            disabled={
              load ||
              (newRequest?.for === "group"
                ? newRequest?.groups?.length === 0
                : newRequest?.users?.length === 0) ||
              newRequest?.applications?.length === 0 ||
              newRequest?.type?.length === 0 ||
              !selectEachSystemRole()
            }
          >
            Add
          </Button>
        </DialogActions>
        {openSnackbar && (
          <div>
            <Dialog
              open={openSnackbar}
              // onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirmation"}
              </DialogTitle>
              <DialogContent>
                Are you sure you want to raise provisioning request for the
                following systems, roles and users ?
                <TableContainer
                  // component={Paper}
                  className={`${classes.userAssignedRolesTableContainer} iagScroll`}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {newRequest?.for !== "group" ? (
                          <TableCell>Users</TableCell>
                        ) : (
                          <TableCell>Groups</TableCell>
                        )}

                        <TableCell>Applications</TableCell>

                        <TableCell>Roles</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                      className={`${classes.userAssignedRolesTableBody} iagScroll`}
                    >
                      {/* {newRequest?.users?.map((user,index) => */}
                      {newRequest?.applications?.map((application, ind) => (
                        <TableRow
                        // key={`${user?.id}-${index}`}
                        >
                          {ind === 0 && (
                            <TableCell
                              rowSpan={newRequest?.applications.length}
                            >
                              {newRequest?.for === "group"
                                ? newRequest?.groups
                                  ?.map((group) => group?.name)
                                  ?.join(", ")
                                : newRequest?.users
                                  ?.map((user) => user?.displayName)
                                  ?.join(", ")}
                            </TableCell>
                          )}

                          <TableCell
                          // colSpan={newRequest?.applications.length}
                          >
                            {application?.name}
                          </TableCell>
                          <TableCell>
                            {newRequest?.roles
                              ?.filter(
                                (role) =>
                                  role?.applicationId === application?.id
                              )
                              ?.map((role) => role?.name)
                              ?.join(", ")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>

              <DialogActions>
                <Button
                  color="secondary"
                  onClick={() => {
                    setOpenSnackbar(false);
                  }}
                >
                  No
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    var res = newRequest?.applications?.filter(
                      (application) => application?.applicationType !== "CW"
                    );
                    if (res.length > 0) {
                      const hasHRApp = newRequest?.applications?.filter(
                        (application) =>
                          ristrictedApps?.includes(
                            application?.name?.toLowerCase()
                          )
                      );
                      if (hasHRApp.length > 0) {
                        const hrApp = hasHRApp?.map((app) =>
                          app?.name?.toLowerCase()
                        );
                        if (hrApp?.includes("zoho-crm")) {
                          setOpenHRConfirmationBox(true);
                        } else if (hrApp?.includes("ariba")) {
                          aribaValidation();
                        } else {
                          addRequests();
                        }
                      } else {
                        addRequests();
                      }
                    } else {
                      addRequests();
                    }
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Dialog>
    </>
  );
};

const NewDeprovisioningRequest = ({
  open,
  onClose,
  selectedSubItem,
  getRequests,
  getSystems,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);

  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [loggedInUser, setLoggedInUser] = useState({});
  const initialRequestState = {
    for: "self",
    users: loggedInUser,
    allSystems: false,
    applications: [],
    roles: [],
    group: null,
    type: "REGULAR",
  };
  const [newRequest, setNewDeprovisioningRequest] =
    useState(initialRequestState);
  const [load, setLoad] = useState(false);
  const [access, setAccess] = useState({ canRaise: true, msg: "" });
  const [openHRConfirmationBox, setOpenHRConfirmationBox] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const [systems, setSystems] = useState([]);
  const dispatch = useDispatch();

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      newRequest?.for === "self" ? systems : basicReducerState?.applications
    );
    // console.log(application);
    return application?.name || "-";
  };
  const selectApplication = (applicationId, applications) => {
    return findApplicationById(Number(applicationId), applications);
  };
  const getUserAssignedRoles = (userEmail) => {
    if (authVerify(userReducerState?.token)) {
      const getUserAssignedRolesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/byUserEmail?userEmail=${userEmail}`;
      const getUserAssignedRolesRequestParam = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(getUserAssignedRolesUrl, getUserAssignedRolesRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setRoles(data?.data || []);
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
    }
  };
  const getGroupAssignedRoles = (groupName) => { };
  const allSystemReq = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const insertNewDeprovisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      // const insertNewDeprovisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests/removeAllAccess?status=NEW`;
      // const insertNewDeprovisioningRequestPayload =
      //   [userReducerState?.user?.email, newRequest?.users] || [];
      const insertNewDeprovisioningRequestPayload = {
        details: [{ requestType: "DEPROVISIONING_ALL" }],
        createdFor: [newRequest?.users?.emailId],
      };
      const insertNewDeprovisioningRequestRequestParams = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(insertNewDeprovisioningRequestPayload),
      };
      // console.log(insertNewDeprovisioningRequestPayload);
      fetch(
        insertNewDeprovisioningRequestUrl,
        insertNewDeprovisioningRequestRequestParams
      )
        .then((res) => res.json())
        .then((req_data) => {
          setOpenHRConfirmationBox(false);
          setOpenConfirmationDialog(false);
          setLoad(false);
          getRequests();
          getSystems();
          setLoad(false);
          onClose();
          setAccess({ canRaise: true, msg: "" });
          setOpenHRConfirmationBox(false);
          setNewDeprovisioningRequest(initialRequestState);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const selectiveSystemReq = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const insertNewDeprovisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      // const insertNewDeprovisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      var insertNewDeprovisioningRequestPayload = [];
      newRequest?.applications?.map((application) => {
        insertNewDeprovisioningRequestPayload.push({
          requestType: `DEPROVISIONING`,
          // pid: newRequest?.users?.userId,
          // userName: newRequest?.users?.userName,
          createdFor: newRequest?.users?.emailId,
          createdBy: userReducerState?.user?.email,
          createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          status: "IN_PROGRESS",
          sla: 0,
          isActive: 1,
          isDeleted: 0,
          systemId: Number(application?.id),
          roleId:
            newRequest?.roles
              ?.filter((role) => role?.applicationId === application?.id)
              ?.map((role) => Number(role?.id)) || [],
          isEmergency: newRequest?.type === "REGULAR" ? 0 : 1,
          // systemName: application?.name,
          // systemOwnerEmail: application?.applicationOwnerEmails,
          // processingDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          // roleName:
          //   newRequest?.roles
          //     ?.filter((role) => role?.applicationId === application?.id)
          //     ?.map((role) => role?.name) || [],
        });
        return null;
      });
      const insertNewDeprovisioningRequestRequestParams = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify({
          details: insertNewDeprovisioningRequestPayload,
          createdFor: [newRequest?.users?.emailId],
        }),
      };
      console.log(insertNewDeprovisioningRequestPayload);
      // console.log(insertNewDeprovisioningRequestPayload, JSON.stringify(insertNewDeprovisioningRequestRequestParams.body));
      fetch(
        insertNewDeprovisioningRequestUrl,
        insertNewDeprovisioningRequestRequestParams
      )
        .then((res) => res.json())
        .then((req_data) => {
          setOpenHRConfirmationBox(false);
          setOpenConfirmationDialog(false);
          setLoad(false);
          getRequests();
          getSystems();
          setLoad(false);
          onClose();
          setAccess({ canRaise: true, msg: "" });
          setOpenHRConfirmationBox(false);
          setNewDeprovisioningRequest(initialRequestState);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
    // addTaskToITM(insertNewDeprovisioningRequestPayload);
  };
  const groupSystemReq = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const insertNewDeprovisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      // const insertNewDeprovisioningRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests`;
      var insertNewDeprovisioningRequestPayload = [];
      newRequest?.applications?.map((application) => {
        insertNewDeprovisioningRequestPayload.push({
          requestType: `DEPROVISIONING`,
          createdFor: newRequest?.group?.name,
          createdBy: userReducerState?.user?.email,
          createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          status: "IN_PROGRESS",
          sla: 0,
          isActive: 1,
          isDeleted: 0,
          isGroup: 1,
          systemId: application?.id,
          roleId:
            newRequest?.roles
              ?.filter((role) => role?.applicationId === application?.id)
              ?.map((role) => Number(role?.id)) || [],
          isEmergency: newRequest?.type === "REGULAR" ? 0 : 1,
          // systemName: application?.name,
          // systemOwnerEmail: application?.applicationOwnerEmails,
          // processingDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          // roleName:
          //   newRequest?.roles
          //     ?.filter((role) => role?.applicationId === application?.id)
          //     ?.map((role) => role?.name) || [],
        });
        return null;
      });

      const insertNewDeprovisioningRequestRequestParams = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify({
          details: insertNewDeprovisioningRequestPayload,
          createdFor: [newRequest?.group?.name],
        }),
      };
      console.log(insertNewDeprovisioningRequestPayload);
      // console.log(insertNewDeprovisioningRequestPayload, JSON.stringify(insertNewDeprovisioningRequestRequestParams.body));
      fetch(
        insertNewDeprovisioningRequestUrl,
        insertNewDeprovisioningRequestRequestParams
      )
        .then((res) => res.json())
        .then((req_data) => {
          setOpenHRConfirmationBox(false);
          setOpenConfirmationDialog(false);
          setLoad(false);
          getRequests();
          getSystems();
          setLoad(false);
          onClose();
          setAccess({ canRaise: true, msg: "" });
          setOpenHRConfirmationBox(false);
          setNewDeprovisioningRequest(initialRequestState);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const addRequests = () => {
    if (newRequest?.allSystems) {
      allSystemReq();
    } else {
      if (newRequest?.for === "self" || newRequest?.for === "other") {
        selectiveSystemReq();
      } else if (newRequest?.for === "group") {
        groupSystemReq();
      }
    }
  };
  const groupApplicationAccess = (app) => {
    const roles = basicReducerState?.roles?.filter(
      (role) =>
        role?.applicationId === app?.id &&
        newRequest?.group?.roleIdList
          ?.split(",")
          ?.map((roleId) => Number(roleId))
          ?.includes(role?.id)
    );
    return roles?.length > 0 ? true : false;
  };
  const getAllSystems = () => {
    if (authVerify(userReducerState?.token)) {
      const getAllSystemUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications`;
      const getAllSystemsRequestParam = {
        mehtod: "GET",
        headers: {
          Authorization: `Bearer ${userReducerState?.token}`,
          "Content-Type": "application/json",
        },
      };
      fetch(getAllSystemUrl, getAllSystemsRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setSystems(data?.data || []);
        })
        .catch((err) => {
          setSystems([]);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  useEffect(() => {
    setLoggedInUser(
      basicReducerState?.users?.find(
        (user) => user?.emailId === userReducerState?.user?.email
      )
    );
  }, [basicReducerState?.users]);

  useEffect(() => {
    setNewDeprovisioningRequest({ ...newRequest, users: loggedInUser });
  }, [loggedInUser]);

  useEffect(() => {
    if (newRequest?.for === "group") {
      if (newRequest?.group) {
      } else {
        setRoles([]);
      }
    } else if (newRequest?.for === "other") {
      if (newRequest?.users) {
        getUserAssignedRoles(newRequest?.users?.emailId);
      } else {
        setRoles([]);
      }
    } else {
      if (loggedInUser?.emailId) {
        getUserAssignedRoles(loggedInUser?.emailId);
      } else {
        setRoles([]);
      }
    }
  }, [newRequest?.users, newRequest?.group]);

  useEffect(() => {
    getAllSystems();
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => {
          onClose();
          setAccess({ canRaise: true, msg: "" });
          setOpenHRConfirmationBox(false);
          setNewDeprovisioningRequest(initialRequestState);
        }}
      >
        <DialogTitle className={classes.newRequestDialogTitle}>
          {selectedSubItem} Request
        </DialogTitle>

        <DialogContent style={{ position: "relative" }}>
          <Loading load={load} />

          {!access?.canRaise && (
            <Typography
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: "red",
                textAlign: "center",
              }}
            >
              {access?.msg}
            </Typography>
          )}

          <Typography
            style={{ fontSize: 14, fontWeight: "bold", marginTop: 8 }}
          >
            Request For
          </Typography>

          <FormControl
            component="fieldset"
            style={{ marginTop: 0, width: "100%" }}
          >
            <RadioGroup
              aria-label="requestFor"
              name="requestFor"
              style={{ flexDirection: "row" }}
              value={newRequest?.for}
              onChange={(e) => {
                setNewDeprovisioningRequest({
                  ...newRequest,
                  for: e.target.value,
                  users: e.target.value === "self" ? loggedInUser : null,
                  allSystems: false,
                  applications: [],
                  roles: [],
                  group: null,
                });
              }}
            >
              {userFeatures?.includes(features?.SELF_REQUESTS) && (
                <FormControlLabel
                  value="self"
                  control={<Radio color="primary" />}
                  label="Self"
                  style={{ flex: 1 }}
                />
              )}

              {userFeatures?.includes(features?.OTHERS_REQUESTS) && (
                <FormControlLabel
                  value="other"
                  control={<Radio color="primary" />}
                  label="Others"
                  style={{ flex: 1 }}
                />
              )}

              {userFeatures?.includes(features?.OTHERS_REQUESTS) && (
                <FormControlLabel
                  value="group"
                  control={<Radio color="primary" />}
                  label="Group"
                  style={{ flex: 1 }}
                />
              )}
            </RadioGroup>
          </FormControl>

          {newRequest?.for === "other" && (
            <Autocomplete
              multiple={false}
              size="small"
              style={{ fontSize: 12 }}
              disableCloseOnSelect
              filterSelectedOptions
              value={newRequest?.users}
              onChange={(e, users) => {
                setNewDeprovisioningRequest({
                  ...newRequest,
                  users: users,
                  allSystems: false,
                  applications: [],
                  roles: [],
                  group: null,
                });
              }}
              options={basicReducerState?.users}
              getOptionLabel={(option) => option?.userName}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox color="primary" fontSize="small" />}
                    checked={selected}
                  />
                  <Typography style={{ fontSize: 12 }}>
                    {option.userName}
                  </Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Users"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          )}

          {newRequest?.for === "group" && (
            <Autocomplete
              multiple={false}
              size="small"
              style={{ fontSize: 12 }}
              disableCloseOnSelect
              filterSelectedOptions
              value={newRequest?.group}
              onChange={(e, group) => {
                setNewDeprovisioningRequest({
                  ...newRequest,
                  group: group,
                  allSystems: false,
                  applications: [],
                  roles: [],
                  users: null,
                });
              }}
              options={basicReducerState?.groups}
              getOptionLabel={(option) => option?.name}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox color="primary" fontSize="small" />}
                    checked={selected}
                  />
                  <Typography style={{ fontSize: 12 }}>
                    {option.name}
                  </Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Groups"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          )}

          {userFeatures?.includes(features?.OTHERS_REQUESTS) &&
            newRequest?.for === "other" &&
            newRequest?.users && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRequest.allSystems}
                    onChange={(e) => {
                      setNewDeprovisioningRequest({
                        ...newRequest,
                        allSystems: e.target.checked,
                      });
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="All Systems"
              />
            )}
          {console.log('11', systems?.filter((system) =>
            newRequest?.users?.applicationName?.includes(system?.name)
            

          ))}

          {!newRequest.allSystems && (

            <Autocomplete
              multiple
              size="small"
              style={{ fontSize: 12 }}
              disableCloseOnSelect
              filterSelectedOptions
              value={newRequest?.applications}
              onChange={(e, applications) => {
                if (newRequest?.applications?.length > applications?.length) {
                  setNewDeprovisioningRequest({
                    ...newRequest,
                    applications: applications,
                    roles: newRequest?.roles?.filter((role) =>
                      applications
                        ?.map((app) => Number(app?.id))
                        ?.includes(role?.applicationId)
                    ),
                  });
                } else {
                  setNewDeprovisioningRequest({
                    ...newRequest,
                    applications: applications,
                  });
                }
                setAccess({ canRaise: true, msg: "" });
              }}
              options={
                newRequest?.for === "group"
                  ? basicReducerState?.applications?.filter((app) =>
                    groupApplicationAccess(app)
                  )
                  : newRequest?.for === "self"
                    ? systems?.filter((system) =>
                      newRequest?.users?.applicationName?.includes(system?.name)
                    )
                    : basicReducerState?.applications?.filter((app) =>
                      newRequest?.users?.applicationName?.includes(app?.name)
                    )
              }
              getOptionLabel={(option) => option?.name}
              // getOptionDisabled={(option) =>
              //   userReducerState?.entitiesAndActivities?.country?.toLowerCase() !==
              //   "india"
              //     ? option?.applicationType?.toLowerCase() !== "cw"
              //     : null
              // }
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox color="primary" fontSize="small" />}
                    checked={selected}
                  />
                  <Typography
                    style={{ fontSize: 12 }}
                  >{`${option.name}`}</Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Systems"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          )}

          {newRequest?.applications?.length > 0 && (
            <Autocomplete
              multiple
              size="small"
              style={{ fontSize: 12 }}
              disableCloseOnSelect
              filterSelectedOptions
              value={newRequest?.roles}
              onChange={(e, roles) => {
                setNewDeprovisioningRequest({ ...newRequest, roles: roles });
              }}
              options={roles?.filter((role) =>
                selectApplication(role?.applicationId, newRequest?.applications)
              )}
              groupBy={(option) => getApplicationNameById(option.applicationId)}
              getOptionLabel={(option) =>
                `${option?.name} (${getApplicationNameById(
                  option?.applicationId
                )})`
              }
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox color="primary" fontSize="small" />}
                    checked={selected}
                  />
                  <Typography
                    style={{ fontSize: 12 }}
                  >{`${option.name}`}</Typography>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Roles"
                  style={{ fontSize: 12 }}
                />
              )}
            />
          )}

          <Typography
            style={{ fontSize: 14, fontWeight: "bold", marginTop: 12 }}
          >
            Request Type
          </Typography>

          <FormControl
            component="fieldset"
            style={{ marginTop: 0, width: "100%" }}
          >
            <RadioGroup
              aria-label="requestType"
              name="requestType"
              style={{ flexDirection: "row" }}
              value={newRequest?.type}
              onChange={(e) => {
                setNewDeprovisioningRequest({
                  ...newRequest,
                  type: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="REGULAR"
                control={<Radio color="primary" />}
                label="Regular"
                style={{ flex: 1 }}
              />
              <FormControlLabel
                value="EMERGENCY"
                control={<Radio color="primary" />}
                label="Emergency"
                style={{ flex: 1 }}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.newRequestDialogActions}>
          <Button
            key={"CANCEL"}
            color="secondary"
            variant="outlined"
            size="small"
            style={{ borderRadius: 50 }}
            onClick={() => {
              onClose();
              setAccess({ canRaise: true, msg: "" });
              setOpenHRConfirmationBox(false);
              setNewDeprovisioningRequest(initialRequestState);
            }}
            disabled={load}
          >
            Cancel
          </Button>

          <Button
            key={"ADD"}
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenConfirmationDialog(true);
            }}
            style={{ borderRadius: 50 }}
            disabled={
              load ||
              (newRequest?.for === "group"
                ? !newRequest?.groups
                : !newRequest?.users) ||
              (newRequest?.applications?.length === 0 &&
                !newRequest?.allSystems) ||
              newRequest?.type?.length === 0
              // !selectEachSystemRole()
              // !newRequest?.users ||
              // (!newRequest?.allSystems &&
              //   newRequest?.applications?.length === 0) ||
              // newRequest?.type?.length === 0
              //  ||
              // (newRequest?.applications?.length > 0 &&
              //   newRequest?.roles?.length === 0)
            }
          >
            Add
          </Button>
        </DialogActions>
        {openConfirmationDialog && (
          <div>
            <Dialog
              open={openConfirmationDialog}
              // onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirmation"}
              </DialogTitle>
              {newRequest?.allSystems === false && (
                <DialogContent>
                  Are you sure you want to raise deprovisioning request for the
                  following systems, roles and users ?
                  <TableContainer
                    // component={Paper}
                    className={`${classes.userAssignedRolesTableContainer} iagScroll`}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {newRequest?.for === "group" ? "Groups" : "Users"}
                          </TableCell>

                          <TableCell>Applications</TableCell>

                          <TableCell>Roles</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody
                        className={`${classes.userAssignedRolesTableBody} iagScroll`}
                      >
                        {/* {newRequest?.users?.map((user,index) => */}
                        {newRequest?.applications?.map((application, ind) => (
                          <TableRow
                          // key={`${user?.id}-${index}`}
                          >
                            {ind === 0 && (
                              <TableCell
                                rowSpan={newRequest?.applications.length}
                              >
                                {newRequest?.for === "group"
                                  ? newRequest?.group?.name
                                  : newRequest?.users?.displayName}
                              </TableCell>
                            )}

                            <TableCell
                            // colSpan={newRequest?.applications.length}
                            >
                              {application?.name}
                            </TableCell>
                            <TableCell>
                              {newRequest?.roles
                                ?.filter(
                                  (role) =>
                                    role?.applicationId === application?.id
                                )
                                ?.map((role) => role?.name)
                                ?.join(", ")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
              )}
              {newRequest?.allSystems && (
                <DialogContent>
                  Are you sure you want to raise a deprovisioning request for{" "}
                  {newRequest?.users?.displayName} for all systems ?
                </DialogContent>
              )}

              <DialogActions>
                <Button
                  color="secondary"
                  onClick={() => {
                    setOpenConfirmationDialog(false);
                  }}
                >
                  No
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    addRequests();
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Dialog>
    </>
  );
};

const FilterRequest = ({ open, onClose, selectedSubItem }) => {
  const classes = useStyle();
  const [requestFilterTab, setRequestFilterTab] = useState(0);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent style={{ position: "relative", padding: 0 }}>
        <div className={classes.filterRequestDialogContentHeader}>
          <Tabs
            value={requestFilterTab}
            onChange={(e, newValue) => setRequestFilterTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label="Filters"
              className={classes.filterRequestTab}
              {...a11yProps(0)}
            />

            <Tab
              label="Sort"
              className={classes.filterRequestTab}
              {...a11yProps(1)}
            />
          </Tabs>

          <IconButton
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <AiOutlineClose />
          </IconButton>
        </div>

        <TabPanel value={requestFilterTab} index={0} style={{ padding: 16 }}>
          <Typography
            style={{ fontSize: 14, fontWeight: "bold", marginBottom: 6 }}
          >
            {selectedSubItem === SIDEBAR_SUB_ITEM_PROVISIONING
              ? "Approved"
              : "Termination"}{" "}
            Date
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 14 }}>From</Typography>

              <TextField
                id="date"
                type="date"
                fullWidth
                variant="outlined"
                formate="MM/DD/YYYY"
              // defaultValue="2017-05-24"
              // className={classes.textField}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography style={{ fontSize: 14 }}>To</Typography>

              <TextField
                id="date"
                type="date"
                fullWidth
                variant="outlined"
                formate="MM/DD/YYYY"
                style={{ padding: 0 }}
              />
            </Grid>
          </Grid>

          <FormControl
            component="fieldset"
            style={{ marginTop: 4, width: "100%" }}
          >
            <RadioGroup aria-label="" name="" style={{ flexDirection: "row" }}>
              <FormControlLabel
                value="week"
                control={<Radio color="primary" />}
                label="A Week"
                style={{ flex: 1 }}
              />
              <FormControlLabel
                value="month"
                control={<Radio color="primary" />}
                label="A Month"
                style={{ flex: 1 }}
              />
            </RadioGroup>
          </FormControl>

          <Divider style={{ margin: "10px 0px 16px 0px" }} />

          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
            Employee Type
          </Typography>

          <FormControl
            component="fieldset"
            style={{ marginTop: 0, width: "100%" }}
          >
            <RadioGroup aria-label="" name="" style={{ flexDirection: "row" }}>
              <FormControlLabel
                value="employee"
                control={<Radio color="primary" />}
                label="Employee"
                style={{ flex: 1 }}
              />
              <FormControlLabel
                value="nonEmployee"
                control={<Radio color="primary" />}
                label="Non-Employee"
                style={{ flex: 1 }}
              />
            </RadioGroup>
          </FormControl>
        </TabPanel>

        <TabPanel value={requestFilterTab} index={1} style={{ padding: 16 }}>
          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
            Sort Order
          </Typography>

          <FormControl
            component="fieldset"
            style={{ marginTop: 0, width: "100%" }}
          >
            <RadioGroup aria-label="" name="" style={{ flexDirection: "row" }}>
              <FormControlLabel
                value="week"
                control={<Radio color="primary" />}
                label="A Week"
                style={{ flex: 1 }}
              />
              <FormControlLabel
                value="month"
                control={<Radio color="primary" />}
                label="A Month"
                style={{ flex: 1 }}
              />
            </RadioGroup>
          </FormControl>

          <Divider style={{ margin: "10px 0px 16px 0px" }} />

          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
            Sort By
          </Typography>

          <FormControl
            component="fieldset"
            style={{ marginTop: 0, width: "100%" }}
          >
            <RadioGroup aria-label="" name="" style={{ flexDirection: "row" }}>
              <FormControlLabel
                value="PersonnelID"
                control={<Radio color="primary" />}
                label="Personnel ID"
                style={{ flex: 1 }}
              />
              <FormControlLabel
                value="name"
                control={<Radio color="primary" />}
                label="Name"
                style={{ flex: 1 }}
              />
              <FormControlLabel
                value="terminationDate"
                control={<Radio color="primary" />}
                label="termination Date"
                style={{ flex: 1 }}
              />
            </RadioGroup>
          </FormControl>
        </TabPanel>
      </DialogContent>

      <DialogActions className={classes.filterRequestDialogActions}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
        >
          Clear Filters
        </Button>

        <Button
          color="primary"
          variant="contained"
          size="small"
          style={{ textTransform: "capitalize" }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RequestEmpCard = ({ request, setReqNote, setSelectedRequest }) => {
  const classes = useStyle();

  return (
    <div className={classes.requestEmpCardContainer}>
      <div
        className={`${classes.requestEmpCard}
          ${request?.requestsDetails?.details?.filter(
          (det) => det?.isEmergency === 0
        )?.length > 0
            ? classes.requestEmpCardRegular
            : classes.requestEmpCardEmergency
          }
        `}
      />

      <div className={classes.requestEmpCardTopContainer}>
        <Typography
          className={classes.requestEmpCardEmpName}
          onClick={() => {
            setSelectedRequest(request);
          }}
        >
          {request?.requestsDetails?.details?.[0]?.isGroup === 1
            ? request?.requestsDetails?.details?.[0]?.createdFor
            : request?.firstName && request?.lastName
              ? `${request?.firstName} ${request?.lastName}`
              : request?.emailId}
        </Typography>

        <div className={classes.requestEmpCardStatusContainer}>
          <div
            className={`${classes.requestEmpCardStatus}
              ${true
                ? classes.requestEmpCardStatusSuccess
                : classes.requestEmpCardStatusError
              }
            `}
          />

          <Typography
            className={`${classes.requestEmpCardStatusText}
            ${true
                ? classes.requestEmpCardStatusTextSuccess
                : classes.requestEmpCardStatusTextError
              }
          `}
          >
            {`${request?.progressPer?.toFixed(2)}%` || "-"}
          </Typography>
        </div>
      </div>

      {/* {request?.employeeId && (
        <Typography className={classes.requestEmpCardEmpId}>
          {request?.employeeId || "-"}
        </Typography>
      )} */}

      <div className={classes.requestEmpCardBottomContainer}>
        <div className={classes.requestEmpCardBottomLeftContainer}>
          {/* <div className={classes.requestEmpCardTerminationContainer}> */}
          {/* <MdOutlineWatchLater
              className={classes.requestEmpCardTerminationIcon}
            /> */}

          <Typography
            // className={classes.requestEmpCardTerminationDate}
            // color="secondary"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            {/* {request?.terminationDate || request?.joiningDate || "-"} */}
            {`Request Id: `}
            <span style={{ fontWeight: "normal" }}>
              {request?.requestsDetails?.parentId}
            </span>
          </Typography>
          {/* </div> */}

          <Typography className={classes.requestEmpCardApprovedDate}>
            {`Raised on ${moment(
              new Date(request?.requestsDetails?.details?.[0]?.createdOn)
            ).format("DD-MM-yyyy")}`}
          </Typography>

          {request?.requestsDetails?.details?.[0]?.exitDate && (
            <Typography className={classes.requestEmpCardApprovedDate}>
              {`Exit date ${moment(
                new Date(request?.requestsDetails?.details?.[0]?.exitDate)
              ).format("DD-MM-yyyy")}`}
            </Typography>
          )}
        </div>

        <div className={classes.requestEmpCardBottomRightContainer}>
          <BsFillPersonFill style={{ fontSize: 20 }} />

          <AiOutlineFileText
            className={classes.requestEmpCardBottomRightNotesIcon}
            onClick={(e) => {
              e.stopPropagation();
              setReqNote(request);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const RequestEmpSystemStatus = ({
  error,
  success,
  progress,
  reject,
  onClick,
  subRequestId,
  getRequests,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const userReducerState = useSelector((state) => state.userReducer);

  return (
    <div className={classes.requestEmpSystemStatusContainer}>
      {progress && (
        <CgSandClock className={classes.requestEmpSystemStatusWarning} />
      )}

      {success && (
        <CheckCircle className={classes.requestEmpSystemStatusSuccess} />
      )}

      {error && <Error className={classes.requestEmpSystemStatusError} />}

      {reject && <Cancel className={classes.requestEmpSystemStatusError} />}

      {(progress || error || success || reject) && (
        <div className={classes.requestEmpSystemStatusInfo}>
          {error && (
            <BiRefresh
              onClick={(e) => {
                e.stopPropagation();
                if (authVerify(userReducerState?.token)) {
                  setLoad(true);
                  const retryRequestUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests/retryRequest?subReqId=${subRequestId}`;
                  const retryRequestParams = {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${userReducerState.token}`,
                      "Content-Type": "application/json",
                    },
                  };
                  fetch(retryRequestUrl, retryRequestParams)
                    .then((res) => res.json())
                    .then((req_data) => {
                      dispatch(
                        setResponseMessage({
                          open: true,
                          status: req_data?.status ? "success" : "error",
                          message: req_data?.status
                            ? toastMessage?.REQUEST_RETRIED_SUCESSFULLY
                            : toastMessage?.SOMETHING_WENT_WRONG,
                        })
                      );
                      getRequests();
                    })
                    .catch((err) => {
                      setLoad(false);
                    });
                } else {
                  dispatch(setIsSessionExpired(true));
                }
              }}
              style={{
                fontSize: 16,
                cursor: "pointer",
              }}
            />
          )}

          <GiCancel
            onClick={(e) => {
              e.stopPropagation();
              if (authVerify(userReducerState?.token)) {
                // cancelling the subrequest
              } else {
                dispatch(setIsSessionExpired(true));
              }
            }}
            style={{
              fontSize: 16,
              cursor: "pointer",
            }}
          />

          <MoreHoriz
            onMouseEnter={onClick}
            style={{
              fontSize: 16,
              marginLeft: 8,
            }}
          />
        </div>
      )}
    </div>
  );
};

const RequestEmpNotes = ({ open, onClose, reqNote }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  const initialNoteState = {
    parentID: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
    title: "On-boarding",
    userCanAddNotes: true,
    enabled: true,
    publicAccess: true,
    createdBy: null,
    createdOn: null,
    lastUpdatedOn: null,
    lastUpdatedBy: null,
    lastSectionId: null,
    methodOfOperation: "readandcreate",
    userDetails: [],
    sectionDetails: [
      {
        noteId: null,
        parentId: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
        title: reqNote?.emailId,
        seq: 1,
        editable: true,
        exportToPdf: true,
        enabled: true,
        value: "",
      },
    ],
  };
  const [note, setNote] = useState(initialNoteState);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (reqNote?.emailId) {
      readAndCreateNote();
    }
  }, [reqNote?.emailId]);

  const readAndCreateNote = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const getNotesUrl = `${process.env.REACT_APP_WCServices}/note/crudnotes`;
      setNote({
        ...note,
        parentID: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
        methodOfOperation: "readandcreate",
        sectionDetails: note?.sectionDetails?.map((detail) => ({
          ...detail,
          parentId: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
          title: reqNote?.emailId,
        })),
      });
      const getNotesPayload = {
        ...note,
        parentID: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
        methodOfOperation: "readandcreate",
        sectionDetails: note?.sectionDetails?.map((detail) => ({
          ...detail,
          parentId: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
          title: reqNote?.emailId,
        })),
      };
      const getNotesRequestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState?.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(getNotesPayload),
      };
      // console.log("getNotesPayload", getNotesPayload);
      fetch(getNotesUrl, getNotesRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setNote(
            data?.data?.sectionDetails?.length !== 0
              ? data?.data
              : getNotesPayload
          );
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const updateNote = (data) => {
    // console.log(data);
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const updateNotesUrl = `${process.env.REACT_APP_WCServices}/note/crudnotes`;
      setNote({
        ...note,
        parentID: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
        methodOfOperation: "update",
        sectionDetails: note?.sectionDetails?.map((detail) => ({
          ...detail,
          parentId: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
          title: reqNote?.emailId,
          value: data?.value || "",
        })),
      });
      const updateNotesPayload = {
        ...note,
        parentID: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
        methodOfOperation: "update",
        sectionDetails: note?.sectionDetails?.map((detail) => ({
          ...detail,
          parentId: `IWA-${reqNote?.emailId}-${reqNote?.requestsDetails?.parentId}`,
          title: reqNote?.emailId,
          value: data?.value || "",
        })),
      };
      const updateNotesRequestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState?.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateNotesPayload),
      };
      // console.log("updateNotesPayload", updateNotesPayload);
      fetch(updateNotesUrl, updateNotesRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setNote(
            data?.data?.sectionDetails?.length === 0
              ? data?.data
              : updateNotesPayload
          );
          setLoad(false);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? `Notes updated successfully` //${reqNote?.emailId} - Notes updated successfully
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        onClose();
        setNote(initialNoteState);
      }}
    >
      <DialogTitle className={classes.requestEmpNotesDialogTitle}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ flex: 1 }}>
            Notes - {`${reqNote?.firstName} ${reqNote?.lastName}`} (
            {reqNote?.employeeId})
          </Typography>

          <IconButton
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className="iagScroll">
        {load ? (
          <div style={{ padding: 120 }}>
            <Loading load={load} />
          </div>
        ) : (
          <Notes
            tabDetails={note?.sectionDetails || []}
            onSave={(data) => {
              updateNote(data);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const RequestEmpSystemInfo = ({
  id,
  anchorEl,
  onClose,
  requestEmpSystemInfo,
}) => {
  // const [empSystemInfoTab, setEmpSystemInfoTab] = useState(0);

  return (
    <Popper
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      placement="bottom-end"
      style={{ zIndex: 999999 }}
    >
      <Paper
        style={{
          backgroundColor: "white",
          padding: 10,
          zIndex: 999,
          width: 200,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              Status
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography style={{ fontSize: 12, textTransform: "capitalize" }}>
              {requestEmpSystemInfo?.status?.toLowerCase()}
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              Roles
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography style={{ fontSize: 12, textTransform: "capitalize" }}>
              {requestEmpSystemInfo?.roleName?.join(", ")?.toLowerCase() || "-"}
            </Typography>
          </Grid>
        </Grid> */}
      </Paper>
    </Popper>
  );
};

const Requests = ({ selectedSubItem, setSelectedRequest, load, setLoad }) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);

  const [requestsContentType, setRequestsContentType] = useState("Active");
  const [reqNote, setReqNote] = useState(null);
  const [
    openNewProvisioningRequestDialog,
    setOpenNewProvisioningRequestDialog,
  ] = useState(false);
  const [
    openNewDeprovisioningRequestDialog,
    setOpenNewDeprovisioningRequestDialog,
  ] = useState(false);
  const [openFilterRequestDialog, setOpenFilterRequestDialog] = useState(false);
  const [requests, setrequests] = useState([]);
  const [requestSearch, setRequestSearch] = useState("");
  const [discrepantUsers, setDiscrepantUsers] = useState(false);
  const [systems, setSystems] = useState([]);
  const [filteredSystems, setFilteredSystems] = useState([]);
  const [systemType, setSystemType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvData, setCSVData] = useState([]);
  const requestEmpInfoId = Boolean(anchorEl)
    ? "requestEmpInfoIdPopup"
    : undefined;
  const [requestEmpSystemInfo, setRequestEmpSystemInfo] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getSystems();
  }, [systemType, selectedSubItem, requestsContentType]);

  useEffect(() => {
    if (systemType) {
      if (requestSearch?.length > 0) {
        filterRequests();
      } else {
        settingRequests();
      }
    }
  }, [
    basicReducerState.requests,
    requestsContentType,
    requestSearch,
    discrepantUsers,
    selectedSubItem,
    systemType,
    filteredSystems,
  ]);

  useEffect(() => {
    downloadData();
  }, [requests]);

  useEffect(() => {
    setRequestsContentType("Active");
    setSystemType("PROD");
  }, [selectedSubItem]);

  const getSystems = () => {
    if (authVerify(userReducerState?.token)) {
      const getRequestSystemsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests/application?requestType=${selectedSubItem}&requestStatus=${requestsContentType}`;
      const getRequestSystemsRequestParam = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(getRequestSystemsUrl, getRequestSystemsRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setSystems(data?.data || []);
          if (systemType === "all") {
            setFilteredSystems(data?.data);
          } else {
            setFilteredSystems(
              data?.data?.filter(
                (system) =>
                  system?.applicationType?.toLowerCase() ===
                  systemType?.toLowerCase()
              )
            );
          }
        })
        .catch((err) => {
          setSystems([]);
          setFilteredSystems([]);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  }
  const settingRequests = () => {
    const systemIds = filteredSystems
      ?.filter((app) => app?.applicationType === systemType)
      ?.map((app) => Number(app?.id));
    if (requestsContentType === "Active") {
      setrequests(
        basicReducerState?.requests
          ?.filter((request) =>
            discrepantUsers
              ? request?.errorRequest !== 0
              : request?.inProgressRequest !== 0 || request?.errorRequest !== 0
          )
          ?.map((request) => {
            const requestSystemsDetail =
              request?.requestsDetails?.details?.filter(
                (requestSystemDetail) =>
                  // ((!discrepantUsers &&
                  //   requestSystemDetail?.status?.toLowerCase() ===
                  //     "in progress") ||
                  //   requestSystemDetail?.status?.toLowerCase() === "error") &&
                  requestSystemDetail?.requestType
                    ?.split("_")?.[0]
                    ?.toLowerCase() === selectedSubItem?.toLowerCase()
              );
            if (requestSystemsDetail?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: requestSystemsDetail || [],
                },
              };
            }
            return null;
          })
          ?.filter((req) => req)
          ?.map((request) => {
            const newDetails = request?.requestsDetails?.details?.filter(
              (det) =>
                systemType !== "all"
                  ? systemIds?.includes(Number(det?.systemId))
                  : request?.requestsDetails?.details
            );
            if (newDetails?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: newDetails,
                },
              };
            }
            return null;
          })
          ?.filter((req) => req) || []
      );
    } else if (requestsContentType === "Completed") {
      setrequests(
        basicReducerState?.requests
          ?.filter(
            (request) =>
              request?.inProgressRequest === 0 &&
              request?.errorRequest === 0 &&
              request?.completedRequest !== 0
          )
          ?.map((request) => {
            const requestSystemsDetail =
              request?.requestsDetails?.details?.filter(
                (requestSystemDetail) =>
                  // !discrepantUsers &&
                  // (requestSystemDetail?.status?.toLowerCase() === "complete" ||
                  //   requestSystemDetail?.status?.toLowerCase() ===
                  //     "completed") &&
                  requestSystemDetail?.requestType
                    ?.split("_")?.[0]
                    ?.toLowerCase() === selectedSubItem?.toLowerCase()
              );
            if (requestSystemsDetail?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: requestSystemsDetail || [],
                },
              };
            }
            return null;
          })
          ?.filter((req) => req)
          ?.map((request) => {
            const newDetails = request?.requestsDetails?.details?.filter(
              (det) =>
                systemType !== "all"
                  ? systemIds?.includes(Number(det?.systemId))
                  : request?.requestsDetails?.details
            );
            if (newDetails?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: newDetails,
                },
              };
            }
            return null;
          })
          ?.filter((req) => req) || []
      );
    } else if (requestsContentType === "Upcoming") {
      setrequests(
        basicReducerState?.requests
          ?.filter(
            (request) =>
              request?.inProgressRequest === 0 &&
              request?.errorRequest === 0 &&
              request?.completedRequest === 0
          )
          ?.map((request) => {
            const requestSystemsDetail =
              request?.requestsDetails?.details?.filter(
                (requestSystemDetail) =>
                  !discrepantUsers &&
                  requestSystemDetail?.status === "FUTURE" &&
                  requestSystemDetail?.requestType
                    ?.split("_")?.[0]
                    ?.toLowerCase() === selectedSubItem?.toLowerCase()
              );
            if (requestSystemsDetail?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: requestSystemsDetail || [],
                },
              };
            }
            return null;
          })
          ?.filter((req) => req)
          ?.map((request) => {
            const newDetails = request?.requestsDetails?.details?.filter(
              (det) =>
                systemType !== "all"
                  ? systemIds?.includes(Number(det?.systemId))
                  : request?.requestsDetails?.details
            );
            if (newDetails?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: newDetails,
                },
              };
            }
            return null;
          })
          ?.filter((req) => req) || []
      );
    }
    downloadData();
  };
  const filterRequests = () => {
    const systemIds = filteredSystems
      ?.filter((app) => app?.applicationType === systemType)
      ?.map((app) => Number(app?.id));
    if (requestsContentType === "Active") {
      setrequests(
        basicReducerState?.requests
          ?.filter((request) =>
            discrepantUsers
              ? request?.errorRequest !== 0
              : request?.inProgressRequest !== 0 || request?.errorRequest !== 0
          )
          ?.map((request) => {
            if (
              request?.employeeId?.includes(requestSearch?.toLowerCase()) ||
              request?.emailId
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.firstName
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.lastName
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              `${request?.firstName} ${request?.lastName}`
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.designation
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase())
            ) {
              const requestSystemsDetail =
                request?.requestsDetails?.details?.filter(
                  (requestSystemDetail) =>
                    // ((!discrepantUsers &&
                    //   requestSystemDetail?.status?.toLowerCase() ===
                    //     "in progress") ||
                    //   requestSystemDetail?.status?.toLowerCase() === "error") &&
                    requestSystemDetail?.requestType
                      ?.split("_")?.[0]
                      ?.toLowerCase() === selectedSubItem?.toLowerCase()
                );
              if (requestSystemsDetail?.length > 0) {
                return {
                  ...request,
                  requestsDetails: {
                    ...request?.requestsDetails,
                    details: requestSystemsDetail || [],
                  },
                };
              }
              return null;
            }
            return null;
          })
          ?.filter((req) => req)
          ?.map((request) => {
            const newDetails = request?.requestsDetails?.details?.filter(
              (det) =>
                systemType !== "all"
                  ? systemIds?.includes(Number(det?.systemId))
                  : request?.requestsDetails?.details
            );
            if (newDetails?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: newDetails,
                },
              };
            }
            return null;
          })
          ?.filter((req) => req) || []
      );
    } else if (requestsContentType === "Completed") {
      setrequests(
        basicReducerState?.requests
          ?.filter(
            (request) =>
              request?.inProgressRequest === 0 &&
              request?.errorRequest === 0 &&
              request?.completedRequest !== 0
          )
          ?.map((request) => {
            if (
              request?.employeeId?.includes(requestSearch?.toLowerCase()) ||
              request?.emailId
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.firstName
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.lastName
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              `${request?.firstName} ${request?.lastName}`
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.designation
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase())
            ) {
              const requestSystemsDetail =
                request?.requestsDetails?.details?.filter(
                  (requestSystemDetail) =>
                    // !discrepantUsers &&
                    // (requestSystemDetail?.status?.toLowerCase() ===
                    //   "complete" ||
                    //   requestSystemDetail?.status?.toLowerCase() ===
                    //     "completed") &&
                    requestSystemDetail?.requestType
                      ?.split("_")?.[0]
                      ?.toLowerCase() === selectedSubItem?.toLowerCase()
                );
              if (requestSystemsDetail?.length > 0) {
                return {
                  ...request,
                  requestsDetails: {
                    ...request?.requestsDetails,
                    details: requestSystemsDetail || [],
                  },
                };
              }
              return null;
            }
            return null;
          })
          ?.filter((req) => req)
          ?.map((request) => {
            const newDetails = request?.requestsDetails?.details?.filter(
              (det) =>
                systemType !== "all"
                  ? systemIds?.includes(Number(det?.systemId))
                  : request?.requestsDetails?.details
            );
            if (newDetails?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: newDetails,
                },
              };
            }
            return null;
          })
          ?.filter((req) => req) || []
      );
    } else if (requestsContentType === "Upcoming") {
      setrequests(
        basicReducerState?.requests
          ?.filter(
            (request) =>
              request?.inProgressRequest === 0 &&
              request?.errorRequest === 0 &&
              request?.completedRequest === 0
          )
          ?.map((request) => {
            if (
              request?.employeeId?.includes(requestSearch?.toLowerCase()) ||
              request?.emailId
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.firstName
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.lastName
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              `${request?.firstName} ${request?.lastName}`
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase()) ||
              request?.designation
                ?.toLowerCase()
                ?.includes(requestSearch?.toLowerCase())
            ) {
              const requestSystemsDetail =
                request?.requestsDetails?.details?.filter(
                  (requestSystemDetail) =>
                    !discrepantUsers &&
                    requestSystemDetail?.status === "FUTURE" &&
                    requestSystemDetail?.requestType
                      ?.split("_")?.[0]
                      ?.toLowerCase() === selectedSubItem?.toLowerCase()
                );
              if (requestSystemsDetail?.length > 0) {
                return {
                  ...request,
                  requestsDetails: {
                    ...request?.requestsDetails,
                    details: requestSystemsDetail || [],
                  },
                };
              }
              return null;
            }
            return null;
          })
          ?.filter((req) => req)
          ?.map((request) => {
            const newDetails = request?.requestsDetails?.details?.filter(
              (det) =>
                systemType !== "all"
                  ? systemIds?.includes(Number(det?.systemId))
                  : request?.requestsDetails?.details
            );
            if (newDetails?.length > 0) {
              return {
                ...request,
                requestsDetails: {
                  ...request?.requestsDetails,
                  details: newDetails,
                },
              };
            }
            return null;
          })
          ?.filter((req) => req) || []
      );
    }
    downloadData();
  };
  const getRequests = () => {
    if (authVerify(userReducerState?.token)) {
      getAllRequests(
        userReducerState?.token,
        () => {
          setLoad(true);
        },
        (data) => {
          // console.log(data);
          dispatch(setRequests(data?.data || []));
          setLoad(false);
          downloadData();
        },
        (err) => {
          setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const findSystemById = (systemId) => {
    return findApplicationById(Number(systemId), systems);
  };
  const downloadData = () => {
    var bodyData = [requestsFileHeading];
    requests?.map((request) => {
      request?.requestsDetails?.details?.map((reqDet) => {
        const system = findSystemById(reqDet?.systemId);
        // console.log(system);
        bodyData.push([
          `${request?.firstName} ${request?.lastName}`,
          request?.emailId,
          request?.employeeId,
          reqDet?.parentRequestId,
          reqDet?.subRequestId,
          reqDet?.createdBy,
          reqDet?.isEmergency === 0 ? "N" : "Y",
          // reqDet?.itmProcessId,
          // reqDet?.itTaskId,
          reqDet?.requestType,
          reqDet?.status,
          system?.name,
          system?.applicationType,
          reqDet?.updatedBy,
          reqDet?.createdOn,
          reqDet?.updatedOn,
        ]);
        return null;
      });
      return null;
    });
    setCSVData(bodyData);
  };

  return (
    <div className={classes.requestsContainer}>
      {reqNote && (
        <RequestEmpNotes
          open={reqNote ? true : false}
          onClose={() => {
            setReqNote(null);
          }}
          reqNote={reqNote}
        />
      )}

      <NewProvisioningRequest
        open={openNewProvisioningRequestDialog}
        onClose={() => setOpenNewProvisioningRequestDialog(false)}
        selectedSubItem={selectedSubItem}
        getRequests={getRequests}
        getSystems={getSystems}
      />

      <NewDeprovisioningRequest
        open={openNewDeprovisioningRequestDialog}
        onClose={() => setOpenNewDeprovisioningRequestDialog(false)}
        selectedSubItem={selectedSubItem}
        getRequests={getRequests}
        getSystems={getSystems}
      />

      <FilterRequest
        open={openFilterRequestDialog}
        onClose={() => setOpenFilterRequestDialog(false)}
        selectedSubItem={selectedSubItem}
      />

      <div className={classes.requestsTopContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: requestTabHeight,
          }}
        >
          <div className={classes.requestsTabContainer}>
            <Typography
              className={`${classes.requestsTab} ${requestsContentType === "Active" && classes.requestsTabSelected
                }`}
              onClick={() => {
                setRequestsContentType("Active");
                setRequestSearch("");
              }}
            >
              Active (
              {
                basicReducerState?.requests
                  ?.filter((request) =>
                    discrepantUsers
                      ? request?.errorRequest !== 0
                      : request?.inProgressRequest !== 0 ||
                      request?.errorRequest !== 0
                  )
                  ?.map((request) => {
                    const requestSystemsDetail =
                      request?.requestsDetails?.details?.filter(
                        (requestSystemDetail) =>
                          // ((!discrepantUsers &&
                          //   requestSystemDetail?.status?.toLowerCase() ===
                          //     "in progress") ||
                          //   requestSystemDetail?.status?.toLowerCase() === "error") &&
                          requestSystemDetail?.requestType
                            //?.split("-")?.[0]
                            ?.toLowerCase() === selectedSubItem?.toLowerCase()
                      );
                    if (requestSystemsDetail?.length > 0) {
                      return {
                        ...request,
                        requestsDetails: {
                          ...request?.requestsDetails,
                          details: requestSystemsDetail || [],
                        },
                      };
                    }
                    return null;
                  })
                  ?.filter((req) => req)
                  ?.map((request) => {
                    const newDetails =
                      request?.requestsDetails?.details?.filter((det) =>
                        systemType !== "all"
                          ? filteredSystems
                            ?.filter(
                              (app) => app?.applicationType === systemType
                            )
                            ?.map((app) => Number(app?.id))
                            ?.includes(Number(det?.systemId))
                          : request?.requestsDetails?.details
                      );
                    if (newDetails?.length > 0) {
                      return {
                        ...request,
                        requestsDetails: {
                          ...request?.requestsDetails,
                          details: newDetails,
                        },
                      };
                    }
                    return null;
                  })
                  ?.filter((req) => req)?.length
              }
              )
            </Typography>

            <Typography
              className={`${classes.requestsTab} ${requestsContentType === "Completed" &&
                classes.requestsTabSelected
                }`}
              onClick={() => {
                setRequestsContentType("Completed");
                setRequestSearch("");
              }}
            >
              Completed (
              {
                basicReducerState?.requests
                  ?.filter(
                    (request) =>
                      request?.inProgressRequest === 0 &&
                      request?.errorRequest === 0 &&
                      request?.completedRequest !== 0
                  )
                  ?.map((request) => {
                    const requestSystemsDetail =
                      request?.requestsDetails?.details?.filter(
                        (requestSystemDetail) =>
                          // !discrepantUsers &&
                          // (requestSystemDetail?.status?.toLowerCase() === "complete" ||
                          //   requestSystemDetail?.status?.toLowerCase() ===
                          //     "completed") &&
                          requestSystemDetail?.requestType
                            //?.split("-")?.[0]
                            ?.toLowerCase() === selectedSubItem?.toLowerCase()
                      );
                    if (requestSystemsDetail?.length > 0) {
                      return {
                        ...request,
                        requestsDetails: {
                          ...request?.requestsDetails,
                          details: requestSystemsDetail || [],
                        },
                      };
                    }
                    return null;
                  })

                  ?.filter((req) => req)
                  ?.map((request) => {
                    const newDetails =
                      request?.requestsDetails?.details?.filter((det) =>
                        systemType !== "all"
                          ? filteredSystems
                            ?.filter(
                              (app) => app?.applicationType === systemType
                            )
                            ?.map((app) => Number(app?.id))
                            ?.includes(Number(det?.systemId))
                          : request?.requestsDetails?.details
                      );
                    if (newDetails?.length > 0) {
                      return {
                        ...request,
                        requestsDetails: {
                          ...request?.requestsDetails,
                          details: newDetails,
                        },
                      };
                    }
                    return null;
                  })
                  ?.filter((req) => req)?.length
              }
              )
            </Typography>

            {selectedSubItem === SIDEBAR_SUB_ITEM_DEPROVISIONING && (
              <Typography
                className={`${classes.requestsTab} ${requestsContentType === "Upcoming" &&
                  classes.requestsTabSelected
                  }`}
                onClick={() => {
                  setRequestsContentType("Upcoming");
                  setRequestSearch("");
                }}
              >
                Upcoming (
                {
                  basicReducerState?.requests
                    ?.filter(
                      (request) =>
                        request?.inProgressRequest === 0 &&
                        request?.errorRequest === 0 &&
                        request?.completedRequest === 0
                    )
                    ?.map((request) => {
                      const requestSystemsDetail =
                        request?.requestsDetails?.details?.filter(
                          (requestSystemDetail) =>
                            !discrepantUsers &&
                            requestSystemDetail?.status === "FUTURE" &&
                            requestSystemDetail?.requestType
                              //?.split("-")?.[0]
                              ?.toLowerCase() === selectedSubItem?.toLowerCase()
                        );
                      if (requestSystemsDetail?.length > 0) {
                        return {
                          ...request,
                          requestsDetails: {
                            ...request?.requestsDetails,
                            details: requestSystemsDetail || [],
                          },
                        };
                      }
                      return null;
                    })

                    ?.filter((req) => req)
                    ?.map((request) => {
                      const newDetails =
                        request?.requestsDetails?.details?.filter((det) =>
                          systemType !== "all"
                            ? filteredSystems
                              ?.filter(
                                (app) => app?.applicationType === systemType
                              )
                              ?.map((app) => Number(app?.id))
                              ?.includes(Number(det?.systemId))
                            : request?.requestsDetails?.details
                        );
                      if (newDetails?.length > 0) {
                        return {
                          ...request,
                          requestsDetails: {
                            ...request?.requestsDetails,
                            details: newDetails,
                          },
                        };
                      }
                      return null;
                    })
                    ?.filter((req) => req)?.length
                }
                )
              </Typography>
            )}
          </div>

          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              if (selectedSubItem === SIDEBAR_SUB_ITEM_PROVISIONING) {
                setOpenNewProvisioningRequestDialog(true);
              } else {
                setOpenNewDeprovisioningRequestDialog(true);
              }
            }}
          >
            Add
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          style={{ height: requestChartContainerHeight }}
        >
          {requestsContentType === "Active" && (
            <Grid item xs={6} sm={6} md={4}>
              <ChartRequest
                title={`${requestsContentType} Requests`}
                data={[
                  {
                    name: "Error",
                    value:
                      requests
                        ?.map((request) => request?.errorRequest)
                        ?.reduce((acc, previous) => {
                          return acc + previous;
                        }, 0) || 0,
                    // value: 0,
                    color: "#f44336",
                  },
                  {
                    name: "In Progress",
                    value:
                      requests
                        ?.map((request) => request?.inProgressRequest)
                        ?.reduce((acc, previous) => {
                          return acc + previous;
                        }, 0) || 0,
                    color: "#ff9800",
                  },
                ]}
                load={load}
              />
            </Grid>
          )}

          <Grid item xs={6} sm={6} md={4}>
            <ChartRequest
              title="Type of Requests"
              data={[
                {
                  name: "Regular",
                  value: requests
                    ?.map((request) =>
                      request?.requestsDetails?.details?.filter(
                        (det) => det?.isEmergency === 0
                      )?.length > 0
                        ? 1
                        : 0
                    )
                    ?.filter((req) => req)
                    ?.reduce((acc, previous) => {
                      return acc + previous;
                    }, 0),
                  color: "#7986cb",
                },
                {
                  name: "Emergency",
                  value: requests
                    ?.map(
                      (request) =>
                        request?.requestsDetails?.details?.filter(
                          (det) => det?.isEmergency === 1
                        )?.length
                    )
                    ?.filter((req) => req)
                    ?.reduce((acc, previous) => {
                      return acc + previous;
                    }, 0),
                  color: "#ffb74d",
                },
              ]}
              load={load}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.requestsBottomContainer}>
        <div className={classes.requestsBottomHeadingContainer}>
          <Typography className={classes.requestsBottomHeading}>
            Showing {requests?.length} records
          </Typography>

          <div className={classes.requestsBottomHeadingRightContainer}>
            {requestsContentType === "Active" && (
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={discrepantUsers}
                    onClick={(e) => {
                      setDiscrepantUsers(e.target.checked);
                    }}
                  />
                }
                label="Discrepant users"
              />
            )}

            <FormControl
              variant="outlined"
              size="small"
              required
              className={classes.requestsBottomHeadingRightContainerDropdown}
            >
              {/* <InputLabel id="demo-simple-select-label">Application Type</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                // label="Application Type"
                style={{ fontSize: 12 }}
                value={systemType}
                onChange={(e) => setSystemType(e.target.value)}
              >
                <MenuItem value={"all"} style={{ fontSize: 12 }}>
                  Show All
                </MenuItem>
                <MenuItem value={"CW"} style={{ fontSize: 12 }}>
                  Cherrywork Apps
                </MenuItem>
                <MenuItem value={"PROD"} style={{ fontSize: 12 }}>
                  Production Apps
                </MenuItem>
                <MenuItem value={"DEV"} style={{ fontSize: 12 }}>
                  Non production Apps
                </MenuItem>
              </Select>
            </FormControl>

            {/* <IconButton
              size="small"
              style={{ marginLeft: 6 }}
              onClick={() => {
                setOpenFilterRequestDialog(true);
              }}
            >
              <FiFilter style={{ fontSize: 20 }} />
            </IconButton> */}

            <CSVLink data={csvData}>
              <IconButton size="small" style={{ marginLeft: 6 }}>
                <HiDownload style={{ fontSize: 20 }} />
              </IconButton>
            </CSVLink>

            <IconButton
              size="small"
              style={{ marginLeft: 6 }}
              onClick={() => {
                getRequests();
              }}
            >
              <IoReload style={{ fontSize: 20 }} />
            </IconButton>
          </div>
        </div>

        <TableContainer
          component={Paper}
          className={`${classes.requestTableContainer} iagScroll`}
        >
          <Table size="small">
            <TableHead className={classes.requestTableHead}>
              <TableRow>
                <TableCell className={classes.requestTableHeadSearchCell}>
                  <div className={classes.requestsTableHeaderInputContainer}>
                    <input
                      placeholder="Search Employee name, Email ID"
                      value={requestSearch}
                      onChange={(e) => {
                        setRequestSearch(e.target.value);
                      }}
                      className={classes.requestsTableHeaderInput}
                    />

                    <BsSearch />
                  </div>
                </TableCell>

                {filteredSystems?.map((system, index) => (
                  <TableCell
                    key={`${system?.id}-${index}`}
                    align="center"
                    className={classes.requestTableHeadCell}
                  >
                    {system?.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {load ? (
                <>
                  {Array(10)
                    .fill(0)
                    ?.map((val, index) => (
                      <TableRow key={`${val}-${index}`}>
                        <TableCell className={classes.requestTableBodyCardCell}>
                          <Skeleton
                            variant="rect"
                            animation="wave"
                            width={"100%"}
                            height={80}
                          />
                        </TableCell>

                        {filteredSystems?.map((system) => (
                          <TableCell
                            key={system?.id}
                            align="center"
                            className={`${classes.requestTableBodyCell}`}
                          >
                            <Skeleton
                              variant="rect"
                              animation="wave"
                              width={"100%"}
                              height={80}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </>
              ) : (
                <>
                  {requests?.map(
                    (request, index) =>
                      request && (
                        <TableRow key={index}>
                          <TableCell
                            className={classes.requestTableBodyCardCell}
                          >
                            <RequestEmpCard
                              request={request}
                              setReqNote={setReqNote}
                              setSelectedRequest={setSelectedRequest}
                            />
                          </TableCell>

                          {filteredSystems?.map((system) => (
                            <TableCell
                              key={system?.id}
                              align="center"
                              className={`${classes.requestTableBodyCell} ${!discrepantUsers &&
                                status?.PROGRESS?.includes(
                                  request?.requestsDetails?.details?.find(
                                    (r_system) =>
                                      system?.id === r_system?.systemId
                                  )?.status
                                ) &&
                                classes.requestTableBodyCellProgress
                                } ${requestsContentType === "Completed"
                                  ? status?.COMPLETED?.includes(
                                    request?.requestsDetails?.details?.find(
                                      (r_system) =>
                                        system?.id === r_system?.systemId
                                    )?.status
                                  ) && classes.requestTableBodyCellSuccess
                                  : !discrepantUsers &&
                                  status?.COMPLETED?.includes(
                                    request?.requestsDetails?.details?.find(
                                      (r_system) =>
                                        system?.id === r_system?.systemId
                                    )?.status
                                  ) &&
                                  classes.requestTableBodyCellSuccess
                                } ${status?.ERROR?.includes(
                                  request?.requestsDetails?.details?.find(
                                    (r_system) =>
                                      system?.id === r_system?.systemId
                                  )?.status
                                ) && classes.requestTableBodyCellError
                                } ${status?.REJECTED?.includes(
                                  request?.requestsDetails?.details?.find(
                                    (r_system) =>
                                      system?.id === r_system?.systemId
                                  )?.status
                                ) && classes.requestTableBodyCellError
                                }`}
                            >
                              {request?.requestsDetails?.details?.find(
                                (r_system) => system?.id === r_system?.systemId
                              ) && (
                                  <div
                                    onMouseLeave={() => {
                                      setAnchorEl(null);
                                      setRequestEmpSystemInfo(null);
                                    }}
                                  >
                                    <RequestEmpSystemStatus
                                      getRequests={getRequests}
                                      progress={
                                        !discrepantUsers &&
                                        status?.PROGRESS?.includes(
                                          request?.requestsDetails?.details?.find(
                                            (r_system) =>
                                              system?.id === r_system?.systemId
                                          )?.status
                                        )
                                      }
                                      success={
                                        requestsContentType === "Completed"
                                          ? status?.COMPLETED?.includes(
                                            request?.requestsDetails?.details?.find(
                                              (r_system) =>
                                                system?.id ===
                                                r_system?.systemId
                                            )?.status
                                          )
                                          : !discrepantUsers &&
                                          status?.COMPLETED?.includes(
                                            request?.requestsDetails?.details?.find(
                                              (r_system) =>
                                                system?.id ===
                                                r_system?.systemId
                                            )?.status
                                          )
                                      }
                                      error={status?.ERROR?.includes(
                                        request?.requestsDetails?.details?.find(
                                          (r_system) =>
                                            system?.id === r_system?.systemId
                                        )?.status
                                      )}
                                      reject={status?.REJECTED?.includes(
                                        request?.requestsDetails?.details?.find(
                                          (r_system) =>
                                            system?.id === r_system?.systemId
                                        )?.status
                                      )}
                                      onClick={(e) => {
                                        setRequestEmpSystemInfo(
                                          request?.requestsDetails?.details?.find(
                                            (r_system) =>
                                              system?.id === r_system?.systemId
                                          )
                                        );
                                        setAnchorEl(e.currentTarget);
                                      }}
                                      subRequestId={
                                        request?.requestsDetails?.details?.find(
                                          (r_system) =>
                                            Number(system?.id) ===
                                            Number(r_system?.systemId)
                                        )?.subRequestId
                                      }
                                    />

                                    {requestEmpSystemInfo &&
                                      requestEmpSystemInfo?.subRequestId ===
                                      request?.requestsDetails?.details?.find(
                                        (r_system) =>
                                          system?.id === r_system?.systemId
                                      )?.subRequestId && (
                                        <RequestEmpSystemInfo
                                          id={requestEmpInfoId}
                                          anchorEl={anchorEl}
                                          onClose={() => {
                                            setAnchorEl(null);
                                            setRequestEmpSystemInfo(null);
                                          }}
                                          requestEmpSystemInfo={
                                            requestEmpSystemInfo
                                          }
                                        />
                                      )}
                                  </div>
                                )}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Requests;
