export const systemImages = [
  {
    name: "Success Factor",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/SuccessFactor.png",
  },
  {
    name: "OnPrem AD",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/onpremAD.png",
  },
  {
    name: "O365",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/0365.png",
  },
  {
    name: "GRC",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/sap.jpg",
  },
  {
    name: "ECC",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/sap.jpg",
  },
  {
    name: "MDG",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/sap.jpg",
  },
  {
    name: "SolMan",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/SAPSolutionManager.png",
  },
  {
    name: "Quorum",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/quorum.png",
  },
  {
    name: "Ariba",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/Ariba.png",
  },
  {
    name: "AFE Nexus",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/nexus2.png",
  },
  {
    name: "SAP HANA",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/sap_hana.png",
  },
  {
    name: "Petrolook",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/Petrolook.png",
  },
  {
    name: "Allegro",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/Allegro.png",
  },
  {
    name: "Lease Accelerator",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/la.png",
  },
  {
    name: "SAP Cloud Platform",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/Sap_cloud.jpg",
  },
  {
    name: "Badge Systems",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/badgeSystem.png",
  },
  {
    name: "Hyperion",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/Hyperion.png",
  },
  {
    name: "Procount",
    type: "PROD",
    url: "https://flpnwc-flt3duj166.dispatcher.us2.hana.ondemand.com/sap/fiori/murphyuma/images/Procount.png",
  },
];

// ITM Create task payload
export const createTask = {
  listOfProcesssAttributes: [
    {
      customAttributeTemplateDto: [
        {
          processName: "STANDARD",
          key: "description",
          label: "Description",
          processType: null,
          isEditable: true,
          isActive: true,
          isMandatory: true,
          isEdited: 0,
          attrDes: "",
          value: "",
          dataType: "TEXT AREA",
          valueList: null,
          attachmentType: null,
          attachmentSize: null,
          attachmentName: null,
          attachmentId: null,
          attachmentValue: null,
          dataTypeKey: 0,
          dropDownType: null,
          url: null,
          taskId: "",
          origin: null,
          attributePath: null,
          dependantOn: null,
          rowNumber: 0,
          tableAttributes: null,
          tableContents: null,
          isDeleted: false,
          isRunTime: null,
          isVisible: null,
        },
        {
          processName: "UserProvisioning",
          key: "a023a37eca7j8",
          label: "Manager PID",
          processType: null,
          isEditable: true,
          isActive: true,
          isMandatory: true,
          isEdited: 0,
          attrDes: null,
          value: "",
          dataType: "DROPDOWN",
          valueList: [],
          attachmentType: null,
          attachmentSize: null,
          attachmentName: null,
          attachmentId: null,
          attachmentValue: null,
          dataTypeKey: 1,
          dropDownType: "individual",
          url: "/idpMapping/getUsers",
          taskId: null,
          origin: "Process",
          attributePath: null,
          dependantOn: null,
          rowNumber: 0,
          tableAttributes: null,
          tableContents: null,
          isDeleted: false,
          isRunTime: true,
          isVisible: null,
        },
        {
          processName: "UserProvisioning",
          key: "cffh0a25aji9a",
          label: "Role",
          processType: null,
          isEditable: true,
          isActive: true,
          isMandatory: true,
          isEdited: 0,
          attrDes: null,
          value: "",
          dataType: "INPUT",
          valueList: [],
          attachmentType: null,
          attachmentSize: null,
          attachmentName: null,
          attachmentId: null,
          attachmentValue: null,
          dataTypeKey: 0,
          dropDownType: null,
          url: null,
          taskId: null,
          origin: "Process",
          attributePath: null,
          dependantOn: null,
          rowNumber: 0,
          tableAttributes: null,
          tableContents: null,
          isDeleted: false,
          isRunTime: false,
          isVisible: null,
        },
        {
          processName: "UserProvisioning",
          key: "ibb9j5j266bb",
          label: "System",
          processType: null,
          isEditable: true,
          isActive: true,
          isMandatory: true,
          isEdited: 0,
          attrDes: null,
          value: "",
          dataType: "INPUT",
          valueList: [],
          attachmentType: null,
          attachmentSize: null,
          attachmentName: null,
          attachmentId: null,
          attachmentValue: null,
          dataTypeKey: 0,
          dropDownType: null,
          url: null,
          taskId: null,
          origin: "Process",
          attributePath: null,
          dependantOn: null,
          rowNumber: 0,
          tableAttributes: null,
          tableContents: null,
          isDeleted: false,
          isRunTime: false,
          isVisible: null,
        },
        {
          processName: "UserProvisioning",
          key: "ifiabhfe6e46",
          label: "System Owner PID",
          processType: null,
          isEditable: true,
          isActive: true,
          isMandatory: true,
          isEdited: 0,
          attrDes: null,
          value: "",
          dataType: "DROPDOWN",
          valueList: [],
          attachmentType: null,
          attachmentSize: null,
          attachmentName: null,
          attachmentId: null,
          attachmentValue: null,
          dataTypeKey: 1,
          dropDownType: "individual",
          url: "/idpMapping/getUsers",
          taskId: null,
          origin: "Process",
          attributePath: null,
          dependantOn: null,
          rowNumber: 0,
          tableAttributes: null,
          tableContents: null,
          isDeleted: false,
          isRunTime: true,
          isVisible: null,
        },
        {
          processName: "UserProvisioning",
          key: "dj204h7343jaa",
          label: "User PID",
          processType: null,
          isEditable: true,
          isActive: true,
          isMandatory: true,
          isEdited: 0,
          attrDes: null,
          value: "",
          dataType: "INPUT",
          valueList: [],
          attachmentType: null,
          attachmentSize: null,
          attachmentName: null,
          attachmentId: null,
          attachmentValue: null,
          dataTypeKey: 0,
          dropDownType: null,
          url: null,
          taskId: null,
          origin: "Process",
          attributePath: null,
          dependantOn: null,
          rowNumber: 0,
          tableAttributes: null,
          tableContents: null,
          isDeleted: false,
          isRunTime: false,
          isVisible: null,
        },
        {
          processName: "UserProvisioning",
          key: "59ba1a18fad4b",
          label: "Username",
          processType: null,
          isEditable: true,
          isActive: true,
          isMandatory: true,
          isEdited: 0,
          attrDes: null,
          value: "",
          dataType: "INPUT",
          valueList: [],
          attachmentType: null,
          attachmentSize: null,
          attachmentName: null,
          attachmentId: null,
          attachmentValue: null,
          dataTypeKey: 0,
          dropDownType: null,
          url: null,
          taskId: null,
          origin: "Process",
          attributePath: null,
          dependantOn: null,
          rowNumber: 0,
          tableAttributes: null,
          tableContents: null,
          isDeleted: false,
          isRunTime: false,
          isVisible: null,
        },
      ],
      resourceId: null,
    },
  ],
  type: "Single Instance",
  resourceid: null,
  actionType: null,
  processName: "UserProvisioning",
  processId: null,
  isEdited: null,
  requestId: null,
  responseMessage: {
    status: "SUCCESS",
    statusCode: "0",
    message: "ATTRIBUTES ARE FETCHED",
  },
};

// system types
export const appType = {
  CW: "Cherrywork",
  PROD: "Production",
  DEV: "Non-Production",
};

// on-boarding
export const martialStatus = ["Married", "Unmarried"];
export const religions = [];
export const nationalities = ["Indian", "Non-Indian"];
export const genders = ["Male", "Female", "Other"];
export const employeeTypes = ["Employee", "Non-Employee"];
export const communicationTypes = [
  "Personal Email Id",
  "Bussiness Email Id",
  "Primary Contact Number",
  "Secondary Contact Number",
  "Emergency Contact Number",
];
export const attachments = [
  "10th Marksheet",
  "12th Marksheet",
  "Graduation Consolidated Marksheet",
  "Graduation Certificate",
  "Post Graduation Consolidated Marksheet",
  "Post Graduation Certificate",
  "Passport",
  "Aadhar Card",
  "Pan Card",
  "Offer Letter (past company if any)",
  "Increment Letter (past company if any)",
  "Experience Letter (past company if any)",
  "Payslip (last 3 month of past company if any)",
  "Relieving Letter (past company if any)",
  "Resume",
  "Signed NDA Letter",
  "EPF Nomination Form",
  "Signed Offer Letter",
  "Interview Support Document",
  "Signed Employment Aggreement",
  "Promotio Letter (if any)",
];

// request
export const raiseOthersRequestDesignation = ["hr", "product manager"]; //have to remove designation, validations have to be done based on roles
export const ristrictedApps = ["zoho-crm", "ariba"];
export const features = {
  OTHERS_DEBOARDING: "Others_Deboarding",
  OTHERS_ONBOARDING: "Others_Onboarding",
  ADD_GROUPS: "Add_Groups",
  ADD_LICENSES: "Add_Licenses",
  ADD_ROLES: "Add_Roles",
  ADD_ROLE_DETAILS: "Add_Role_Details",
  ADD_SYSTEMS: "Add_Systems",
  ADD_SYSTEM_DETAILS: "Add_System_Details",
  ADD_USERS: "Add_Users",
  ADD_USER_DETAILS: "Add_User_Details",
  MAP_LICENSE: "Map_License",
  REACTIVATE_USERS: "Reactivate_Users",
  REMOVE_GROUPS: "Remove_Groups",
  REMOVE_ROLES: "Remove_Roles",
  REMOVE_SYSTEMS: "Remove_Systems",
  REMOVE_USERS: "Remove_Users",
  SELF_ONBOARDING: "Self_Onboarding",
  SELF_DEBOARDING: "Self_Deboarding",
  SELF_REQUESTS: "Self_Requests",
  UPDATE_SYSTEMS: "Update_Systems",
  VIEW_GROUPS: "View_Groups",
  VIEW_LICENSES: "View_Licenses",
  VIEW_ROLES: "View_Roles",
  VIEW_SYSTEMS: "View_Systems",
  VIEW_USERS: "View_Users",
  OTHERS_REQUESTS: "Others_Requests",
};
export const modules = {
  REQUESTS: "Requests",
  CONFIGURATIONS: "Configurations",
  BOARDING: "Boarding",
};
export const roles = {
  HR_MANAGER: "HR_Manager",
  BUSINESS_USER: "Business_User",
  SYSTEM_OWNER: "System_Owner",
  PEOPLE_MANAGER: "People_Manager",
  SUPER_ADMIN: "Super_Admin",
};
export const raiseRequestForOthersRoles = {
  SUPER_ADMIN: "Super_Admin",
  IWA_ADMIN: "iwaAdmin",
};

// dashboard
export const triggersName = {
  HR_MANAGER: "HttpTrigger1",
  BUSINESS_USER: "HttpTrigger1",
  SYSTEM_OWNER: "HttpTrigger1",
  PEOPLE_MANAGER: "HttpTrigger1",
  SUPER_ADMIN: "HttpTrigger1",
};

// actions
export const action = {
  SUBMIT: "Submit",
  BACK: "Back",
  CANCEL: "Cancel",
  EDIT: "Edit",
  ADD: "Add",
  CONTINUE: "Continue",
};

// status
export const status = {
  PROGRESS: [
    "IN_PROGRESS",
    "IN PROGRESS",
    "AWAITING_MANAGER_APPROVAL",
    "AWAITING_SYSTEM_OWNER_APPROVAL",
    "FUTURE",
    "NEW",
  ],
  COMPLETED: ["COMPLETED", "COMPLETE"],
  REJECTED: ["REJECTED_BY_MANAGER", "REJECTED_BY_SYSTEM_OWNER"],
  ERROR: [
    "ERROR"
  ]
};

// sidebar subItems
export const SIDEBAR_SUB_ITEM_PROVISIONING = "Provisioning";
export const SIDEBAR_SUB_ITEM_DEPROVISIONING = "Deprovisioning";
export const SIDEBAR_SUB_ITEM_APP_MAPPING = "appMapping";
export const SIDEBAR_SUB_ITEM_SYSTEMS = "Systems";
export const SIDEBAR_SUB_ITEM_GROUPS = "Groups";
export const SIDEBAR_SUB_ITEM_ROLES = "Roles";
export const SIDEBAR_SUB_ITEM_USERS = "Users";
export const SIDEBAR_SUB_ITEM_LICENSES = "Licenses";
export const SIDEBAR_SUB_ITEM_ONBOARDING = "Onboarding";
export const SIDEBAR_SUB_ITEM_DEBOARDING = "Deboarding";
export const sidebarItems = {
  REQUESTS: [
    { name: "Provisioning", uri: SIDEBAR_SUB_ITEM_PROVISIONING },
    { name: "Deprovisioning", uri: SIDEBAR_SUB_ITEM_DEPROVISIONING },
  ],
  MAPPING: [{ name: "Application Mapping", uri: SIDEBAR_SUB_ITEM_APP_MAPPING }],
  MASTER_DATA: [
    { name: "Systems", uri: SIDEBAR_SUB_ITEM_SYSTEMS },
    { name: "Groups", uri: SIDEBAR_SUB_ITEM_GROUPS },
    { name: "Roles", uri: SIDEBAR_SUB_ITEM_ROLES },
    { name: "Users", uri: SIDEBAR_SUB_ITEM_USERS },
    { name: "Licenses", uri: SIDEBAR_SUB_ITEM_LICENSES },
  ],
  BOARDING: [
    { name: "Onboarding", uri: SIDEBAR_SUB_ITEM_ONBOARDING },
    { name: "Deboarding", uri: SIDEBAR_SUB_ITEM_DEBOARDING },
  ],
};
