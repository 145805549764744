import {
  Tooltip,
  Select,
  Menu,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  FormGroup,
  ListItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CustomSelect = withStyles((theme) => ({
  // paper: {
  //   marginTop: "35px !important",
  // },
  select: {
    // width: "80px !important",
    fontSize: "12px !important",
  },
  root: {
    // width: "80px !important",
    fontSize: "12px !important",
  },
}))(Select);

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: theme.palette.primary.main,
    maxWidth: 285,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 16,
  },
  arrow: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}))(Tooltip);

export const CustomMenu = withStyles((theme) => ({
  list: {
    maxHeight: "35vh !important",
  },
  paper: {
    marginTop: "35px !important",
  },
}))(Menu);

export const CustomMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "12px !important",
  },
}))(MenuItem);

export const CustomRadioGroup = withStyles((theme) => ({
  root: {
    display: "flex !important",
    flexDirection: "row !important",
    width: "100% !important",
  },
}))(RadioGroup);

export const CustomOutlinedInput = withStyles((theme) => ({
  input: {
    padding: "8px !important",
  },
  multiline: {
    padding: "0px !important",
    fontize: "12px !important",
  },
}))(OutlinedInput);

export const CustomFormGroup = withStyles((theme) => ({
  root: {
    display: "flex !important",
    flexDirection: "row !important",
    width: "100% !important",
  },
}))(FormGroup);

export const CustomListItem = withStyles((theme) => ({
  button: {
    "&:hover": {
      backgroundColor: "#E3EEFF !important",
    },
  },
  selected: {
    backgroundColor: "#E3EEFF !important",
    "&:hover": {
      backgroundColor: "#E3EEFF !important",
    },
  },
}))(ListItem);
