import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomAvatar from "../CustomAvatar";
import UserDetail from "./UserDetail";
// import { allUsers } from "../../Data/data";
import { Search } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  userContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    padding: 10,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userContainerSelected: {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
  userInfoContent: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 8,
  },
  userName: {
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userId: {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  mappingsContainer: {
    width: "94%",
    overflow: "hidden",
  },
  mappingsHeaderContainer: {
    width: "100%",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
    padding: "24px 16px 0px 16px",
  },
  mappingsHeader: {
    fontSize: 24,
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontWeight: "bold",
  },
  mappingsLeftContainer: {
    padding: "16px 16px 0px 16px",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mappingsLeftTopContainer: {
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  mappingsLeftTopHeading: {
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mappingsHeaderInputContainer: {
    margin: 8,
    display: "flex",
    alignItems: "center",
    border: "1px solid #bdbdbd",
    borderRadius: 4,
    padding: 4,
    backgroundColor: "white",
  },
  mappingsHeaderInput: {
    border: "0px solid",
    outlined: "none",
    flex: 1,
  },
  mappingUsersListContainer: {
    width: "100%",
    height: "68vh",
    overflowX: "hidden",
  },
}));

const User = ({ user, onClick }) => {
  const classes = useStyle();
  const params = useParams();

  return (
    <Paper
      elevation={0}
      className={`${classes.userContainer} ${
        Number(user?.id) === Number(params?.userId) &&
        classes.userContainerSelected
      }`}
      onClick={onClick}
    >
      <CustomAvatar name={user?.userName} />

      <div className={classes.userInfoContent}>
        <Typography className={classes.userName}>{user?.userName}</Typography>

        <Typography className={classes.userId}>
          {user?.empId || user?.userId}
        </Typography>
      </div>
    </Paper>
  );
};

const Mappings = ({ selectedSubItem }) => {
  const classes = useStyle();
  const history = useHistory();
  const params = useParams();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [employees, setEmployees] = useState([]);
  const [searchEmp, setSearchEmp] = useState("");

  useEffect(() => {
    setEmployees(basicReducerState.users || []);
  }, [basicReducerState.users]);

  useEffect(() => {
    if (searchEmp?.length > 0) {
      setEmployees(
        basicReducerState?.users?.filter(
          (user) =>
            user?.userName?.toLowerCase()?.includes(searchEmp?.toLowerCase()) ||
            user?.empId?.toLowerCase()?.includes(searchEmp?.toLowerCase())
        ) || []
      );
    } else {
      setEmployees(basicReducerState.users || []);
    }
  }, [searchEmp, basicReducerState?.users]);

  return (
    <div className={classes.mappingsContainer}>
      <div className={classes.mappingsHeaderContainer}>
        <Typography className={classes.mappingsHeader}>
          User {selectedSubItem}
        </Typography>
      </div>

      <Grid container>
        <Grid item xs={3} className={classes.mappingsLeftContainer}>
          <div className={classes.mappingsLeftTopContainer}>
            <Typography className={classes.mappingsLeftTopHeading}>
              Personnel List
            </Typography>

            <Select size="small" value="By Name" style={{ fontSize: 14 }}>
              <MenuItem value="By Name" style={{ fontSize: 14 }}>
                By Name
              </MenuItem>
              <MenuItem value="By Id" style={{ fontSize: 14 }}>
                By Id
              </MenuItem>
            </Select>
          </div>

          <div className={classes.mappingsHeaderInputContainer}>
            <input
              placeholder="Search"
              value={searchEmp}
              onChange={(e) => {
                setSearchEmp(e.target.value);
              }}
              className={classes.mappingsHeaderInput}
            />

            <Search />
          </div>

          <div className={`${classes.mappingUsersListContainer} iagScroll`}>
            {employees?.map((employee, index) => (
              <User
                key={index}
                user={employee}
                onClick={() => {
                  history.push(`/mapping/${employee?.empId || employee?.id}`);
                }}
              />
            ))}
          </div>
        </Grid>

        <Grid item xs={9}>
          {params?.userId && <UserDetail selectedSubItem={selectedSubItem} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default Mappings;
