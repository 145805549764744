import React from "react";
import { useSelector } from "react-redux";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { roles } from "../../Data/data";

function Dashboard() {
  const userReducerState = useSelector((state) => state.userReducer);
  const userRoles = userReducerState?.entitiesAndActivities?.roles || [];

  return (
    <div style={{ maxWidth: "93vw", width: "100%" }}>
      {/* <PowerBIEmbed
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual, and qna.
          id: basicReducerState?.powerBi?.ReportId,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=8fd4d746-42a6-42c2-a4c5-66c10fcf3474&autoAuth=true&ctid=78d7cfc1-dedd-4464-b309-74a59265897e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWluZGlhLXdlc3QtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filter=workaccess_cw_user_role_mapping/user_id%20eq%20${"6"}%20and%20workaccess_cw_requests/created_for%20eq%20%27${
            userReducerState?.entitiesAndActivities?.emailId ||
            "aastik.mandil@incture.com"
          }%27%20and%20workaccess_cw_user_role_mapping/status%20eq%20%27${"Active"}%27`, // basicReducerState?.powerBi?.EmbedUrl
          accessToken: basicReducerState?.powerBi?.AccessToken,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
          },
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              function () {
                console.log("Report rendered");
              },
            ],
            [
              "error",
              function (event) {
                console.log(event.detail);
              },
            ],
          ])
        }
        cssClassName={"report-style-class"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      /> */}
      {userRoles?.includes(roles?.SUPER_ADMIN) ? (
        <iframe
          title="admin_dashboard"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/reportEmbed?reportId=5d0a4bb0-5559-4863-835a-d031e4a790e4&autoAuth=true&ctid=78d7cfc1-dedd-4464-b309-74a59265897e"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      ) : userRoles?.includes(roles?.PEOPLE_MANAGER) ? null : (
        <iframe
          title="default_user_dashboard"
          width="100%"
          height="100%"
          src={`https://app.powerbi.com/reportEmbed?reportId=8fd4d746-42a6-42c2-a4c5-66c10fcf3474&autoAuth=true&ctid=78d7cfc1-dedd-4464-b309-74a59265897e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWluZGlhLXdlc3QtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filter=workaccess_cw_user_role_mapping/user_id%20eq%20${userReducerState?.entitiesAndActivities?.id}%20and%20workaccess_cw_requests/created_for%20eq%20%27${userReducerState?.entitiesAndActivities?.emailId}%27%20and%20workaccess_cw_user_role_mapping/status%20eq%20%27Active%27`}
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      )}
    </div>
  );
}

export default Dashboard;
// people manager- https://app.powerbi.com/reportEmbed?reportId=8fd4d746-42a6-42c2-a4c5-66c10fcf3474&autoAuth=true&ctid=78d7cfc1-dedd-4464-b309-74a59265897e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWluZGlhLXdlc3QtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filter=workaccess_cw_user_role_mapping/user_id%20in%20(6,5)%20and%20workaccess_cw_requests/created_for%20in%20(%27aastik.mandil@incture.com%27,%27deepaksha.chadha@incture.com%27)%20and%20workaccess_cw_user_role_mapping/status%20eq%20%27Active%27
