import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  sessionDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  sessionDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

function SessionExpired({ open, onClick }) {
  const classes = useStyle();

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle className={classes.sessionDialogTitle}>
        Session Expired
      </DialogTitle>

      <DialogContent>
        <Typography style={{ fontSize: 12, marginBottom: 24 }}>
          Your session has expired. Please login again.
        </Typography>
      </DialogContent>

      <DialogActions className={classes.sessionDialogActions}>
        <Button
          variant="text"
          color="primary"
          onClick={onClick}
          style={{ textTransform: "capitalize" }}
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionExpired;
