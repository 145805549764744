import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tab,
  Tabs,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { TabPanel, a11yProps } from "../TabPanel";
import { makeStyles } from "@material-ui/core/styles";
import { FaIdCard } from "react-icons/fa";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { GrUserNew } from "react-icons/gr";
import CustomAvatar from "../CustomAvatar";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import {
  appHeaderHeight,
  buttonHeight,
  crossIconContainerHeight,
  profileHeaderLgMaxHeight,
  profileHeaderMaxHeight,
  profileHeaderSmMaxHeight,
  profileTabHeight,
  sidebarWidth,
  userDetailPageCss,
  userPageHeaderHeight,
} from "../../Data/cssConstant";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";

const useStyle = makeStyles((theme) => ({
  userInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight} - ${crossIconContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${userDetailPageCss?.footerHeight} - 18px)`,
  },
  userInfoItemContainer: {
    margin: "2%",
    // flexDirection:'row',
    // justifyContent: "center",
    // alignItems: "center",
  },
  userContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    // marginBottom: "2%",
    whiteSpace: "no-wrap",
    // width: "20%",
  },
  userInfoContainerHeadingLabel: {
    fontSize: 14,
    fontWeight: "600",
    marginBottom: "2%",
  },
  userInfoContainerLabel: {
    fontSize: 14,
    fontWeight: "600",
    // color: theme.palette.text.secondary,
  },
  userInfoContainerText: {
    fontSize: 14,
  },

  userAssignedRolesTableContainer: {
    // height: `calc(100vh - ${appHeaderHeight} - ${profileHeaderMaxHeight} - ${profileTabHeight})`,
    // overflow: "overlay",
    width: "100%",
    [theme.breakpoints.up('xs')]:{
      height: `calc(100vh - ${appHeaderHeight} - ${profileHeaderSmMaxHeight} - ${profileTabHeight})`,
    },
    [theme.breakpoints.up('lg')]:{
      height: `calc(100vh - ${appHeaderHeight} - ${profileHeaderLgMaxHeight} - ${profileTabHeight})`,
    },
    [theme.breakpoints.down('xs')]:{
      height: `calc(100vh - ${appHeaderHeight} - ${profileHeaderMaxHeight} - ${profileTabHeight} + 8px)`,
    },
  },
  userAssignedRolesTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  userAssignedRolesTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  userAssignedRolesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAssignedRolesTableBodyRow: {
    // "&:hover": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
  userAssignedRolesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  userAssignedRolesBottomAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },
}));

function Profile() {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState(0);
  const [roleData, setRoleData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [licenseData, setLicenseData] = useState([]);

  useEffect(() => {
    getLoggedInUserRoles();
    getLoggedInUserGroups();
    getLoggedInUserLicenses();
  }, []);

  const getLoggedInUserRoles = () => {
    if (authVerify(userReducerState?.token)) {
      const getAllRolesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMappingProfile/byUserEmail?userEmail=${userReducerState?.entitiesAndActivities?.emailId}`;
      const getAllRolesRequestParams = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(getAllRolesUrl, getAllRolesRequestParams)
        .then((res) => res.json())
        .then((data) => {
          setRoleData(data?.data);
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getLoggedInUserGroups = () => {
    if (authVerify(userReducerState?.token)) {
      const getAllGroupsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/getUserProfileGroup?userEmail=${userReducerState?.entitiesAndActivities?.emailId}`;
      const getAllGroupsRequestParams = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(getAllGroupsUrl, getAllGroupsRequestParams)
        .then((res) => res.json())
        .then((data) => {
          setGroupData(data?.data);
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getLoggedInUserLicenses = () => {
    if (authVerify(userReducerState?.token)) {
      const getAllLicensesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/license/mappedUserProfileDetails?userEmail=${userReducerState?.entitiesAndActivities?.emailId}`;
      const getAllLicensesRequestParams = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(getAllLicensesUrl, getAllLicensesRequestParams)
        .then((res) => res.json())
        .then((data) => {
          setLicenseData(data?.data);
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <div
      style={{
        width: `calc(100vw - ${sidebarWidth})`,
        height: `calc(100vh - ${appHeaderHeight})`,
        // display: "flex",
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          maxHeight: profileHeaderMaxHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "row",
          padding: "10px",
          borderRight: "1px solid #d9d9d9",
        }}
      >
        {/* <div style={{whiteSpace: "nowrap"}}> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={classes.userContainer}
        >
          <IconButton style={{ color: "blue" }} disabled={true}>
            {" "}
            <AiOutlineUser />
          </IconButton>
          <div>
            <Typography className={classes.userInfoContainerLabel}>
              Name
            </Typography>
            <Typography className={classes.userInfoContainerText}>
              {userReducerState?.user?.name}
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={classes.userContainer}
        >
          <IconButton style={{ color: "orange" }} disabled={true}>
            {" "}
            <FaIdCard />
          </IconButton>
          <div>
            <Typography className={classes.userInfoContainerLabel}>
              User Id
            </Typography>
            <Typography className={classes.userInfoContainerText}>
              {userReducerState?.user?.pid}
            </Typography>
          </div>
        </Grid>
        {/* </div>
        <div style={{whiteSpace: "nowrap"}}> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={classes.userContainer}
        >
          <IconButton style={{ color: "green" }} disabled={true}>
            {" "}
            <AiOutlineMail />
          </IconButton>
          <div>
            <Typography className={classes.userInfoContainerLabel}>
              Email Id
            </Typography>

            <Typography className={classes.userInfoContainerText}>
              {userReducerState?.user?.email}
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={classes.userContainer}
        >
          <IconButton style={{ color: "green" }} disabled={true}>
            {" "}
            <GrUserNew />
          </IconButton>
          <div>
            <Typography className={classes.userInfoContainerLabel}>
              Username
            </Typography>

            <Typography className={classes.userInfoContainerText}>
              {userReducerState?.user?.preferred_username}
            </Typography>
          </div>
        </Grid>
        {/* </div> */}
      </Grid>

      <div>
        <Tabs
          value={tabName}
          onChange={(e, newValue) => setTabName(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Roles"
            style={{ fontSize: "12px", fontWeight: "600" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Groups"
            style={{ fontSize: "12px", fontWeight: "600" }}
            {...a11yProps(1)}
          />
          <Tab
            label="Licenses"
            style={{ fontSize: "12px", fontWeight: "600" }}
            {...a11yProps(2)}
          />
          {/* <Tab label="Features" style={{fontSize:'12px'}}{...a11yProps(3)} /> */}
        </Tabs>

        <TabPanel value={tabName} index={0}>
          <TableContainer
            // component={Paper}
            className={`${classes.userAssignedRolesTableContainer} iagScroll`}
          >
            <Table
              size="small"
              //   className="table-sort table-arrows"
            >
              <TableHead className={classes.userAssignedRolesTableHead}>
                <TableRow>
                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Application
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Role
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Type
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                className={`${classes.userAssignedRolesTableBody} iagScroll`}
              >
                {roleData?.map((assignedRole, index) =>
                  assignedRole?.roleDetailsProfileDto?.map((role, ind) => (
                    <TableRow
                      // key={`${assignedRole?.id}-${index}`}
                      className={classes.userAssignedRolesTableBodyRow}
                    >
                      {ind === 0 && (
                        <TableCell
                          rowSpan={assignedRole?.roleDetailsProfileDto?.length}
                          className={classes.userAssignedRolesTableBodyCell}
                        >
                          {assignedRole?.applicationName}
                        </TableCell>
                      )}

                      <TableCell
                        className={classes.userAssignedRolesTableBodyCell}
                      >
                        {role?.roleName}
                      </TableCell>

                      <TableCell
                        className={classes.userAssignedRolesTableBodyCell}
                      >
                        {role?.isComposite === 1 ? "Composite" : "Single"}
                      </TableCell>

                      <TableCell
                        className={classes.userAssignedRolesTableBodyCell}
                      >
                        {role?.status}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value={tabName} index={1}>
          <TableContainer
            // component={Paper}
            className={`${classes.userAssignedRolesTableContainer} iagScroll`}
          >
            <Table
              size="small"
              //   className="table-sort table-arrows"
            >
              <TableHead className={classes.userAssignedRolesTableHead}>
                <TableRow>
                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Application
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Groups
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                className={`${classes.userAssignedRolesTableBody} iagScroll`}
              >
                {groupData?.map((assignedGroup, index) =>
                  assignedGroup?.groupUserProfileDetailsDto?.map(
                    (group, ind) => (
                      <TableRow
                        // key={`${assignedGroup?.id}-${index}`}
                        className={classes.userAssignedRolesTableBodyRow}
                      >
                        {ind === 0 && (
                          <TableCell
                            rowSpan={
                              assignedGroup?.groupUserProfileDetailsDto?.length
                            }
                            className={classes.userAssignedRolesTableBodyCell}
                          >
                            {assignedGroup?.applicationName}
                          </TableCell>
                        )}

                        <TableCell
                          className={classes.userAssignedRolesTableBodyCell}
                        >
                          {group?.name}
                        </TableCell>

                        {/* <TableCell
                      className={classes.userAssignedRolesTableBodyCell}
                    >
                      {role?.isComposite === 1 ? "Composite" : "Single"}
                    </TableCell>

                    <TableCell
                      className={classes.userAssignedRolesTableBodyCell}
                    >
                      {role?.status}
                    </TableCell> */}
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value={tabName} index={2}>
          <TableContainer
            // component={Paper}
            className={`${classes.userAssignedRolesTableContainer} iagScroll`}
          >
            <Table
              size="small"
              //   className="table-sort table-arrows"
            >
              <TableHead className={classes.userAssignedRolesTableHead}>
                <TableRow>
                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Application
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    License Number
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    License Type
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableHeadCell}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                className={`${classes.userAssignedRolesTableBody} iagScroll`}
              >
                {licenseData?.map((assignedLicense, index) =>
                  assignedLicense?.licenseDetailsProfileDto?.map(
                    (license, ind) => (
                      <TableRow
                        // key={`${assignedLicense?.id}-${index}`}
                        className={classes.userAssignedRolesTableBodyRow}
                      >
                        {ind === 0 && (
                          <TableCell
                            rowSpan={
                              assignedLicense?.licenseDetailsProfileDto?.length
                            }
                            className={classes.userAssignedRolesTableBodyCell}
                          >
                            {assignedLicense?.applicationName}
                          </TableCell>
                        )}

                        <TableCell
                          className={classes.userAssignedRolesTableBodyCell}
                        >
                          {license?.licenseNumber}
                        </TableCell>

                        <TableCell
                          className={classes.userAssignedRolesTableBodyCell}
                        >
                          {license?.licenseType}
                        </TableCell>

                        <TableCell
                          className={classes.userAssignedRolesTableBodyCell}
                        >
                          {license?.status}
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </div>
    </div>
  );
}

export default Profile;
