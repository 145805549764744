import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRequests } from "../../Action/action";
import { setRequests } from "../../Redux/Reducers/basicReducer";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import { authVerify } from "../../Utility/auth";
import RequestDetail from "./RequestDetail";
import Requests from "./Requests";

function Index({ selectedSubItem }) {
  const userReducerState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    getRequests();
  }, []);

  const getRequests = () => {
    if (authVerify(userReducerState?.token)) {
      getAllRequests(
        userReducerState?.token,
        () => {
          setLoad(true);
        },
        (data) => {
          // console.log(data);
          dispatch(setRequests(data?.data || []));
          setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return selectedRequest ? (
    <RequestDetail
      selectedSubItem={selectedSubItem}
      selectedRequest={selectedRequest}
      setSelectedRequest={setSelectedRequest}
    />
  ) : (
    <Requests
      selectedSubItem={selectedSubItem}
      setSelectedRequest={setSelectedRequest}
      load={load}
      setLoad={setLoad}
    />
  );
}

export default Index;
