import { Box, LinearProgress, Typography } from "@material-ui/core";
import React from "react";

function LoadingApp({ message }) {
  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "60vw",
          height: "100%",
          maxHeight: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={"./Cw_logo.png"}
          alt="Logo"
          height="50px"
          style={{ marginBottom: "10px" }}
        />

        <LinearProgress style={{ width: "100%", height: 5, borderRadius: 6 }} />

        <Typography sx={{ fontSize: 16, marginTop: 10 }} gutterBottom>
          {message}
        </Typography>
      </div>
    </Box>
  );
}

export default LoadingApp;
