import React, { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  Grid,
  Button,
  // Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBack,
  MoreVert,
  Add,
  // GetApp,
  Refresh,
  ArrowForwardIos,
} from "@material-ui/icons";
// import { useHistory } from "react-router-dom";
import moment from "moment";
// import {
// useLocation,
// useParams,
// } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import NewItem from "./NewItem";
// import { allActivities, allApplications, allEntities } from "../../Data/data";
import CustomCard from "./CustomCard";
import {
  getAllActivities,
  getAllApis,
  //   getAllApplications,
  getAllEntities,
  getAllPayloads,
} from "../../Action/action";
import {
  setActivities,
  setApis,
  //   setApplications,
  setEntities,
  setPayloads,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
// import Loading from "../Loading";
import NotFound from "../NotFound";
import NewPayload from "./NewPayload";
import NewApi from "./NewApi";
import {
  activityFileHeading,
  apiFileHeading,
  //   applicationFileHeading,
  downloadFile,
  entityFileHeading,
  payloadFileHeading,
} from "../../Utility/file";
import UploadFile from "../UploadFile";
import { findApplicationById } from "../../Utility/basic";
import DeletionMessageBox from "../DeletionMessageBox";
import { SystemBasicInfo } from "./SystemDetails";
import SystemUserTable from "./SystemUserTable";
import Loading from "../Loading";
import { features, roles } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  cwAppDetailPageHeaderHeight,
  cwAppDetailPageTabHeight,
  sidebarWidth,
} from "../../Data/cssConstant";

const useStyle = makeStyles((theme) => ({
  applicationContainer: {
    width: "100%",
  },
  applicationHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 10px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    height: cwAppDetailPageTabHeight,
    backgroundColor: theme.palette.background.paper,
  },
  applicationHeadeTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  applicationHeadeAddButton: {
    marginLeft: 10,
    borderRadius: 50,
  },
  applicationContentContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${cwAppDetailPageHeaderHeight} - ${cwAppDetailPageTabHeight} - 18px)`,
    padding: "0 4px",
  },
  applicationDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    height: cwAppDetailPageTabHeight,
  },
  applicationDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    maxWidth: 160,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0px 8px",
  },
  applicationDetailHeaderItemSelected: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

function ApplicationDetail({ selectedApplication, setSelectedApplication }) {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  // const userRoles = userReducerState?.entitiesAndActivities?.roles || [];
  // const userRoles = [];
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();
  const [params, setParams] = useState({ applicationId: selectedApplication });

  const [application, setApplication] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [anchorElSubMenu, setAnchorElSubMenu] = useState(null);
  const [operation, setOperation] = useState("");
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [
    selectedApplicationDetailContentType,
    setSelectedApplicationDetailContentType,
  ] = useState("Basic Info");
  const [entities, setentities] = useState([]);
  const [apis, setapis] = useState([]);
  const [activities, setactivities] = useState([]);
  const [payloads, setpayloads] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [entityFile, setEntityFile] = useState(null);
  const [apiFile, setApiFile] = useState(null);
  const [activityFile, setActivityFile] = useState(null);
  const [payloadFile, setPayloadFile] = useState(null);

  const [onLoad, setOnLoad] = useState(false);
  const initialItem = { name: "", label: "", description: "" };
  const [item, setItem] = useState(initialItem);
  const [selectEntity, setSelectedEntity] = useState(null);
  const [selectApi, setSelectedApi] = useState(null);
  const [updatingApi, setUpdatingApi] = useState(null);
  const [updatingPayload, setUpdatingPayload] = useState(null);

  const [addNewEntityDialog, setAddNewEntityDialog] = useState(false);
  const [addNewApiDialog, setAddNewApiDialog] = useState(false);
  const [addNewActivityDialog, setAddNewActivityDialog] = useState(false);
  const [addNewPayloadDialog, setAddNewPayloadDialog] = useState(false);

  const [openEntityFileDialog, setOpenEntityFileDialog] = useState(false);
  const [openApiFileDialog, setOpenApiFileDialog] = useState(false);
  const [openActivityFileDialog, setOpenActivityFileDialog] = useState(false);
  const [openPayloadFileDialog, setOpenPayloadFileDialog] = useState(false);

  const [deletingEntity, setDeletingEntity] = useState(null);
  const [deletingApi, setDeletingApi] = useState(null);
  const [deletingActivity, setDeletingActivity] = useState(null);
  const [deletingPayload, setDeletingPayload] = useState(null);

  const getEntities = () => {
    if (authVerify(userReducerState?.token)) {
      getAllEntities(
        userReducerState.token,
        () => {
          setOnLoad(true);
        },
        (data) => {
          dispatch(setEntities(data?.data || []));
          setOnLoad(false);
        },
        (err) => {
          setOnLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getApis = () => {
    if (authVerify(userReducerState?.token)) {
      getAllApis(
        userReducerState.token,
        () => {
          setOnLoad(true);
        },
        (data) => {
          dispatch(setApis(data?.data || []));
          setOnLoad(false);
        },
        (err) => {
          setOnLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getActivities = () => {
    if (authVerify(userReducerState?.token)) {
      getAllActivities(
        userReducerState.token,
        () => {
          setOnLoad(true);
        },
        (data) => {
          dispatch(setActivities(data?.data || []));
          setOnLoad(false);
        },
        (err) => {
          setOnLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getPayloads = () => {
    if (authVerify(userReducerState?.token)) {
      getAllPayloads(
        userReducerState.token,
        () => {
          setOnLoad(true);
        },
        (data) => {
          dispatch(setPayloads(data?.data || []));
          setOnLoad(false);
        },
        (err) => {
          setOnLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getSystemDetail = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const getSystemDetailUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/read?id=${params?.applicationId}`;
      // const getSystemDetailUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/read?id=${params?.applicationId}`;
      const getSystemDetailRequestParam = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(getSystemDetailUrl, getSystemDetailRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          setFilteredUsers(data?.data || []);
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  const handleCreateEntity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const insertEntityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/entities`;
      // const insertEntityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/entities`;
      const insertEntityPayload = {
        applicationId: params?.applicationId,
        createdBy: userReducerState?.user?.email,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: item?.description,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const insertEntityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(insertEntityPayload),
      };
      fetch(insertEntityUrl, insertEntityRequestParam)
        .then((res) => res.json())
        .then((entity_data) => {
          setOnLoad(false);
          setItem(initialItem);
          getAllEntities(
            userReducerState.token,
            () => {
              setOnLoad(true);
            },
            (data) => {
              dispatch(setEntities(data?.data || []));
              setOnLoad(false);

              dispatch(
                setResponseMessage({
                  open: true,
                  status: entity_data?.status ? "success" : "error",
                  message: entity_data?.status
                    ? "Entity created successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setOnLoad(false);
            }
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleCreateActivity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const insertActivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities`;
      // const insertActivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities`;
      const insertActivityPayload = {
        createdBy: userReducerState?.user?.email,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: item?.description,
        entityId: selectEntity,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const insertActivityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(insertActivityPayload),
      };
      fetch(insertActivityUrl, insertActivityRequestParam)
        .then((res) => res.json())
        .then((activity_data) => {
          setOnLoad(true);
          setItem(initialItem);
          getAllActivities(
            userReducerState.token,
            () => {
              setOnLoad(true);
            },
            (data) => {
              dispatch(setActivities(data?.data || []));
              setOnLoad(false);

              dispatch(
                setResponseMessage({
                  open: true,
                  status: activity_data?.status ? "success" : "error",
                  message: activity_data?.status
                    ? "Activity created successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setOnLoad(false);
            }
          );
        })
        .catch((err) => {
          setOnLoad(true);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  const handleUpdateEntity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const updateEntityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/entities/modify`;
      // const updateEntityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/entities/modify`;
      const updateEntityPayload = {
        id: item?.id,
        applicationId: params?.applicationId,
        createdBy: item?.createdBy,
        createdOn: moment(new Date(item?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: item?.description,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updateEntityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateEntityPayload),
      };
      fetch(updateEntityUrl, updateEntityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          setItem(initialItem);
          dispatch(
            setEntities(
              basicReducerState?.entities?.map((entity) =>
                Number(entity?.id) === Number(item?.id)
                  ? {
                      ...entity,
                      name: item?.name,
                      label: item.label,
                      description: item.description,
                    }
                  : { ...entity }
              ) || []
            )
          );

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Entity updated successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleUpdateActivity = () => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const updateActivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities/modify`;
      // const updateActivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities/modify`;
      const updateActivityPayload = {
        id: item?.id,
        createdBy: item?.createdBy,
        createdOn: moment(new Date(item?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: item?.description,
        entityId: selectEntity,
        isActive: 1,
        isDeleted: 0,
        label: item?.label,
        name: item?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updateActivityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateActivityPayload),
      };
      fetch(updateActivityUrl, updateActivityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          setItem(initialItem);
          dispatch(
            setActivities(
              basicReducerState?.activities?.map((activity) =>
                Number(activity?.id) === Number(item?.id)
                  ? {
                      ...activity,
                      name: item?.name,
                      label: item.label,
                      description: item.description,
                    }
                  : { ...activity }
              ) || []
            )
          );

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Activity updated successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  const handleDeleteEntity = (entityId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disableEntityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/entities/deactivate?id=${entityId}`;
      // const disableEntityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/entities/deactivate?id=${entityId}`;
      const disableEntityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(disableEntityUrl, disableEntityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(
            setEntities(
              entities?.filter((entity) => entity?.id !== entityId) || []
            )
          );
          setDeletingEntity(null);
          setSelectedEntity(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Entity deleted successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleDeleteApi = (apiId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disableApiUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/deactivate?id=${apiId}`;
      // const disableApiUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/deactivate?id=${apiId}`;
      const disableApiRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(disableApiUrl, disableApiRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(setApis(apis?.filter((api) => api?.id !== apiId) || []));
          setDeletingApi(null);
          setSelectedApi(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Api deleted successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleDeleteActivity = (activityId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disableActivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities/deactivate?id=${activityId}`;
      // const disableActivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities/deactivate?id=${activityId}`;
      const disableActivityRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(disableActivityUrl, disableActivityRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(
            setActivities(
              activities?.filter((activity) => activity?.id !== activityId) ||
                []
            )
          );
          setDeletingActivity(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Activity deleted successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleDeletePayload = (payloadId) => {
    if (authVerify(userReducerState.token)) {
      setOnLoad(true);
      const disablePayloadUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload/deactivate?id=${payloadId}`;
      // const disablePayloadUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload/deactivate?id=${payloadId}`;
      const disablePayloadRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(disablePayloadUrl, disablePayloadRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setOnLoad(false);
          dispatch(
            setPayloads(
              payloads?.filter((payload) => payload?.id !== payloadId) || []
            )
          );
          setDeletingPayload(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Payload deleted successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  const uploadEntitiesFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!entityFile) {
        console.log("no file found");
        return;
      }
      setOnLoad(true);
      const url = `${process.env.REACT_APP_IWAServices}/api/v1/entities/addEntitiesUsingCsv?applicationId=${params?.applicationId}`;
      // const url = `${process.env.REACT_APP_IWAServices}/api/v1/entities/addEntitiesUsingCsv?applicationId=${params?.applicationId}`;
      let formData = new FormData();
      formData.append("file", entityFile);
      formData.append("name", entityFile.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: formData,
      };
      fetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setOnLoad(false);
          setOpenEntityFileDialog(false);
          setEntityFile(null);
          getEntities();

          dispatch(
            setResponseMessage({
              open: true,
              status:"success",
              message: "Entity file uploaded successfully",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
          dispatch(
            setResponseMessage({
              open: true,
              status:  "error",
              message: "Something went wrong",
            })
          );
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const uploadActivitiesFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!activityFile) {
        console.log("no file found");
        return;
      }
      setOnLoad(true);
      const url = `${process.env.REACT_APP_IWAServices}/api/v1/activities/addActivitiesUsingCsv`;
      // const url = `${process.env.REACT_APP_IWAServices}/api/v1/activities/addActivitiesUsingCsv`;
      let formData = new FormData();
      formData.append("file", activityFile);
      formData.append("file_name", activityFile.name);
      formData.append(
        "name",
        JSON.stringify(
          basicReducerState?.entities
            ?.filter(
              (entity) =>
                Number(entity?.applicationId) === Number(params?.applicationId)
            )
            ?.map((entity) => ({
              id: entity?.id,
              name: entity?.name,
            })) || []
        )
      );
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: formData,
      };
      // console.log(formData);
      fetch(url, requestParam)
        .then((res) => {
          console.log(res);
          // return res.json();
        })
        .then((data) => {
          setOnLoad(false);
          setOpenActivityFileDialog(false);
          setActivityFile(null);
          getActivities();

          dispatch(
            setResponseMessage({
              open: true,
              status: "success",
              message:  "Activity file uploaded successfully",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
          dispatch(
            setResponseMessage({
              open: true,
              status:  "error",
              message: "Something went wrong",
            })
          );
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const uploadApisFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!apiFile) {
        console.log("no file found");
        return;
      }
      setOnLoad(true);
      const url = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/addApiUsingCsv?applicationId=${params?.applicationId}`;
      let formData = new FormData();
      formData.append("file", apiFile);
      formData.append("name", apiFile.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: formData,
      };
      fetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setOnLoad(false);
          setOpenApiFileDialog(false);
          setApiFile(null);
          getApis();

          dispatch(
            setResponseMessage({
              open: true,
              status:"success",
              message: "Api file uploaded successfully",
            })
          );
        })
        .catch((err) => {
          setOnLoad(false);
          dispatch(
            setResponseMessage({
              open: true,
              status:  "error",
              message: "Something went wrong",
            })
          );
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  const getApplicationById = (applicationId) => {
    const application = findApplicationById(
      applicationId,
      basicReducerState?.applications
    );
    return application || null;
  };
  // useEffect(()=>{
  //   setSelectedApplication(null);
  //   setParams(null);
  // })

  useEffect(() => {
    setentities(basicReducerState.entities);
    setapis(basicReducerState.apis);
    setactivities(basicReducerState.activities);
    setpayloads(basicReducerState?.payloads);
  }, [
    basicReducerState.entities,
    basicReducerState.apis,
    basicReducerState.activities,
    basicReducerState?.payloads,
  ]);

  useEffect(() => {
    getSystemDetail();

    setApplication(getApplicationById(params?.applicationId));
  }, [params?.applicationId]);

  return (
    <div
      style={{
        width: `calc(100vw - ${sidebarWidth})`,
        height: `calc(100vh - ${appHeaderHeight})`,
        padding: 16,
      }}
    >
      <Loading load={onLoad} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: cwAppDetailPageHeaderHeight,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            style={{
              border: "1px solid #3f51b5",
              borderRadius: "50%",
            }}
            onClick={() => {
              setSelectedApplication(null);
              setParams(null);
            }}
          >
            <ArrowBack style={{ fontSize: 20 }} color="primary" />
          </IconButton>

          <Typography style={{ marginLeft: 8, fontSize: 14 }}>
            {application?.name}
          </Typography>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            onClick={() => {
              getEntities();
              getApis();
              getActivities();
              getPayloads();
            }}
          >
            <Refresh style={{ fontSize: 20 }} />
          </IconButton>

          {(userReducerState?.entitiesAndActivities?.roles?.includes(
            roles?.SUPER_ADMIN
          ) ||
            (userFeatures?.includes(features?.ADD_SYSTEM_DETAILS) &&
              application?.applicationOwnerEmails?.includes(
                userReducerState?.user?.email
              ))) && (
            <IconButton
              size="small"
              aria-controls="menu"
              onClick={(e) => {
                setAnchorElMenu(e.currentTarget);
              }}
            >
              <MoreVert style={{ fontSize: 20 }} />
            </IconButton>
          )}
        </div>

        <Menu
          id="menu"
          anchorEl={anchorElMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ width: 230 }}
          open={Boolean(anchorElMenu)}
          onClose={() => {
            setAnchorElMenu(null);
            setOperation("");
          }}
        >
          <MenuItem
            aria-controls="subMenu"
            selected={operation === "download"}
            onClick={(e) => {
              setAnchorElSubMenu(e.currentTarget);
              setOperation("download");
            }}
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontSize: 14, marginRight: 20 }}>
              Download template
            </Typography>

            <ListItemIcon style={{ minWidth: 0 }}>
              <ArrowForwardIos fontSize="small" style={{ fontSize: 12 }} />
            </ListItemIcon>
          </MenuItem>

          <MenuItem
            aria-controls="subMenu"
            selected={operation === "upload"}
            onClick={(e) => {
              setAnchorElSubMenu(e.currentTarget);
              setOperation("upload");
            }}
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontSize: 14, marginRight: 20 }}>
              Upload template
            </Typography>

            <ListItemIcon style={{ minWidth: 0 }}>
              <ArrowForwardIos fontSize="small" style={{ fontSize: 12 }} />
            </ListItemIcon>
          </MenuItem>
        </Menu>

        <Menu
          id="subMenu"
          anchorEl={anchorElSubMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElSubMenu)}
          onClose={() => {
            setAnchorElSubMenu(null);
            setAnchorElMenu(null);
            setOperation("");
          }}
        >
          <MenuItem
            onClick={(e) => {
              if (operation === "download") {
                downloadFile({
                  data: entityFileHeading,
                  fileName: "cw_entities.csv",
                  fileType: "text/csv",
                });
                setOperation("");
              } else {
                setOpenEntityFileDialog(true);
              }
              setAnchorElSubMenu(null);
              setAnchorElMenu(null);
            }}
          >
            <Typography style={{ fontSize: 14 }}>Module</Typography>
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              if (operation === "download") {
                downloadFile({
                  data: apiFileHeading,
                  fileName: "cw_apis.csv",
                  fileType: "text/csv",
                });
                setOperation("");
              } else {
                setOpenApiFileDialog(true);
              }
              setAnchorElSubMenu(null);
              setAnchorElMenu(null);
            }}
          >
            <Typography style={{ fontSize: 14 }}>API</Typography>
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              if (operation === "download") {
                downloadFile({
                  data: activityFileHeading,
                  fileName: "cw_activities.csv",
                  fileType: "text/csv",
                });
                setOperation("");
              } else {
                setOpenActivityFileDialog(true);
              }
              setAnchorElSubMenu(null);
              setAnchorElMenu(null);
            }}
          >
            <Typography style={{ fontSize: 14 }}>Feature</Typography>
          </MenuItem>

          {/* <MenuItem
            onClick={(e) => {
              if (operation === "download") {
                downloadFile({
                  data: payloadFileHeading,
                  fileName: "cw_payloads.csv",
                  fileType: "text/csv",
                });
                setOperation("");
              } else {
                setOpenPayloadFileDialog(true);
              }
              setAnchorElSubMenu(null);
              setAnchorElMenu(null);
            }}
          >
            <Typography style={{ fontSize: 14 }}>Payload</Typography>
          </MenuItem> */}
        </Menu>
      </div>

      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid
          item
          xs={
            selectedApplicationDetailContentType === "Modules" ||
            selectedApplicationDetailContentType === "Apis"
              ? 6
              : 12
          }
          //  sm={5} md={4} lg={3}
        >
          <div className={classes.applicationDetailHeaderContainer}>
            <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
              <Typography
                className={`${classes.applicationDetailHeaderItem} ${
                  selectedApplicationDetailContentType === "Basic Info" &&
                  classes.applicationDetailHeaderItemSelected
                }`}
                onClick={() => {
                  setSelectedApplicationDetailContentType("Basic Info");
                  setSelectedEntity(null);
                  setSelectedApi(null);
                }}
              >
                Basic Info
              </Typography>

              <Typography
                className={`${classes.applicationDetailHeaderItem} ${
                  selectedApplicationDetailContentType === "Modules" &&
                  classes.applicationDetailHeaderItemSelected
                }`}
                onClick={() => {
                  setSelectedApplicationDetailContentType("Modules");
                  setSelectedEntity(null);
                  setSelectedApi(null);
                }}
              >
                Modules
              </Typography>

              <Typography
                className={`${classes.applicationDetailHeaderItem} ${
                  selectedApplicationDetailContentType === "Apis" &&
                  classes.applicationDetailHeaderItemSelected
                }`}
                onClick={() => {
                  setSelectedApplicationDetailContentType("Apis");
                  setSelectedEntity(null);
                  setSelectedApi(null);
                }}
              >
                Apis
              </Typography>

              <Typography
                className={`${classes.applicationDetailHeaderItem} ${
                  selectedApplicationDetailContentType === "Users" &&
                  classes.applicationDetailHeaderItemSelected
                }`}
                onClick={() => {
                  setSelectedApplicationDetailContentType("Users");
                  setSelectedEntity(null);
                  setSelectedApi(null);
                }}
              >
                Users
              </Typography>
            </div>

            {(selectedApplicationDetailContentType === "Modules" ||
              selectedApplicationDetailContentType === "Apis") &&
              (userReducerState?.entitiesAndActivities?.roles?.includes(
                roles?.SUPER_ADMIN
              ) ||
                (userFeatures?.includes(features?.ADD_SYSTEM_DETAILS) &&
                  application?.applicationOwnerEmails?.includes(
                    userReducerState?.user?.email
                  ))) && (
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  //   className={classes.applicationHeadeAddButton}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    if (selectedApplicationDetailContentType === "Modules") {
                      setAddNewEntityDialog(true);
                    } else {
                      setAddNewApiDialog(true);
                    }
                  }}
                  startIcon={<Add />}
                  disabled={onLoad}
                >
                  Add
                </Button>
              )}
          </div>

          {selectedApplicationDetailContentType === "Basic Info" && (
            <SystemBasicInfo
              selectedApplication={selectedApplication}
              height={`calc(100vh - ${appHeaderHeight} - ${cwAppDetailPageHeaderHeight} - ${cwAppDetailPageTabHeight} - 18px)`}
            />
          )}

          {selectedApplicationDetailContentType === "Modules" && (
            <>
              {entities?.length === 0 ? (
                onLoad ? null : (
                  <NotFound />
                )
              ) : (
                <div
                  className={`${classes.applicationContentContainer} iagScroll`}
                  style={{ width: "100%" }}
                >
                  {entities?.map(
                    (entity, index) =>
                      Number(params?.applicationId) ===
                        entity?.applicationId && (
                        <CustomCard
                          key={`${entity?.id}-${index}`}
                          item={entity}
                          application={application}
                          onDelete={(e) => {
                            e.stopPropagation();
                            // handleDeleteEntity(entity?.id);
                            setDeletingEntity(entity?.id);
                          }}
                          onClick={() => {
                            setSelectedEntity(entity?.id);
                            setSelectedApi(null);
                          }}
                          selected={
                            params?.applicationId &&
                            selectEntity &&
                            entity?.id === Number(selectEntity)
                          }
                          load={onLoad}
                          onUpdate={(e) => {
                            e.stopPropagation();
                            setIsUpdateItem(true);
                            setAddNewEntityDialog(true);
                            setItem(entity);
                          }}
                        />
                      )
                  )}
                </div>
              )}

              <NewItem
                open={addNewEntityDialog}
                onClose={() => {
                  setAddNewEntityDialog(false);
                  setItem(initialItem);
                  setSelectedEntity(null);
                  setSelectedApi(null);
                  setUpdatingApi(null);
                  setIsUpdateItem(null);
                }}
                title={`${isUpdateItem ? "Update" : "New"} Modules`}
                label={true}
                newItem={item}
                setNewItem={setItem}
                onCreate={
                  isUpdateItem ? handleUpdateEntity : handleCreateEntity
                }
                error={
                  isUpdateItem
                    ? basicReducerState?.entities?.find(
                        (entity) =>
                          entity?.name === item?.name &&
                          Number(entity?.applicationId) ===
                            Number(params?.applicationId) &&
                          Number(entity?.id) !== Number(item?.id)
                      )
                    : basicReducerState?.entities?.find(
                        (entity) =>
                          entity?.name === item?.name &&
                          Number(entity?.applicationId) ===
                            Number(params?.applicationId)
                      )
                }
                errorMessage="Module name already exists"
              />

              <UploadFile
                open={openEntityFileDialog}
                onClose={() => {
                  setOpenEntityFileDialog(false);
                  setEntityFile(null);
                }}
                onUpload={() => {
                  uploadEntitiesFile();
                }}
                file={entityFile}
                setFile={setEntityFile}
                disableCondition={!entityFile}
                load={onLoad}
              />

              <DeletionMessageBox
                open={deletingEntity ? true : false}
                onClose={() => setDeletingEntity(null)}
                onDelete={() => {
                  handleDeleteEntity(deletingEntity);
                }}
                load={onLoad}
              />
            </>
          )}

          {selectedApplicationDetailContentType === "Apis" && (
            <>
              {apis?.length === 0 ? (
                onLoad ? null : (
                  <NotFound />
                )
              ) : (
                <div
                  className={`${classes.applicationContentContainer} iagScroll`}
                  style={{ width: "100%" }}
                >
                  {apis?.map(
                    (api, index) =>
                      Number(params?.applicationId) === api?.applicationId && (
                        <CustomCard
                          key={`${api?.id}-${index}`}
                          item={api}
                          application={application}
                          onDelete={(e) => {
                            e.stopPropagation();
                            // handleDeleteApi(api?.id);
                            setDeletingApi(api?.id);
                          }}
                          onClick={() => {
                            setSelectedEntity(null);
                            setSelectedApi(api?.id);
                          }}
                          selected={
                            params?.applicationId &&
                            selectApi &&
                            api?.id === Number(selectApi)
                          }
                          load={onLoad}
                          onUpdate={(e) => {
                            e.stopPropagation();
                            setIsUpdateItem(true);
                            setUpdatingApi(api);
                            setAddNewApiDialog(true);
                          }}
                        />
                      )
                  )}
                </div>
              )}

              <NewApi
                open={addNewApiDialog}
                onClose={() => {
                  setIsUpdateItem(false);
                  setAddNewApiDialog(false);
                  setSelectedEntity(null);
                  setSelectedApi(null);
                  setUpdatingApi(null);
                }}
                title={`${isUpdateItem ? "Update" : "New"} Api`}
                update={isUpdateItem}
                updatingApi={updatingApi}
                applicationId={params?.applicationId}
              />

              <UploadFile
                open={openApiFileDialog}
                onClose={() => {
                  setOpenApiFileDialog(false);
                  setApiFile(null);
                }}
                onUpload={() => {
                  uploadApisFile();
                }}
                file={apiFile}
                setFile={setApiFile}
              />

              <DeletionMessageBox
                open={deletingApi ? true : false}
                onClose={() => setDeletingApi(null)}
                onDelete={() => {
                  handleDeleteApi(deletingApi);
                }}
                load={onLoad}
              />
            </>
          )}

          {selectedApplicationDetailContentType === "Users" && (
            <SystemUserTable
              filteredUsers={filteredUsers}
              load={onLoad}
              height={`calc(100vh - ${appHeaderHeight} - ${cwAppDetailPageHeaderHeight} - ${cwAppDetailPageTabHeight} - 18px)`}
            />
          )}
        </Grid>

        {(selectedApplicationDetailContentType === "Modules" ||
          selectedApplicationDetailContentType === "Apis") &&
          (userReducerState?.entitiesAndActivities?.roles?.includes(
            roles?.SUPER_ADMIN
          ) ||
            (userFeatures?.includes(features?.ADD_SYSTEM_DETAILS) &&
              application?.applicationOwnerEmails?.includes(
                userReducerState?.user?.email
              ))) && (
            <>
              {selectEntity && (
                <Grid item xs={6}>
                  <div className={classes.applicationHeaderContainer}>
                    <Typography className={classes.applicationHeadeTitle}>
                      Features
                    </Typography>

                    {(userReducerState?.entitiesAndActivities?.roles?.includes(
                      roles?.SUPER_ADMIN
                    ) ||
                      (userFeatures?.includes(features?.ADD_SYSTEM_DETAILS) &&
                        application?.applicationOwnerEmails?.includes(
                          userReducerState?.user?.email
                        ))) && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* <Tooltip title="Download template">
                  <IconButton
                    size="small"
                    // color="primary"
                    style={{ marginLeft: 4 }}
                    disabled={onLoad}
                    onClick={(e) => {
                      downloadFile({
                        data: activityFileHeading,
                        fileName: "cw_features.csv",
                        fileType: "text/csv",
                      });
                    }}
                  >
                    <GetApp style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Upload file">
                  <IconButton
                    size="small"
                    // color="primary"
                    style={{ marginLeft: 4 }}
                    disabled={onLoad}
                    onClick={() => {
                      setOpenActivityFileDialog(true);
                    }}
                  >
                    <Publish style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Refresh">
                  <IconButton
                    size="small"
                    disabled={onLoad}
                    onClick={getActivities}
                  >
                    <Refresh style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip> */}

                        <Button
                          size="small"
                          variant="text"
                          color="primary"
                          //   className={classes.applicationHeadeAddButton}
                          style={{ textTransform: "capitalize" }}
                          onClick={() => setAddNewActivityDialog(true)}
                          startIcon={<Add />}
                          disabled={onLoad}
                        >
                          Add
                        </Button>
                      </div>
                    )}
                  </div>

                  {activities?.length === 0 ? (
                    onLoad ? null : (
                      <NotFound />
                    )
                  ) : (
                    <div
                      className={`${classes.applicationContentContainer} iagScroll`}
                    >
                      {activities?.map(
                        (activity, index) =>
                          selectEntity === activity?.entityId && (
                            <CustomCard
                              key={`${activity?.id}-${index}`}
                              item={activity}
                              application={application}
                              onDelete={(e) => {
                                e.stopPropagation();
                                // handleDeleteActivity(activity?.id);
                                setDeletingActivity(activity?.id);
                              }}
                              onClick={() => {}}
                              load={onLoad}
                              onUpdate={(e) => {
                                e.stopPropagation();
                                setIsUpdateItem(true);
                                setAddNewActivityDialog(true);
                                setItem(activity);
                              }}
                            />
                          )
                      )}
                    </div>
                  )}

                  <NewItem
                    open={addNewActivityDialog}
                    onClose={() => {
                      setAddNewActivityDialog(false);
                      setItem(initialItem);
                      setIsUpdateItem(null);
                    }}
                    title={`${isUpdateItem ? "Update" : "New"} Feature`}
                    newItem={item}
                    label={true}
                    setNewItem={setItem}
                    onCreate={
                      isUpdateItem ? handleUpdateActivity : handleCreateActivity
                    }
                    error={
                      isUpdateItem
                        ? basicReducerState?.activities?.find(
                            (activity) =>
                              activity?.name === item?.name &&
                              Number(activity?.entityId) ===
                                Number(selectEntity) &&
                              Number(activity?.id) !== Number(item?.id)
                          )
                        : basicReducerState?.activities?.find(
                            (activity) =>
                              activity?.name === item?.name &&
                              Number(activity?.entityId) ===
                                Number(selectEntity)
                          )
                    }
                    errorMessage="Feature name already exists"
                  />

                  <UploadFile
                    open={openActivityFileDialog}
                    onClose={() => {
                      setOpenActivityFileDialog(false);
                      setActivityFile(null);
                    }}
                    onUpload={() => {
                      uploadActivitiesFile();
                    }}
                    file={activityFile}
                    setFile={setActivityFile}
                    disableCondition={!activityFile}
                    load={onLoad}
                  />

                  <DeletionMessageBox
                    open={deletingActivity ? true : false}
                    onClose={() => setDeletingActivity(null)}
                    onDelete={() => {
                      handleDeleteActivity(deletingActivity);
                    }}
                    load={onLoad}
                  />
                </Grid>
              )}

              {selectApi && (
                <Grid item xs={6}>
                  <div className={classes.applicationHeaderContainer}>
                    <Typography className={classes.applicationHeadeTitle}>
                      Payloads
                    </Typography>

                    {(userReducerState?.entitiesAndActivities?.roles?.includes(
                      roles?.SUPER_ADMIN
                    ) ||
                      (userFeatures?.includes(features?.ADD_SYSTEM_DETAILS) &&
                        application?.applicationOwnerEmails?.includes(
                          userReducerState?.user?.email
                        ))) && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* <Tooltip title="Download template">
                  <IconButton
                    size="small"
                    // color="primary"
                    style={{ marginLeft: 4 }}
                    disabled={onLoad}
                    onClick={(e) => {
                      downloadFile({
                        data: payloadFileHeading,
                        fileName: "cw_payloads.csv",
                        fileType: "text/csv",
                      });
                    }}
                  >
                    <GetApp style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Upload file">
                  <IconButton
                    size="small"
                    // color="primary"
                    style={{ marginLeft: 4 }}
                    disabled={onLoad}
                    onClick={() => {
                      setOpenPayloadFileDialog(true);
                    }}
                  >
                    <Publish style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Refresh">
                  <IconButton
                    size="small"
                    disabled={onLoad}
                    onClick={getPayloads}
                  >
                    <Refresh style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip> */}

                        <Button
                          size="small"
                          variant="text"
                          color="primary"
                          //   className={classes.applicationHeadeAddButton}
                          style={{ textTransform: "capitalize" }}
                          onClick={() => setAddNewPayloadDialog(true)}
                          startIcon={<Add />}
                          disabled={onLoad}
                        >
                          Add
                        </Button>
                      </div>
                    )}
                  </div>

                  {payloads?.length === 0 ? (
                    onLoad ? null : (
                      <NotFound />
                    )
                  ) : (
                    <div
                      className={`${classes.applicationContentContainer} iagScroll`}
                    >
                      {payloads?.map(
                        (payload, index) =>
                          selectApi === payload?.apiId && (
                            <CustomCard
                              key={`${payload?.id}-${index}`}
                              item={payload}
                              application={application}
                              onDelete={(e) => {
                                e.stopPropagation();
                                // handleDeletePayload(payload?.id);
                                setDeletingPayload(payload?.id);
                              }}
                              onClick={() => {}}
                              load={onLoad}
                              onUpdate={(e) => {
                                e.stopPropagation();
                                setIsUpdateItem(true);
                                setUpdatingPayload(payload);
                                setAddNewPayloadDialog(true);
                              }}
                            />
                          )
                      )}
                    </div>
                  )}

                  <NewPayload
                    open={addNewPayloadDialog}
                    onClose={() => {
                      setAddNewPayloadDialog(false);
                      setUpdatingPayload(null);
                      setIsUpdateItem(false);
                    }}
                    title={`${isUpdateItem ? "Update" : "New"} Payload`}
                    selectApi={selectApi}
                    update={isUpdateItem}
                    updatingPayload={updatingPayload}
                  />

                  <UploadFile
                    open={openPayloadFileDialog}
                    onClose={() => {
                      setOpenPayloadFileDialog(false);
                      setPayloadFile(null);
                    }}
                    onUpload={() => {}}
                    file={payloadFile}
                    setFile={setPayloadFile}
                  />

                  <DeletionMessageBox
                    open={deletingPayload ? true : false}
                    onClose={() => setDeletingPayload(null)}
                    onDelete={() => {
                      handleDeletePayload(deletingPayload);
                    }}
                    load={onLoad}
                  />
                </Grid>
              )}

              {!selectEntity && !selectApi && (
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    boxShadow: "0px 1px 3px rgba(112, 112, 112, 0.25)",
                  }}
                >
                  <img src="/Frame.png" alt="no select" />

                  <Typography style={{ fontSize: 24, color: "#DFDFDF" }}>
                    Select an item to view
                  </Typography>
                </Grid>
              )}
            </>
          )}
      </Grid>
    </div>
  );
}

export default ApplicationDetail;
